import { round, sum } from "../../../utils/general";
import { Part } from "./Part";
import { v4 as uuidv4 } from "uuid";
import { setAlert } from "../../alert";
import store from "../../../store";

export class Molding extends Part {
  constructor(MoldingElement, Room) {
    super(MoldingElement, Room);
    this.$ = Room.$;
    this.MoldingElement = Room.$(MoldingElement);
    this.Room = Room;
  }
  attr(key) {
    return this.MoldingElement.attr(key);
  }
  getMoldingTexture() {
    return this.Room.getRoomSetting("MoldTexture");
  }
  getRoomCabMaterial() {
    return this.Room.getRoomSetting("MatCabTemplate");
  }
  getWallNumber() {
    // 2_1 => 2
    return this.MoldingElement.attr("Wall").split("_")[0];
  }
  getCabNumber() {
    // R2C4
    return `R${this.Room.getRoomNumber()}C${this.MoldingElement.attr("CabNo")}`;
  }
  // This will return an array of lengths for each partType and materialType
  getMoldingType() {
    return this.MoldingElement.attr("Name");
  }
  getMoldingWidth() {
    return this.MoldingElement.attr("ShapeW");
  }
  getLengthFromShapePoint(currentShapePoint, nextShapePoint) {
    const yDiff = nextShapePoint.attr("Y") - currentShapePoint.attr("Y");
    const xDiff = nextShapePoint.attr("X") - currentShapePoint.attr("X");
    const length = Math.abs(xDiff + yDiff);
    return length;
  }
  getMoldingParts() {
    // @TODO: These should be pulled in from a spreadsheet that can be easily updated to add new moldings

    const MoldingPartName = {
      "Crown 2C WD": "Crown",
      Crown: "Crown",
      "Square 2C": "Riser",
      "Crown 2C ND": "Crown",
    };
    const MoldingPartWidth = {
      "Crown 2C WD": 2.75,
      Crown: 2.75,
      "Square 2C": 1.5,
      Square: 3,
      "Face Filler": 2,
      "Crown 2C ND": 2.75,
      "Face Filler 2C": 1.5,
    };
    // const MoldingPartThickness = {
    //   "Crown 2C WD": '3/4"',
    //   Crown: '3/4"',
    //   "Square 2C": '5/8"',
    //   Square: '3/4"',
    //   "Face Filler": '3/4"',
    //   "Crown 2C ND": '3/4"',
    //   "Face Filler 2C": '5/8"',
    // };
    const PartA = {
      "Crown 2C WD": 2,
      Crown: 1,
      Square: 2,
      "Square 2C": 2,
      "Face Filler": 1,
      "Crown 2C ND": 1,
      "Face Filler 2C": 1,
      "Custom Molding": 2,
    };
    const PartB = ["Crown 2C WD", "Square", "Square 2C", "Crown 2C ND", "Face Filler 2C", "Face Filler", "Custom Molding"];
    const RoomCabMaterial = this.getRoomCabMaterial().replace("0.75 inch", '3/4"');
    const MoldingTexture = this.getMoldingTexture();
    const MoldingType = this.getMoldingType().trim();
    const MoldingWidth = this.getMoldingWidth();

    const parts = [];

    const ShapePoints = this.MoldingElement.find("PathShapeXml ShapePoint")
      .toArray()
      .map(point => this.$(point));

    for (let i = 0; i < ShapePoints.length - 1; i++) {
      const length = this.getLengthFromShapePoint(ShapePoints[i], ShapePoints[i + 1]);

      if (!PartA[MoldingType]) {
        console.log("INVALID MOLDING TYPE: ", MoldingType);
        store.dispatch(setAlert("error", `Invalid Molding Profile: ${MoldingType}`, 5 * 60 * 1000));
        return; // @TODO: Need to cancel the running of the script
      }

      // Molding
      if (MoldingPartName[MoldingType]) {
        parts.push({
          "`PrintName": "Molding | " + MoldingType + " | " + MoldingPartName[MoldingType],
          "`Qty": 1,
          "`Width": MoldingPartWidth[MoldingType],
          "`Length": length,
          "`Material": MoldingTexture === "Thermofoil" ? "Thermofoil" : RoomCabMaterial,
        });
      }

      // Part A
      if (Object.keys(PartA).includes(MoldingType)) {
        parts.push({
          "`PrintName": "Molding | " + MoldingType + " | A",
          "`Qty": PartA[MoldingType],
          "`Width": Number(MoldingWidth).to("inches"),
          "`Length": length,
          "`Material": MoldingTexture === "Thermofoil" ? "Thermofoil" : RoomCabMaterial,
        });
      }

      // Part B
      if (PartB.includes(MoldingType)) {
        parts.push({
          "`PrintName": "Molding | " + MoldingType + " | Nailer",
          "`Qty": 1,
          "`Width": 0.75,
          "`Length": length,
          "`Material": MoldingTexture === "Thermofoil" ? "Thermofoil" : RoomCabMaterial,
        });
      }
    }
    return parts;
  }
  async getLengths() {
    const lengths = [];
    const parts = this.getMoldingParts();
    const stripLength = 8; // length of strip in feet
    const partTypes = new Set([...parts.map(part => part["`PrintName"])]);
    const materials = new Set([...parts.map(part => part["`Material"])]);
    for (const material of materials) {
      for (const partType of partTypes) {
        const matching = parts.filter(part => part["`PrintName"] == partType && part["`Material"] == material);
        if (!matching.length) continue;
        const W = matching[0]["`Width"];
        const totalLnFt = sum(matching.map(part => part["`Length"].to("ft") * part["`Qty"]));
        const totalLnFtPlus = totalLnFt + stripLength;
        const numStrips = round(totalLnFtPlus / stripLength, 0);

        // Strips
        lengths.push({
          "`Id": this.getId(),
          "`Room Name": this.Room.getRoomNameWithNumber(),
          "`PrintName": partType,
          "`Qty": numStrips,
          "`Material": material,
          "`CabNo": `R${this.Room.getRoomNumber()}C0`,
          "`Wall": 0,
          "`Style": partType,
          "`Width": W, // inches
          "`Length": stripLength * 12, // inches
          "`Primary Category": await super.getPrimaryCategory(partType),
          "`Secondary Category": await super.getSecondaryCategory(partType),
          "`Tertiary Category": await super.getTertiaryCategory(partType),
          "`Measurement": "",
          "`LnFt": stripLength,
          "`SqFt": stripLength * W.to("ft"),
        });

        // True Length
        lengths.push({
          "`Id": this.getId(),
          "`Room Name": this.Room.getRoomNameWithNumber(),
          "`PrintName": `[True Length] ${partType}`,
          "`Qty": 1,
          "`Material": material,
          "`CabNo": `R${this.Room.getRoomNumber()}C0`,
          "`Wall": 0,
          "`Style": partType,
          "`Width": W, // inches
          "`Length": totalLnFt * 12, // inches
          "`Primary Category": await super.getPrimaryCategory(partType),
          "`Secondary Category": await super.getSecondaryCategory(partType),
          "`Tertiary Category": await super.getTertiaryCategory(partType),
          "`Measurement": "",
          "`LnFt": totalLnFt,
          "`SqFt": totalLnFt * W.to("ft"),
        });
      }
    }
    return lengths;
  }
}
