import { uniqueId } from "lodash";
import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { setAlert } from "../../actions/alert";
import { Drive } from "../../actions/google";
import { getRoomParts } from "../../actions/mozaik/parts";
import Button from "../../components/Elements/Button";
import JobSelect from "../../components/Jobs/JobSelect";
import { load, createLinkedProductFunction, isNumber, getMaxLineBoreQuantity, isManuallyAddedHole } from "./OptimizerRules/OptimizerUtils";
import { removeExtraHolesAlongLineBore } from "./OptimizerRules/RemoveExtraHolesAlongLineBore";
import OptimizerVBEdgeFix from "./OptimizerVBEdgeFix";

/**
 *
 * Other Features:
 *  - Copy Parts from One Job File to current
 *      - as a new part
 *      - to replace existing part
 *  - Copy operations from a part in another job file to the current
 *  - Ability to specify cabinet number and part name
 */

const Optimizer = ({ jobFolder, jobParts, rooms, setAlert }) => {
  const [files, setFiles] = useState([]);
  const [complete, setComplete] = useState(false);
  const [report, setReport] = useState([]);
  const [operations, setOperations] = useState({
    vbEdgeFix: [],
  });

  console.log({ operations });

  const ref = useRef();

  async function fetchFiles(jobFolder) {
    if (jobFolder.id) {
      const files = (await Drive.getFilesByFolder(jobFolder.id)).filter(file => file.name.endsWith(".opt"));
      if (!files.length) setAlert("error", "No optimizer files found.");
      setFiles(files);
    }
  }

  useEffect(() => {
    fetchFiles(jobFolder);
  }, [jobFolder.id]);

  async function sanitizeFiles() {
    ref.current.textContent = "Running...";
    ref.current.setAttribute("disabled", true);
    const issues = [];
    for (const file of files) {
      await sanitizeOptimizerFile(file, issues);
    }
    setReport(issues);
    setComplete(true);
  }

  async function sanitizeOptimizerFile(file, issues) {
    try {
      // @TODO: Need a way to check if the file has already been sanitized

      let $ = await load(file);

      const optimizeMaterial = $("OptimizeMaterial").attr("Name");

      console.log({ optimizeMaterial });

      const getLinkedProduct = createLinkedProductFunction(rooms);

      function addTopShelfHoles(part, issues) {
        const cabNumber = isNumber($(part).attr("AssyNo")) ? "R1C" + $(part).attr("AssyNo") : $(part).attr("AssyNo");
        const name = $(part).attr("Name");
        const length = $(part).attr("Length");

        const operationHoles = $(part)
          .find("OperationHole")
          .toArray()
          .map(hole => Number($(hole).attr("Y")));

        const Ymin = Math.min(...operationHoles);
        const Ymax = Math.max(...operationHoles);

        const X = Number(length) - 16;
        const depth = 13; // @TODO:

        $(part)
          .find("Operations")
          .append(
            `<OperationHole IsScriptAddition="True" Diameter="5" Diameter_Eq="" ID="1" X="${X}" Y="${Ymin}" Depth="${depth}" Hide="False" X_Eq="" Y_Eq="" Depth_Eq="" Hide_Eq="" IsUserOp="True" IdTag="9999" Noneditable="False" Anchor="" FlipSideOp="False" />`
          );
        issues.push({
          type: "gable",
          msg: `ADDED TOP SHELF HOLE: Adding a hole at position X: ${X}, Y: ${Ymin} for ${name} (${cabNumber})`,
        });
        $(part)
          .find("Operations")
          .append(
            `<OperationHole IsScriptAddition="True" Diameter="5" Diameter_Eq="" ID="1" X="${X}" Y="${Ymax}" Depth="${depth}" Hide="False" X_Eq="" Y_Eq="" Depth_Eq="" Hide_Eq="" IsUserOp="True" IdTag="9999" Noneditable="False" Anchor="" FlipSideOp="False" />`
          );
        issues.push({
          type: "gable",
          msg: `ADDED TOP SHELF HOLE: Adding a hole at position X: ${X}, Y: ${Ymax} for ${name} (${cabNumber})`,
        });
      }

      function addBottomShelfHoles(part, issues) {
        const cabNumber = isNumber($(part).attr("AssyNo")) ? "R1C" + $(part).attr("AssyNo") : $(part).attr("AssyNo");
        const name = $(part).attr("Name");
        const operationHoles = $(part)
          .find("OperationHole")
          .toArray()
          .map(hole => Number($(hole).attr("Y")));

        const Ymin = Math.min(...operationHoles);
        const Ymax = Math.max(...operationHoles);

        const X = 10;
        const depth = 13; // @TODO:

        $(part)
          .find("Operations")
          .append(
            `<OperationHole IsScriptAddition="True" Diameter="5" Diameter_Eq="" ID="1" X="${X}" Y="${Ymin}" Depth="${depth}" Hide="False" X_Eq="" Y_Eq="" Depth_Eq="" Hide_Eq="" IsUserOp="True" IdTag="9999" Noneditable="False" Anchor="" FlipSideOp="False" />`
          );
        issues.push({
          type: "gable",
          msg: `ADDED TOP SHELF HOLE: Adding a hole at position X: ${X}, Y: ${Ymin} for ${name} (${cabNumber})`,
        });
        $(part)
          .find("Operations")
          .append(
            `<OperationHole IsScriptAddition="True" Diameter="5" Diameter_Eq="" ID="1" X="${X}" Y="${Ymax}" Depth="${depth}" Hide="False" X_Eq="" Y_Eq="" Depth_Eq="" Hide_Eq="" IsUserOp="True" IdTag="9999" Noneditable="False" Anchor="" FlipSideOp="False" />`
          );
        issues.push({
          type: "gable",
          msg: `ADDED TOP SHELF HOLE: Adding a hole at position X: ${X}, Y: ${Ymax} for ${name} (${cabNumber})`,
        });
      }

      $("OptimizePart")
        .toArray()
        .forEach(part => {
          const cabNumber = isNumber($(part).attr("AssyNo")) ? "R1C" + $(part).attr("AssyNo") : $(part).attr("AssyNo");
          const name = $(part).attr("Name").trim();
          const partId = $(part).attr("PartID");
          const width = $(part).attr("Width");
          const length = $(part).attr("Length");
          const maxLineBoreQuantity = getMaxLineBoreQuantity($, part);
          const isWallCab = cabNumber.startsWith("N");
          const maxLength = Math.max(width, length);
          const minLength = Math.min(width, length);
          const hasLineBore = maxLineBoreQuantity > 0;
          const linkedProduct = getLinkedProduct(cabNumber);
          const sourceLib = linkedProduct ? $(linkedProduct).attr("SourceLib") : null;

          console.log("##############################################################################");
          console.log({ cabNumber, name });

          // run operations

          if (operations.romina && name.includes("Gable")) {
            console.log("running romina operations...");
            $(part)
              .find("OperationHole")
              .toArray()
              .forEach(hole => {
                const X = $(hole).attr("X");

                if (X == "316.9875") {
                  $(hole).attr("X", "329.50");
                  issues.push({
                    type: "gable",
                    msg: `MOVED HOLE: Moved a hole from position X: ${X} --- to --- X: 329.50 for ${name} (${cabNumber})`,
                  });
                }
                if (X == "541.1875") {
                  $(hole).attr("X", "553.50");
                  issues.push({
                    type: "gable",
                    msg: `MOVED HOLE: Moved a hole from position X: ${X} --- to --- X: 553.50 for ${name} (${cabNumber})`,
                  });
                }
                if (X == "765.3875") {
                  $(hole).attr("X", "777.50");
                  issues.push({
                    type: "gable",
                    msg: `MOVED HOLE: Moved a hole from position X: ${X} --- to --- X: 777.50 for ${name} (${cabNumber})`,
                  });
                }
              });
          }

          if (operations.biggerHoles && name.includes("Gable")) {
            console.log("running bigger holes operations...");
            $(part)
              .find("OperationHole")
              .toArray()
              .forEach(hole => {
                const diameter = $(hole).attr("Diameter");
                if (diameter == "7") {
                  $(hole).attr("Original_Diameter", "7");
                  $(hole).attr("Diameter", "7.5");
                  issues.push({
                    type: "gable",
                    msg: `INCREASED HOLE DIAMETER: Increased the diameter of a hole from 7mm to 7.5mm for ${name} (${cabNumber})`,
                  });
                }
              });

            $(part)
              .find("OperationLineBore")
              .toArray()
              .forEach(lineBore => {
                const diameter = $(lineBore).attr("Diameter");
                if (diameter == "7") {
                  $(lineBore).attr("Original_Diameter", "7");
                  $(lineBore).attr("Diameter", "7.5");
                  issues.push({
                    type: "gable",
                    msg: `INCREASED LINE BORE DIAMETER: Increased the diameter of a line bore from 7mm to 7.5mm for ${name} (${cabNumber})`,
                  });
                }
              });
          }

          // move line bore for 5/8" White
          if (optimizeMaterial === '5/8" | White (L191)' && name.includes("Gable")) {
            const amountToIncrease = 2.6; // shift line bore up by 2.6mm
            const screwInDowelDepth = 10;
            const boardThickness = 16;

            const length = $(part).attr("Length");
            const lineBores = $(part).find("OperationLineBore").toArray();
            lineBores.forEach(lineBore => {
              const origin = $(lineBore).attr("X");
              const Y = $(lineBore).attr("Y");
              const depth = $(lineBore).attr("Depth");
              const isDrilledThrough = depth >= boardThickness;

              const X = Number(origin) + amountToIncrease;
              console.log({ origin, Y, X });
              $(lineBore).attr("X", String(X));

              issues.push({
                type: "gable",
                msg: `MOVED LINE BORE DOWN: Moved a line bore from position X: ${origin}, Y: ${Y} --- to --- X: ${X}, Y: ${Y} for ${name} (${cabNumber})`,
              });

              // if it is not drilled through then we need to reduce the depth of the screw in dowel hole from 13mm (for 3/4" dowels) to 10mm

              if (!isDrilledThrough) {
                $(lineBore).attr("Depth", String(screwInDowelDepth));
                issues.push({
                  type: "gable",
                  msg: `REDUCED LINE BORE DEPTH: Reduced the depth of a line bore from ${depth}mm to ${screwInDowelDepth}mm for ${name} (${cabNumber})`,
                });
              }
            });
          }

          if (optimizeMaterial === '5/8" | White (L191)' && ["Fixed Shelf", "Top", "Bottom"].includes(name)) {
            /**
             * VBs
                  We used 3/4" VBs because the 5/8" VBs caused misalignment
                  20mm diameter holes (big holes)
                  Depth = 13.3 mm
                  10mm diameter holes (small holes)
                  Depth = 11 mm
             */

            $(part)
              .find("OperationHole")
              .toArray()
              .forEach(hole => {
                const diameter = Number($(hole).attr("Diameter"));
                const depth = $(hole).attr("Depth");
                if (diameter == 20) {
                  $(hole).attr("Depth", "13.3");
                  issues.push({
                    type: "gable",
                    msg: `REDUCED BIG VB HOLE DEPTH: Reduced the depth of a hole from ${depth}mm to 13.3mm for ${name} (${cabNumber})`,
                  });
                } else if (diameter == 10) {
                  $(hole).attr("Depth", "11");
                  issues.push({
                    type: "gable",
                    msg: `REDUCED SMALL VB HOLE DEPTH: Reduced the depth of a hole from ${depth}mm to 11mm for ${name} (${cabNumber})`,
                  });
                }
              });
          }

          // Move top shelf holes down for 1 inch material
          // if (optimizeMaterial == "1 inch White" && name.includes("Gable")) {
          //   // get top shelf holes
          //   const length = $(part).attr("Length");
          //   const threshold = Number(length) - 12.7 - 5;
          //   console.log("threshold", threshold);

          //   const X = Number(length) - 16;

          //   const topShelfHoles = $(part)
          //     .find("OperationHole")
          //     .toArray()
          //     .filter(hole => Number($(hole).attr("X") > threshold));

          //   if (topShelfHoles.length != 2 && topShelfHoles.length != 4) {
          //     console.log(topShelfHoles.map(hole => Number($(hole).attr("X"))));
          //     throw new Error("MORE THAN 2 TOP SHELF HOLES? ", cabNumber);
          //   } else {
          //     topShelfHoles.forEach(hole => {
          //       const origin = $(hole).attr("X");
          //       const Y = $(hole).attr("Y");

          //       $(hole).attr("X", X);
          //       issues.push({
          //         type: "gable",
          //         msg: `MOVED TOP SHELF HOLE: Moved a hole from position X: ${origin}, Y: ${Y} --- to --- X: ${X}, Y: ${Y} for ${name} (${cabNumber})`,
          //       });
          //     });
          //   }
          // }

          // Increase size of Face Kick

          if (name == "Face Kick") {
            const length = $(part).attr("Length");
            // $(part).attr("Length", Number(length) * 2);
          }

          // Remove overlapping holes, with a preference to delete through-drills (maybe? confirm this)

          removeOverlappingHoles($, part, cabNumber, issues, "True");
          removeOverlappingHoles($, part, cabNumber, issues, "False");

          // Remove extra holes that occur along a line bore

          if (!isWallCab) {
            removeExtraHolesAlongLineBore($, part, "True", issues); // FlipSideOp = 'True'
            removeExtraHolesAlongLineBore($, part, "False", issues); // FlipSideOp = 'False'
          }

          // Remove holes that are outside of the part

          removeHolesOutsidePartShape($, part, cabNumber, issues);

          // Remove holes in Optimizer part that aren't in mozaik part

          // if (linkedProduct) removeHolesNotFoundinMozaik($, part, linkedProduct, cabNumber, issues);

          // Increase End Panel Height

          // if (name === "Flat end panel") increaseEndPanelHeight($, part, cabNumber, issues);

          // @TODO: Remove regular kicks from units with face kicks

          // @TODO: If drawer faces are SLAB throw warning to confirm drawer faces are accurate

          // @TODO: Check for missing bottom shelf holes if unit has line bore. You'll want to run the optimizer to look for this. If they are missing you either need to increase the gable height by 1/16" or add fastener "RaFix 20-32mm Closets".

          // Switch Width and Length for Finished Panels and Tray Bottoms

          if (name === "Finished Panel" || name === "Tray Bottom") switchWidthAndLength($, part, cabNumber, issues);

          // Check for first hole on wall gable

          if (name.startsWith("Wall Gable") && hasLineBore) {
          }

          // Remove unnessary line bore on backed wall cabinet

          if (name.startsWith("Gable") && hasLineBore && cabNumber.includes("N")) {
            // look for adjustable shelves with this same cabinet number
            // if there aren't any, remove line bore
          }

          // check flip side line bore holes line up with other side. If the difference is less than 5mm then adjust the line bore with Qty < 10 holes (basically want the holes on each side to line up)

          // Baseboard confirmation

          if (name.startsWith("Gable")) {
            const shapePoints = $(part).find('ShapePoint[X="0"]').toArray();
            const zeroPoints = shapePoints.filter(point => $(point).attr("Y") == "0");
            if (zeroPoints.length == 5 || shapePoints == 4) {
              issues.push({
                type: "baseboard",
                msg: `BASEBOARD: Gable ${cabNumber} does not have a baseboard notch. Please confirm this is correct.`,
              });
            }
          }

          // // Enid

          // if (optimizeMaterial == "1 inch White" && cabNumber.startsWith("R1") && name.includes("Gable") && Number(length) > 2032) {
          //   // add top shelf holes for 1 inch material
          //   addTopShelfHoles(part, issues);
          //   addBottomShelfHoles(part, issues);
          // }
        });

      /**
       * Do we need to fix the VBs along the left edge? If so, run the OptimizerVBEdgeFix function
       * @TODO: What if the starting rotation is not 0? does this affect the X / Y / Width / Length values?
       * @TODO: Need to warn if flipping the sheet still won't fix it (i.e. are there also holes on the other side that are > SheetLength - Threshold away from right edge?)
       */

      if (operations.vbEdgeFix.includes(file.name)) {
        console.warn("RUNNING VB EDGE FIX...");

        const threshold = 50; // mm
        const optimizerSheets = $("OptimizeSheet").toArray();

        for (const sheet of optimizerSheets) {
          const sheetWidth = Number($(sheet).attr("Width"));
          const sheetLength = Number($(sheet).attr("Length"));
          const sheetNumber = $(sheet).attr("PatternNumber");
          const sheetParts = $(sheet).find("OptimizePartLocation").toArray();

          console.warn("IS FLIP SHEET? ", isFlipSheet($, sheet));

          const issueSheet = isIssueSheet($, sheet, threshold);

          if (issueSheet) {
            console.log(`Sheet ${sheetNumber} has issues: `, sheetNumber);

            // const flipSheet = isFlipSheet($, sheet);
            // if (flipSheet) {
            //   console.log(`Sheet ${sheetNumber} has issues but also flip operations: `, sheetNumber);
            //   issues.push({
            //     type: "vb-edge-fix",
            //     msg: `VB EDGE FIX: FLIPSHEET! Pattern #${sheetNumber} has VB issue but is a flip sheet, must be corrected manually!`,
            //   });
            //   continue;
            // }

            // this means the sheet has parts with holes that are not 5mm in diameter and are too close to the left edge
            // therefore we need to set the rotation for each OptimizePartLocation on the sheet to 180 degrees and recalculate the X value for each part

            issues.push({
              type: "vb-edge-fix",
              msg: `VB EDGE FIX: Pattern #${sheetNumber}. Adjusted the sheet`,
            });

            sheetParts.forEach(partLocation => {
              const partId = $(partLocation).attr("PartID");
              const part = $(`OptimizePart[PartID="${partId}"]`);
              const partWidth = Number($(part).attr("Width"));
              const partLength = Number($(part).attr("Length"));
              const rotation = Number($(partLocation).attr("Rotation"));

              let newRotation = rotation + 180;
              if (newRotation >= 360) newRotation -= 360;

              $(partLocation).attr("Rotation", String(newRotation));

              if (rotation == 0 || rotation == 180) {
                const currentX = Number($(partLocation).attr("X"));
                const originalX = $(partLocation).attr("OriginalXForVBEdgeFix");
                const newX = recalculateX(currentX, sheetLength, partLength);
                $(partLocation).attr("X", String(newX));
                $(partLocation).attr("OriginalXForVBEdgeFix", originalX || String(currentX));
              } else {
                const currentX = Number($(partLocation).attr("X"));
                const originalX = $(partLocation).attr("OriginalXForVBEdgeFix");
                const newX = recalculateX(currentX, sheetLength, partWidth);
                $(partLocation).attr("X", String(newX));
                $(partLocation).attr("OriginalXForVBEdgeFix", originalX || String(currentX));
              }
            });
          }
        }
      }

      const output = "4\n" + $.xml().replace(/  /g, "");
      console.log((output.match(/  /g) || []).length);
      const base64 = btoa(output);
      const res = await Drive.updateFile(file.id, "audio/mpeg", base64, file.parents[0]);
      console.log(res);
    } catch (err) {
      console.log(err);
      ref.current.textContent = "Error: " + err.message;
    }
  }

  function recalculateX(currentX, sheetLength, partLength) {
    return sheetLength - currentX - partLength;
  }

  function isIssueSheet($, sheet, threshold) {
    const sheetParts = $(sheet).find("OptimizePartLocation").toArray();
    const leftEdgeParts = sheetParts.filter(part => Number($(part).attr("X")) < threshold);
    const hasIssueHoles = leftEdgeParts.some(partLocation => {
      const partId = $(partLocation).attr("PartID");
      const part = $(`OptimizePart[PartID="${partId}"]`);
      const issueHoles = $(part)
        .find("OperationHole")
        .toArray()
        .filter(hole => Number($(hole).attr("X")) < threshold && $(hole).attr("Diameter") !== "5");
      return issueHoles.length > 0;
    });
    return hasIssueHoles;
  }

  function isFlipSheet($, sheet) {
    const sheetParts = $(sheet).find("OptimizePartLocation").toArray();
    const partWithFlipOp = sheetParts.find(partLocation => {
      const partId = $(partLocation).attr("PartID");
      const part = $(`OptimizePart[PartID="${partId}"]`);
      const operationHoleFlipOp = $(part).find('OperationHole[FlipSideOp="True"]').length > 0;
      const operationLineBoreFlipOp = $(part).find('OperationLineBore[FlipSideOp="True"]').length > 0;
      return operationHoleFlipOp || operationLineBoreFlipOp;
    });
    if (partWithFlipOp) return true;
  }

  function increaseEndPanelHeight($, part, cabNumber, issues) {
    if ($(part).attr("OriginalLength")) return; // only increase the height if it hasn't already been increased
    const length = $(part).attr("OriginalLength") || $(part).attr("Length");
    $(part).attr("OriginalLength", length);
    $(part).attr("Length", String(Number(length) + 12.7));
    issues.push({
      type: "end-panel-height-increase",
      msg: `Increasing the End Panel height for: ${cabNumber} from "${Number(length).toFixed(2)}" to "${(Number(length) + 12.7).toFixed(2)}"`,
    });
  }

  function switchWidthAndLength($, part, cabNumber, issues) {
    if ($(part).attr("DimensionsFlipped") == "True") return; // already flipped
    console.log("Flipping the width and length of the part for: ", cabNumber);
    const width = $(part).attr("Width");
    const length = $(part).attr("Length");
    $(part).attr("DimensionsFlipped", "True");
    $(part).attr("Width", length);
    $(part).attr("Length", width);
    issues.push({
      type: "switch-width-and-length",
      msg: `Flipping the width and length of the ${$(part).attr("Name")} for: ${cabNumber}. W: ${width}, L: ${length}`,
    });
  }

  function removeOverlappingHoles($, part, cabNumber, issues, flipSideOp) {
    const removedHoles = [];

    console.log("***************************************************");
    console.log("Looking for overlapping holes...");

    const holes = $(part)
      .find(`OperationHole[FlipSideOp="${flipSideOp}"]`)
      .toArray()
      .map(hole => $(hole).attr("uuid", uniqueId()));

    holes.forEach(hole => {
      const holeX = Number($(hole).attr("X"));
      const holeY = Number($(hole).attr("Y"));
      const uuid = $(hole).attr("uuid");

      if (removedHoles.includes(uuid)) return false;

      const overlappingHoles = holes.filter(h => {
        if ($(h).attr("uuid") === uuid) return false;
        const xDiff = Math.abs(holeX - Number($(h).attr("X")));
        const yDiff = Math.abs(holeY - Number($(h).attr("Y")));
        return xDiff <= 2 && yDiff <= 2;
      });

      if (overlappingHoles.length) {
        console.log("-------------");
        const allHoles = [hole, ...overlappingHoles];
        console.log(`Found ${allHoles.length} overlapping holes`);
        console.log({ holes: allHoles.map(hole => hole.attribs) });
        allHoles.sort((a, b) => Number($(b).attr("Depth")) - Number($(a).attr("Depth")));
        console.log({ allHoles });
        const holeToKeep = allHoles.shift();
        console.log({ holeToKeep });
        allHoles.forEach(hole => {
          issues.push({
            type: "removed overlapping hole",
            msg: `Removed an overlapping hole from ${$(part).attr("Name")} (${cabNumber}) at position X: ${$(hole).attr("X")}, Y: ${$(hole).attr("Y")} with Depth: ${$(hole).attr("Depth")}`,
          });
          removedHoles.push($(hole).attr("uuid"));
          $(hole).remove();
        });
      }
    });
  }

  function removeHolesOutsidePartShape($, part, cabNumber, issues) {
    console.log("***************************************************");
    console.log("Looking for holes outside of the part shape...");
    const maxY = $(part)
      .find("ShapePoint")
      .toArray()
      .reduce((max, point) => {
        return Number($(point).attr("Y")) > max ? Number($(point).attr("Y")) : max;
      }, 0);

    const maxX = $(part)
      .find("ShapePoint")
      .toArray()
      .reduce((max, point) => {
        return Number($(point).attr("X")) > max ? Number($(point).attr("X")) : max;
      }, 0);

    console.log({ maxX, maxY });

    $(part)
      .find("OperationHole")
      .toArray()
      .forEach(hole => {
        if (Number($(hole).attr("X")) > maxX || Number($(hole).attr("Y")) > maxY) {
          console.log("Found a hole that it outside the shape. Remove it now...");
          issues.push({
            type: "remove-hole-outside-shape",
            msg: `Removing hole outside the part shape from ${$(part).attr("Name")} (${cabNumber}) at position X: ${$(hole).attr("X")}, Y: ${$(hole).attr("Y")} with Depth: ${$(hole).attr("Depth")}`,
          });
          $(hole).remove();
        }
      });
  }

  function removeHolesNotFoundinMozaik($, part, linkedProduct, cabNumber, issues) {
    const optimizerHoles = $(part).find("OperationHole").toArray();
    const mozaikHoles = $(linkedProduct).find("OperationHole").toArray();

    const threshold = 0.01; // mm

    optimizerHoles.forEach(optimizerHole => {
      // do not remove holes that were intentionally added by the user
      // if (isManuallyAddedHole($, optimizerHole)) return;

      const { X, Y } = optimizerHole.attribs;

      const match = mozaikHoles.find(hole => {
        return Math.abs(hole.attribs.X - X) <= threshold && Math.abs(hole.attribs.Y - Y) <= threshold;
      });

      if (!match) {
        issues.push({
          type: "nonexistent-hole",
          msg: `Removing random hole added by optimizer for ${$(part).attr("Name")} (${cabNumber}) at position X: ${$(optimizerHole).attr("X")}, Y: ${$(optimizerHole).attr("Y")} with Depth: ${$(
            optimizerHole
          ).attr("Depth")}`,
        });
        $(optimizerHole).remove();
      }
    });
  }

  function getOptimizerPart($, partId) {
    return $(`OptimizePart[PartID="${partId}"]`);
  }

  function optimizePartHasOperationHoleThatIsFlipSideOp($, optimizePart) {
    return $(optimizePart).find('OperationHole[FlipSideOp="True"]').length > 0;
  }

  function getOptimizerSheets($) {
    return $("OptimizerSheet").toArray();
  }

  /**
   * https://docs.google.com/spreadsheets/d/1Bx2d79tcKN2GN0QbVRSpuGclLe5mkJUO09gMHcLl88E/edit#gid=0
   *
   * Get list of all ".opt" files from the job folder
   * Create a function to iterate each Optimizer Part and find the SourceLib and the PrintName
   * Iterate each Optimizer Part and create a JSON object of all the "criteria" attributes
   *
   * OptimizePart > Shape > ShapePoint
   * OptimizePart > Shape > Operations > OperationHole, OperationLineBore
   *
   * OptimizePart attributes: PrintName, Name, Width, Length, Quan
   * ShapePoint: X, Y
   * OperationHole: Diameter, X, Y, Depth, FlipSide
   * OperationLineBore: Quan, Diameter, X, Y, Depth, FlipSide
   *
   * Check whether part meets condition for a rule and if so modify part according to that rule
   * Keep track of which parts have been modified and which rules were applied
   * Output the modified XML and update the google drive file
   */

  return (
    <div className="mt-10">
      <JobSelect />
      <div id="operations">
        {files.length > 0 && (
          <>
            <h1 className="text-white text-xl uppercase mt-6 font-bold">OPERATIONS</h1>
            <div className="flex flex-col">
              <div className="mt-3 flex items-center">
                <input
                  className=" h-4 w-4"
                  type="checkbox"
                  checked={operations.romina}
                  onChange={() =>
                    setOperations({
                      ...operations,
                      romina: !operations.romina,
                    })
                  }
                />
                <div class="pl-1 text-sm text-gray-400">Romina Sara</div>
              </div>
              <div className="mt-3 flex items-center">
                <input
                  className=" h-4 w-4"
                  type="checkbox"
                  checked={operations.biggerHoles}
                  onChange={() =>
                    setOperations({
                      ...operations,
                      biggerHoles: !operations.biggerHoles,
                    })
                  }
                />
                <div class="pl-1 text-sm text-gray-400">7.5MM HOLES</div>
              </div>
              <OptimizerVBEdgeFix operations={operations} setOperations={setOperations} files={files} />
            </div>
          </>
        )}
      </div>
      <button
        ref={ref}
        onClick={sanitizeFiles}
        class={`mt-12 block w-full cursor-pointer items-center justify-center rounded-md bg-cyan-500 px-4 py-4 text-lg leading-6 text-white shadow-sm outline-gray-200 transition duration-150 ease-in-out hover:bg-cyan-400 focus:outline-offset-1 sm:leading-5 ${
          !files.length || !rooms.length ? "cursor-not-allowed opacity-50 hover:bg-cyan-500" : "opacity-100"
        }`}
      >
        {complete ? "Finished" : `Start`}
      </button>
      {files.length === 0 && <p className="my-1 text-sm font-semibold tracking-wide text-red-500">Unable to find any optimizer files</p>}
      <div className="mt-8 rounded-md border border-gray-400 p-8 text-sm text-white">
        <div className="py-1 text-base text-gray-400">Warnings</div>
        {report.map(issue => (
          <div className="py-2 text-sm">* {issue.msg}</div>
        ))}
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  jobFolder: state.job.job,
  rooms: state.room.rooms,
  params: state.room.params,
});

export default connect(mapStateToProps, { setAlert })(Optimizer);
