import React, { useState, useEffect } from "react";
import axios from "../../actions/axios";
import moment from "moment";
import { SpreadsheetApp } from "../../actions/sheets";

const RecentlyCreated = ({ jobFolder, record, quote = {}, setQuote, setRecord, setSpreadsheet, setProgress, setIsLoading }) => {
  const [files, setFiles] = useState([]);

  useEffect(() => {
    fetchFiles();
  }, [jobFolder, record, quote]);

  async function fetchFiles() {
    const params = {
      job_id: jobFolder?.id,
      potential_id: record?.module === "Potential" && record?.id ? record.id : undefined,
      quote_id: quote?.value && quote.value !== "new_quote" ? quote.value : undefined,
    };
    if (!Object.values(params).some(Boolean)) setFiles([]);
    else {
      const res = await axios.get("/api/quote", { params });
      setFiles(res.data);
    }
  }

  // async function loadRecentlyCreatedQuote(file) {
  //   console.warn(file);
  //   const { quote_id, drawings_id, spreadsheet_id, potential_id, job_name } = file;
  //   setRecord({ id: potential_id, module: "Potential", label: job_name });
  //   setTimeout(() => setQuote({ label: job_name, value: quote_id, id: quote_id }), 1000);
  //   const ss = await SpreadsheetApp.openById(spreadsheet_id);
  //   setSpreadsheet(ss);
  //   setProgress(9999);
  // }

  async function syncAll(file) {
    setIsLoading(true);

    const { quote_id, drawings_id, spreadsheet_id, potential_id, job_name, job_id } = file;
    setRecord({ id: potential_id, module: "Potential", label: job_name });
    setProgress(9999);

    const res = await axios.get("/api/gapi/sync-sheet", { params: { job_id, quote_id, drawings_id, spreadsheet_id } });
    console.log(res);

    if (!res.data.id) window.alert("Error! Something went wrong syncing the sheet and CRM");

    setQuote({ label: job_name, value: quote_id, id: quote_id });
    const ss = await SpreadsheetApp.openById(spreadsheet_id);
    setSpreadsheet(ss);

    setIsLoading(false);
  }

  return (
    <div>
      <div class="mb-3 text-sm font-medium text-gray-400 ">Recently Created</div>
      {files.map(file => {
        return (
          <div className="mb-3 flex items-center justify-between text-sm text-white">
            <div>{moment(file.date_updated).format("LLL")}</div>
            <div className="flex space-x-2">
              {/* <div onClick={() => loadRecentlyCreatedQuote(file)} className="cursor-pointer hover:opacity-75">
                <img src="https://img.icons8.com/?size=22&id=69347&format=png&color=9ca3af" />
              </div> */}
              <div onClick={() => syncAll(file)} className="cursor-pointer hover:opacity-75">
                <img src="https://img.icons8.com/?size=22&id=69347&format=png&color=9ca3af" />
              </div>
              <a href={`https://docs.google.com/spreadsheets/d/${file.spreadsheet_id}/edit`} target="_blank" className="cursor-pointer hover:opacity-75">
                <img src="https://img.icons8.com/?size=22&id=30453&format=png&color=9ca3af" />
              </a>
              <a href={`https://crm.zoho.com/crm/org51589495/tab/Quotes/${file.quote_id}`} target="_blank" className="cursor-pointer hover:opacity-75">
                <img src="https://img.icons8.com/ios-filled/22/9ca3af/sellsy.png" />
              </a>
              <a href={`https://drive.google.com/file/d/${file.drawings_id}/view`} target="_blank" className="cursor-pointer hover:opacity-75">
                <img src="https://img.icons8.com/?size=22&id=78741&format=png&color=9ca3af" />
              </a>
              <a href={`https://drive.google.com/drive/u/1/folders/${file.job_id}`} target="_blank" className="cursor-pointer hover:opacity-75">
                <img src="https://img.icons8.com/fluency-systems-filled/22/9ca3af/folder-invoices.png" />
              </a>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default RecentlyCreated;
