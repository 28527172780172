import React, { useEffect, useRef } from "react";
import domtoimage from "dom-to-image";
import jsPDF from "jspdf";
import { DocumentContext } from "./DocumentContext";
import { getPageDimensions } from "./utils";
import { roundUp } from "../../utils/general";

const defaultOptions = {
  pageSize: "letter",
  unit: "pt",
  orientation: "portrait",
  scale: 2,
  filename: "Document.pdf",
  bgColor: "white",
  preview: false,
  previewBorder: "1px solid yellow",
  centered: false,
};

const Document = ({ children, width, height, options, filename, setExportPDF, setDownloadPDF }) => {
  const ref = useRef();

  useEffect(() => {
    if (ref.current) ref.current.setAttribute("data-filename", filename);
  }, [filename]);

  const getSettings = () => ({ ...defaultOptions, ...options });
  const settings = getSettings();

  const exportPDF = async () => {
    const { pageSize, scale, bgColor, orientation, unit } = getSettings();
    const settings = getSettings();
    const el = ref.current;
    const filename = el.getAttribute("data-filename");
    const htmlWidth = el.clientWidth;
    const htmlHeight = el.clientHeight;
    const [pdfWidth, pdfHeight] = getPageDimensions(pageSize, orientation, unit);

    // The html element need only be the same aspect ratio as the pdf page, therefore we need to know the ratio of the html width to the pdf width to determine how scaled up the html element is relative to the pdf page size so we can get the de-scaled height in order to determine the number of pages

    const multiplier = htmlWidth / pdfWidth;

    // Since the html element is not the same pixel Width x Height as the pdf page (it's only the same aspect-ratio), the "descaledHeight" is basically saying what would the height be if we were to shrink down the html element of the entire document (all pages) so that the width of the html element is the same as the width of the PDF page.

    const descaledHeight = htmlHeight / multiplier;

    const pdfAspectRatio = pdfWidth / pdfHeight;
    const htmlAspectRatio = htmlWidth / htmlHeight;

    const totalPages = Math.ceil(descaledHeight / pdfHeight) - 1;

    console.log({ htmlWidth, htmlHeight, pdfWidth, pdfHeight, multiplier, descaledHeight, pdfAspectRatio, htmlAspectRatio: htmlAspectRatio * totalPages, totalPages });

    // @TODO: We have a problem because the aspect ratio of the html element is not EXACTLY the same as the pdf because the "aspect-ratio" css attribute does not allow the required number of decimal places

    // const style = {
    //   transform: "scale(" + settings.scale + ")",
    //   transformOrigin: "top left",
    //   width: el.offsetWidth + "px",
    //   height: el.offsetHeight + "px",
    // };

    // const params = {
    //   height: el.offsetHeight * scale,
    //   width: el.offsetWidth * scale,
    //   quality: 1,
    //   style,
    //   bgcolor: bgColor,
    // };

    // // remove the border preview when actually generating the PDF
    // ref.current.classList.add("!border-none"); // document
    // ref.current.querySelectorAll(".page").forEach(page => page.classList.add("!border-none")); // pages

    // domtoimage
    //   .toJpeg(ref.current, params)
    //   .then(async imgData => {
    //     const pdf = new jsPDF(orientation, "pt", [pdfWidth, pdfHeight]);
    //     pdf.addImage(imgData, "JPG", 0, 0, pdfWidth, descaledHeight);
    //     for (let i = 1; i <= totalPages; i++) {
    //       pdf.addPage([pdfWidth, pdfHeight], orientation);
    //       pdf.addImage(imgData, "JPG", 0, -(pdfHeight * i), pdfWidth, descaledHeight);
    //     }
    //     pdf.save(filename.endsWith(".pdf") ? filename : `${filename}.pdf`);

    //     // show the border preview again
    //     ref.current.classList.remove("!border-none"); // document
    //     ref.current.querySelectorAll(".page").forEach(page => page.classList.remove("!border-none")); // pages
    //   })
    //   .catch(err => {
    //     console.log(err);
    //   });

    const pdf = await generatePDF(ref.current, settings);
    pdf.save(filename.endsWith(".pdf") ? filename : `${filename}.pdf`);
  };

  const generatePDF = async (documentElement, settings) => {
    const { pageSize, scale, bgColor, orientation, unit } = settings;

    // remove the border preview when actually generating the PDF
    documentElement.classList.add("!border-none"); // document

    const pageImages = await Promise.all([...documentElement.querySelectorAll(".page")].map(page => getPageElementImageData(page, scale, bgColor)));

    const [pdfWidth, pdfHeight] = getPageDimensions(pageSize, orientation, unit);
    const pdf = new jsPDF(orientation, "pt", [pdfWidth, pdfHeight]);
    pageImages.forEach((page, i) => {
      if (i !== 0) pdf.addPage([pdfWidth, pdfHeight], orientation);
      pdf.addImage(page, "JPG", 0, 0, pdfWidth, pdfHeight);
    });

    // show the border preview again
    documentElement.classList.remove("!border-none"); // document

    return pdf;
  };

  const getPageElementImageData = async (page, scale, bgColor) => {
    page.classList.add("!border-none");
    const params = {
      height: page.offsetHeight * scale,
      width: page.offsetWidth * scale,
      quality: 1,
      bgcolor: bgColor,
      style: {
        transform: "scale(" + scale + ")",
        transformOrigin: "top left",
        width: page.offsetWidth + "px",
        height: page.offsetHeight + "px",
      },
    };
    const imgData = await domtoimage.toJpeg(page, params);
    page.classList.remove("!border-none");
    return imgData;
  };

  const downloadPDF = async () => {
    const { pageSize, scale, bgColor, orientation, unit } = getSettings();
    const settings = getSettings();
    const el = ref.current;
    // const filename = el.getAttribute("data-filename");
    const filename = "test.pdf";
    const htmlWidth = el.clientWidth;
    const htmlHeight = el.clientHeight;
    const [pdfWidth, pdfHeight] = getPageDimensions(pageSize, orientation, unit);

    // The html element need only be the same aspect ratio as the pdf page, therefore we need to know the ratio of the html width to the pdf width to determine how scaled up the html element is relative to the pdf page size so we can get the de-scaled height in order to determine the number of pages

    const multiplier = htmlWidth / pdfWidth;
    const descaledHeight = htmlHeight / multiplier;

    console.log({ pdfHeight, descaledHeight });

    const totalPages = Math.ceil(descaledHeight / pdfHeight) - 1;

    // const style = {
    //   transform: "scale(" + settings.scale + ")",
    //   transformOrigin: "top left",
    //   width: el.offsetWidth + "px",
    //   height: el.offsetHeight + "px",
    // };

    // const params = {
    //   height: el.offsetHeight * scale,
    //   width: el.offsetWidth * scale,
    //   quality: 1,
    //   style,
    //   bgcolor: bgColor,
    // };

    // OLD START
    // // remove the border preview when actually generating the PDF
    // ref.current.classList.add("!border-none"); // document
    // ref.current.querySelectorAll(".page").forEach(page => page.classList.add("!border-none")); // pages
    // const imgData = await domtoimage.toJpeg(ref.current, params);
    // const pdf = new jsPDF(orientation, "pt", [pdfWidth, pdfHeight]);
    // pdf.addImage(imgData, "JPG", 0, 0, pdfWidth, descaledHeight);
    // for (let i = 1; i <= totalPages; i++) {
    //   pdf.addPage([pdfWidth, pdfHeight], orientation);
    //   pdf.addImage(imgData, "JPG", 0, -(pdfHeight * i), pdfWidth, descaledHeight);
    // }

    // // show the border preview again
    // ref.current.classList.remove("!border-none"); // document
    // ref.current.querySelectorAll(".page").forEach(page => page.classList.remove("!border-none")); // pages
    // OLD END

    const pdf = await generatePDF(ref.current, settings);

    return pdf.output("dataurlstring", { filename: filename.endsWith(".pdf") ? filename : `${filename}.pdf` });
  };

  useEffect(() => {
    if (setExportPDF) setExportPDF(() => exportPDF);
    if (setDownloadPDF) setDownloadPDF(() => downloadPDF);
  }, [setExportPDF, setDownloadPDF]);

  const [pageWidth, pageHeight] = getPageDimensions(settings.pageSize, settings.orientation, settings.unit);

  console.log({ pageWidth, pageHeight });
  console.log(pageWidth / pageHeight);

  const style = {
    width: width || "unset",
    height: height || "unset",
    // aspectRatio: String(roundUp(pageWidth / pageHeight, 3)),
    aspectRatio: String(`${pageWidth}/${pageHeight}`),
    borderBottom: settings.preview ? settings.previewBorder : "",
    backgroundColor: settings.bgColor,
  };

  return (
    <DocumentContext.Provider value={{ width, height, settings }}>
      <div class="document-container" style={style} ref={ref}>
        {children}
      </div>
    </DocumentContext.Provider>
  );
};

export default Document;
