import { v4 as uuidv4 } from "uuid";
import { getMozaikParam } from "../params";
import { CabProdPart } from "./CabProdParts";
import { Door } from "./Door";
import { RaisedEndPanel } from "./RaisedEndPanel";
import { Drawer } from "./Drawer";
import { ROTray } from "./RolloutTray";
import { ROShelf } from "./RolloutShelves";

export class Product {
  constructor(ProductElement, Room) {
    this.$ = Room.$;
    this.ProductElement = Room.$(ProductElement);
    this.Room = Room;
  }
  attr(key) {
    return this.ProductElement.attr(key);
  }
  getProductElement() {
    return this.ProductElement;
  }
  getProductInstance() {
    return this;
  }
  getRoom() {
    return this.Room;
  }
  getWallNumber() {
    // 2_1 => 2
    return this.ProductElement.attr("Wall").split("_")[0];
  }
  getProductLibrary() {
    return this.ProductElement.attr("SourceLib");
  }
  getCabNumber() {
    // R2C4
    return `R${this.Room.getRoomNumber()}C${this.ProductElement.attr("CabNo")}`;
  }
  getKickStyle() {
    return ["Regular", "Face"][getMozaikParam(this.ProductElement, "KickStyle") - 1];
  }
  getCabProdParts() {
    return this.ProductElement.find("CabProdPart")
      .toArray()
      .map(elem => {
        elem = this.$(elem).attr("ID", uuidv4());
        return new CabProdPart(elem, this.getProductInstance());
      });
  }
  getDoors() {
    return this.ProductElement.find("ProductDoor")
      .toArray()
      .filter(elem => {
        const isRaisedEndPanel = this.$(elem).find('DoorProdPart[ReportName=" Raised end panel"]').length > 0;
        return !isRaisedEndPanel;
      })
      .map(elem => {
        elem = this.$(elem).attr("ID", uuidv4());
        return new Door(elem, this.getProductInstance());
      });
  }
  getRaisedEndPanels() {
    return this.ProductElement.find("ProductDoor")
      .toArray()
      .filter(elem => {
        const isRaisedEndPanel = this.$(elem).find('DoorProdPart[ReportName=" Raised end panel"]').length > 0;
        return isRaisedEndPanel;
      })
      .map(elem => {
        elem = this.$(elem).attr("ID", uuidv4());
        return new RaisedEndPanel(elem, this.getProductInstance());
      });
  }
  getDrawers() {
    return this.ProductElement.find("ProductDrawer")
      .toArray()
      .filter(elem => this.$(elem).attr("IsTray") == "False")
      .map(elem => {
        elem = this.$(elem).attr("ID", uuidv4());
        return new Drawer(this.$(elem), this.getProductInstance());
      });
  }
  getROTrays() {
    return this.ProductElement.find("ProductDrawer")
      .toArray()
      .filter(elem => this.$(elem).attr("IsTray") == "True")
      .map(elem => {
        elem = this.$(elem).attr("ID", uuidv4());
        return new ROTray(this.$(elem), this.getProductInstance());
      });
  }
  getROShelves() {
    return this.ProductElement.find("ProductRolloutShelf")
      .toArray()
      .map(elem => {
        elem = this.$(elem).attr("ID", uuidv4());
        return new ROShelf(this.$(elem), this.getProductInstance());
      });
  }
}
