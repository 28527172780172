import React, { useEffect, useState } from "react";
import { Zoho } from "../../actions/zoho";
import moment from "moment";

export const InstallationPackageDates = ({ potential, installDates, setInstallDates }) => {
  async function getInstallationDates() {
    console.log("fetching installation dates...");
    console.log(potential);
    const res = await Zoho.getRelatedListsForRecord("Potentials", potential.id, "Installations");
    console.log(res);
    if (res.length) {
      const scheduledInstalls = res.filter(date => date["Status"] === "Scheduled" && date["Expected_Install_Date"]);
      scheduledInstalls.sort((a, b) => new Date(a["Expected_Install_Date"]) - new Date(b["Expected_Install_Date"]));
      setInstallDates(scheduledInstalls);
    }
  }

  useEffect(() => {
    console.log("potential changed...");
    if (potential && potential.id) getInstallationDates();
  }, [potential]);

  return (
    <div id="installation-dates" className="mt-6 text-sm text-gray-400">
      <div className="font-medium">Installation Dates</div>
      <div className="mt-2 flex flex-col space-y-1 text-white">
        {installDates.map(date => {
          const { id, Expected_Install_Date } = date;
          const url = `https://crm.zoho.com/crm/org51589495/tab/CustomModule2/${id}`;
          return (
            <a href={url} target="_blank" rel="noreferrer" className="flex items-center space-x-2">
              {moment(Expected_Install_Date).format("dddd, MMMM Do YYYY [at] h:mm a")}
            </a>
          );
        })}
        {!installDates.length && <div className="text-red-400 font-medium">No installation dates found</div>}
      </div>
    </div>
  );
};
