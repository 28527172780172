import _, { groupBy } from "lodash";
import { roundNearest16th } from "../../utils/general";
import { mergeDuplicates } from "../mozaik/utils";

export const buildDrawerPage = async (ss, parts) => {
  // order parts by printname (to ensure consistent order)
  // group drawer parts into drawer groups by Id (i.e. drawer face, slides, handles all related to same drawer we group together)
  // find and group all drawer groups that have the same parts (compared by PrintName, Style, Width, Length, Material)
  // aggregate the parts within each group

  const aggregatedDrawerParts = groupDrawerParts(parts);

  // ensure all arrays have a length of 6

  const excludedDrawerParts = ["Filing Insert Corner", "Filing Insert Rail"];

  const partsArray = aggregatedDrawerParts
    .map(group => {
      group = group.filter(part => !excludedDrawerParts.includes(part["`PrintName"]));
      return [...group, ...Array(6 - group.length).fill({ "`Id": group[0]["`Id"] })];
    })
    .flat();

  const drawerSheet = await ss.getSheetByName("Drawers");

  const values = partsArray.map(part => {
    return [
      part["`Id"],
      part["`PrintName"],
      part["`PrintName"]?.includes("Drawer Face") ? part["`Style"] : "",
      part["`Qty"],
      part["`Width"]?.to("mm") || "",
      part["`PrintName"]?.includes("Slide |") ? roundNearest16th(`${part["`Length"]}"`) : part["`Length"]?.to("mm") || "",
      "",
      part["`Material"],
    ];
  });

  drawerSheet.getRange("A6:H").setValues(values);
};

// returns an array of arrays where the innermost array is an array of objects that represent the parts of the drawer. The higher level array is an array of drawers

export function groupDrawerParts(parts) {
  // order parts by printname (to ensure consistent order)
  // group drawer parts into drawer groups by Id (i.e. drawer face, slides, handles all related to same drawer we group together)
  // find and group all drawer groups that have the same parts (compared by PrintName, Style, Width, Length, Material)
  // aggregate the parts within each group

  const drawerParts = parts.filter(part => ["Door", "Drawer", "Tray"].includes(part["`Secondary Category"]) || part["`Primary Category"] == "Rollout Shelf");

  // exclude jewelry inserts, etc
  const filteredDrawerParts = drawerParts.filter(part => part["`Tertiary Category"] !== "Accessory");

  // drawerParts.sort((a, b) => a["`PrintName"] - b["`PrintName"]);
  const drawerGroups = Object.values(groupBy(filteredDrawerParts, "`Id")); // [ [{`Id, `Print Name, ...}, {}, {}],  [{}, {}], [{}, {}, {}] ]
  const commonDrawers = Object.values(
    groupBy(drawerGroups, drawer => {
      // [ [ [{}], [{}] ], [{}]] ] - An array of arrays of drawerGroups (which are arrays of part objects)
      // only include the attributes (keys) for each part that we want to include in the comparison
      const simplifiedParts = drawer.map(part => {
        return {
          "`PrintName": part["`PrintName"],
          "`Style": part["`Style"],
          "`Width": part["`Width"],
          "`Length": part["`Length"],
          "`Material": part["`Material"],
          "`CabNo": "",
        };
      });
      return JSON.stringify(simplifiedParts);
    })
  );
  // flatten the innermost array to break up the drawerGroup which separates parts by drawer and instead have parts grouped by commonDrawer,
  // meaning that all the parts of drawers that are the same are in one array which then goes through the merge process to combine duplicate parts
  const comparisonAttributes = ["`PrintName", "`Style", "`Width", "`Length", "`Material"];
  const aggregatedDrawerParts = commonDrawers.map(drawers => mergeDuplicates(drawers.flat(), comparisonAttributes));
  return aggregatedDrawerParts;
}

// function sortDrawerParts(drawers) {
//   return drawers;

//   // [
//   //   [{}, {}],
//   //   [{}, {}, {}]
//   // ]

//   const orderedGroups = drawers.map(group => {
//     const parts = group.map(part => ({ ...part, order: getSortOrderForPart(part) }));
//     parts.sort((a, b) => a.order - b.order);
//     return parts;
//   });
//   return orderedGroups;
// }
// function getSortOrderForPart(part) {
//   const ref = {
//     "Drawer Face": 1,
//     "Door Face": 2,
//   };

//   const printName = part["`PrintName"];
// }
