import React from "react";
import Image from "./Image";

const ImageGrid = ({ images }) => {
  const className = `grid h-full w-full ${images.length > 1 ? "grid-rows-2" : "grid-rows-1"} gap-2 bg-white p-3 ${images.length > 1 ? "grid-cols-2" : "grid-cols-1"}`;
  return (
    <div className={className}>
      {images.map(image => (
        <Image src={image.thumbnailLink.split("=")[0]} filename={image.label} />
      ))}
    </div>
  );
};

export default ImageGrid;
