import { Part } from "./Part";
import { roundNearest } from "../../../utils/general";

export class RaisedEndPanel extends Part {
  getFace() {
    return new RaisedEndPanelFace(this.PartElement, this.Product);
  }
  getStyle() {
    return this.getPartAttribute("DoorStyle");
  }
  getQuantity() {
    return 1;
  }
  getDoorElement() {
    return this.PartElement;
  }
}

export class RaisedEndPanelFace extends RaisedEndPanel {
  getPrintName() {
    return "Raised End Panel | " + this.getStyle();
  }
  getMaterial() {
    const SectAC = this.getDoorElement().attr("SectAC"); // "A" or "C"
    const frontFaceValue = this.getFrontFace().attr("MatDoorOR" + SectAC);
    const productValue = this.getProductAttribute("MatDoorOR");
    const roomValue = this.getRoomSetting("MatDoorTemplate");
    const material = frontFaceValue || productValue || roomValue;
    // @TODO: Should change this to be more specific door face material
    if (material === "Thermofoil") {
      return "Thermofoil | " + super.getMaterial().exclude('3/4" | ');
    }
    return '3/4" | ' + material.exclude("SLAB").trim();
  }
  getWidth() {
    const width = this.getPartAttribute("W");
    return Math.floor(roundNearest(width / 0.5, 1) * 0.5); // rounds down, unless .8mm or higher
  }
  getLength() {
    const length = this.getPartAttribute("H");
    return Math.floor(roundNearest(length / 0.5, 1) * 0.5); // rounds down, unless .8mm or higher
  }
}
