import React, { useState } from "react";
import { useEffect } from "react";
import { connect } from "react-redux";

const ProgressBar = ({ progress, roomLength }) => {
  if (!roomLength) return null;
  const pct = (progress / (roomLength + 1)) * 100;
  return (
    <div class="-mt-[6px] mb-4 h-1.5 w-full rounded-b-md rounded-bl-md bg-cyan-500 transition">
      <div class="relative h-1.5 transition">
        {/* <div class="left absolute h-1.5 w-[6px] rounded-b-md bg-cyan-900"></div> */}
        <div class={`${pct == 100 ? "rounded-br-md" : ""} absolute h-1.5 w-full rounded-bl-md bg-cyan-900 transition`} style={{ transform: `scaleX(${pct}%)`, transformOrigin: "left" }}></div>
      </div>
    </div>
  );
};

export default ProgressBar;
