import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import PrivateRoute from "./components/Routing/PrivateRoute";

// Alerts

import { Provider as AlertProvider } from "react-alert";
import AlertTemplate from "./components/Alerts/AlertTemplate";
import AlertOptions from "./components/Alerts/AlertOptions";
import Alert from "./components/Alerts/Alert";

// Actions
import { loadUser } from "./actions/auth";
import setAuthToken from "./utils/setAuthToken";

// Redux
import { Provider } from "react-redux";
import store from "./store";

// COMPONENTS

// Auth
import Register from "./pages/Register";
import Login from "./pages/Login";
import ResetPassword from "./pages/ResetPassword";
import UpdatePassword from "./pages/UpdatePassword";

import { Banner } from "./components/Layout/Banner";
import Home from "./pages/Home";
import Article from "./components/Support/Article";
import Playground from "./pages/Playground";
import Options from "./pages/Options";
import CoverPage from "./pages/CoverPage";

// Pass token from localStorage to axios header by default
if (localStorage.token) setAuthToken(localStorage.token);

const App = () => {
  useEffect(() => {
    store.dispatch(loadUser());
  }, []); // need the second parameter with empty brackets otherwise the function will loop infinitely and keep running

  return (
    <Provider store={store}>
      <Router>
        <AlertProvider className="w-1/2" template={AlertTemplate} {...AlertOptions}>
          <Alert />
          <div className="min-h-screen bg-gray-900">
            {/* <Navbar /> */}
            <Banner />
            <main>
              <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
                <section>
                  <Routes>
                    <Route path="/" element={<Login />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/play" element={<Playground />} />
                    <Route path="/register" element={<Register />} />
                    <Route path="/forgot-password" element={<ResetPassword />} />
                    <Route path="/update-password" element={<UpdatePassword />} />
                    <Route
                      path="/:mode"
                      element={
                        <PrivateRoute>
                          <Home />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/options"
                      element={
                        <PrivateRoute>
                          <Options />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/component"
                      element={
                        <PrivateRoute>
                          <CoverPage />
                        </PrivateRoute>
                      }
                    />
                  </Routes>
                </section>
              </div>
            </main>
          </div>
        </AlertProvider>
      </Router>
    </Provider>
  );
};

export default App;
