import React, { useState, useEffect } from "react";
import Select, { components } from "react-select";
import { Zoho } from "../../actions/zoho";
import moment from "moment";

const CutlistSelect = ({ record, cutlist, setCutlist }) => {
  cutlist = cutlist || { label: "Create New Cutlist", value: "new_cutlist" };

  const [options, setOptions] = useState([]);

  const styles = {
    control: (styles, { isFocused }) => ({
      ...styles,
      outline: "none",
      boxShadow: "none",
      borderColor: isFocused ? "#fff !important" : "transparent",
      ":hover": { borderColor: "#4b5563" },
      borderRadius: "0.5rem",
      borderWidth: "2px",
      backgroundColor: "rgb(31 41 55)",
      fontSize: "1rem",
      color: "white",
    }),
    multiValueRemove: styles => ({
      ...styles,
      ":hover": {
        backgroundColor: "lightgray",
        color: "black",
      },
    }),
    indicatorSeparator: styles => ({ display: "none" }),
    input: styles => ({ ...styles, color: "white", fontSize: "1rem", padding: "0.5rem", paddingLeft: "0", margin: "0" }),
    valueContainer: styles => ({ ...styles, margin: "0", paddingLeft: "1rem" }),
    singleValue: styles => ({ ...styles, color: "white", fontSize: "1rem" }),
    multiValue: styles => ({ ...styles, backgroundColor: "rgb(107 114 128)", marginLeft: "0", marginRight: "0.5rem", borderRadius: "0.5rem", color: "white", fontSize: "1rem" }),
    multiValueLabel: styles => ({ ...styles, color: "white" }),
    placeholder: styles => ({ ...styles, fontSize: "1rem", color: "rgb(107 114 128)", paddingLeft: "0" }),
    option: (styles, { isFocused }) => ({
      ...styles,
      fontSize: "0.75rem",
      cursor: "pointer",
      borderRadius: "0.25rem",
      backgroundColor: isFocused ? "rgb(107 114 128)" : "transparent",
      ":hover": {
        backgroundColor: "rgb(107 114 128)",
      },
    }),
    menu: styles => ({ ...styles, zIndex: "50", backgroundColor: "rgb(31 41 55)", color: "white", borderRadius: "0.5rem" }),
  };

  const handleChange = (e = {}) => {
    setCutlist(e);
  };

  const fetchOptions = async potentialId => {
    const records = await Zoho.getRelatedListsForRecord("Potentials", potentialId, "Cutlist");
    const options = records.map(record => ({ label: record.Name, value: record.id, modified_time: record.Modified_Time }));
    setOptions([{ label: "Create New Cutlist", value: "new_cutlist" }, ...options]);
  };

  useEffect(() => {
    setOptions([]);
    setCutlist({ label: "Create New Cutlist", value: "new_cutlist" });
    if (record.module === "Potential") fetchOptions(record.id);
  }, [record]);

  const Option = props => {
    return (
      <components.Option {...props}>
        <span class="text-sm font-medium">{props.data.label}</span>
        <div class="min-h-[18px] text-xs text-gray-300">{props.data.modified_time ? moment(props.data.modified_time).format("MMMM Do YYYY, h:mm a") : " "}</div>
      </components.Option>
    );
  };

  return (
    <div>
      <div className="my-3 mt-8 flex items-center space-x-2">
        <div className="text-sm font-medium text-gray-400">Cutlist</div>
        {cutlist && cutlist.value !== "new_cutlist" && (
          <a className="opacity-75 hover:opacity-100" target="_blank" href={`https://crm.zoho.com/crm/org51589495/tab/Cutlists/${cutlist.value}`}>
            <img src="https://img.icons8.com/material-outlined/20/9ca3af/external-link.png" />
          </a>
        )}
      </div>
      <Select isDisabled={record.module === "Contact"} components={{ Option }} onChange={handleChange} value={cutlist} isClearable={false} options={options} styles={styles} />
      {cutlist && cutlist.value !== "new_cutlist" && <p class="mt-2 ml-2 text-sm italic text-gray-400">*This will overwrite the existing data for this cutlist!</p>}
    </div>
  );
};

export default CutlistSelect;
