import { v4 as uuidv4 } from "uuid";
import store from "../../../store";
import { roundDownTo16th, roundNearest, roundNearest16th } from "../../../utils/general";
import { awaitUserInput } from "../../userInput";
import { getMozaikParam, getMozaikProductParam } from "../params";

export class Part {
  constructor(PartElement, Product, Room) {
    this.$ = Product.$;
    this.PartElement = Product.$(PartElement);
    this.Product = Product;
    this.ProductElement = Product.ProductElement;
    this.Room = Product.Room || Room;
  }
  // attribs = this.PartElement.attribs;

  attr(key) {
    return this.PartElement.attr(key);
  }
  getPartAttribute(key) {
    return this.PartElement.attr(key);
  }
  getProductAttribute(key) {
    return this.ProductElement.attr(key);
  }
  findProductParts(query) {
    return this.ProductElement.find(query)
      .toArray()
      .map(el => this.$(el));
  }
  findProductPart(query) {
    const PartElement = this.ProductElement.find(query).toArray()[0];
    return this.$(PartElement);
  }
  getFrontFace() {
    const SectIdx = this.getPartAttribute("SectIdx");
    return this.findProductPart("FrontFace Section[ID=" + SectIdx + "]");
  }
  getProductInterior() {
    let SectIdx = Number(this.getPartAttribute("SectIdx")); // Mozaik v9
    if (isNaN(SectIdx)) {
      SectIdx = this.$(this.PartElement.find('PartTagReference[Key="SectIdx"]')[0]).attr("Value");
    }
    return this.findProductParts("ProductInterior Section")[SectIdx - 1];
  }
  getMozaikParam(param) {
    return getMozaikParam(this.ProductElement, param);
  }
  getMozaikProductParam(param) {
    return getMozaikProductParam(this.ProductElement, param);
  }
  getPartRef() {
    return store.getState().reference.partRef;
  }
  getSlideRef() {
    return store.getState().reference.slideRef;
  }
  getMozaikPartRef() {
    return store.getState().reference.mozaikPartRef;
  }
  getDrawerFaceHeightRef() {
    return store.getState().reference.drawerFaceHeightRef;
  }
  getDrawerFaceWidthRef() {
    return store.getState().reference.drawerFaceWidthRef;
  }
  getRoom() {
    console.log("getting rooom.");
    return this.Product.getRoom();
  }
  getRoomSettings() {
    return this.Room.getRoomSettings();
  }
  getRoomSetting(key) {
    return this.getRoomSettings()[key];
  }
  getPrintName(overrideName) {
    // Components/Modal/PartDetailsModal.js
    const mozaikPartRef = this.getMozaikPartRef();

    if (overrideName && mozaikPartRef[overrideName]) return overrideName;

    // if Comment begins with "." then use this as the name override.
    const comment = this.getPartAttribute("Comment");

    if (comment && comment.startsWith(".")) {
      const name = comment.exclude(".").trim();
      if (mozaikPartRef[name]) return mozaikPartRef[name]["Print Name"];
      return name;
    }

    const name = this.getPartAttribute("Name");
    const reportName = this.getPartAttribute("ReportName");
    if (name == "Gable (CB)") return mozaikPartRef[name]["Print Name"];
    if (reportName && mozaikPartRef[reportName.trim()]) return mozaikPartRef[reportName.trim()]["Print Name"];
    if (mozaikPartRef[name]) return mozaikPartRef[name]["Print Name"];
  }
  getWidth() {
    return roundNearest16th(this.getPartAttribute("W"));
  }
  getLength() {
    return roundNearest16th(this.getPartAttribute("L"));
  }
  getSQFT() {
    return this.getWidth().to("ft") * this.getLength().to("ft");
  }
  async getLNFT() {
    const secondaryCategory = await this.getSecondaryCategory();
    if (secondaryCategory === "Gable" || secondaryCategory === "Shelf") return this.getLength().to("ft");
    else return Math.max(this.getWidth().to("ft"), this.getLength().to("ft"));
  }
  getStyle() {
    // @TODO
    return "";
  }
  getMeasurement(printName) {
    printName = printName || this.getPrintName();
    return this.getPartRef()[printName]["Measurement"];
  }
  getMaterial() {
    // @TEST MatCabOR override
    // first check for product override, otherwise default to room settings
    return (this.PartElement.attr("MatOR") || this.ProductElement.attr("MatCabOR") || this.getRoomSetting("MatCabTemplate")).replace("0.75 inch", '3/4"').replace("3-4 ", '3/4"');
  }
  getQuantity() {
    return this.getPartAttribute("Quan");
  }
  async getPrimaryCategory(printName) {
    printName = printName || this.getPrintName();
    if (!this.getPartRef()[printName]) await awaitUserInput("printName", this); // Components/Modal/PartDetailsModal.js
    return this.getPartRef()[printName]["Primary Category"];
  }
  async getSecondaryCategory(printName) {
    printName = printName || this.getPrintName();
    if (!this.getPartRef()[printName]) await awaitUserInput("printName", this); // Components/Modal/PartDetailsModal.js
    return this.getPartRef()[printName]["Secondary Category"];
  }
  async getTertiaryCategory(printName) {
    printName = printName || this.getPrintName();
    if (!this.getPartRef()[printName]) await awaitUserInput("printName", this); // Components/Modal/PartDetailsModal.js
    return this.getPartRef()[printName]["Tertiary Category"];
  }
  getCabNumber() {
    return this.Product.getCabNumber();
  }
  getWallNumber() {
    return this.Product.getWallNumber();
  }
  getId() {
    return this.PartElement.attr("ID") || uuidv4();
  }
  async json() {
    const printName = this.getPrintName();
    const partRef = this.getPartRef()[printName];
    if (!partRef) await awaitUserInput("printName", this);

    return {
      "`Id": this.getId(),
      "`Room Name": this.Room.getRoomNameWithNumber(),
      "`PrintName": printName,
      "`Qty": Number(this.getQuantity()),
      "`Material": this.getMaterial(),
      "`CabNo": this.getCabNumber(),
      "`Wall": this.getWallNumber(),
      "`Style": this.getStyle() || "",
      "`Width": this.getWidth().to("inch"),
      "`Length": this.getLength().to("inch"),
      "`Primary Category": await this.getPrimaryCategory(printName),
      "`Secondary Category": await this.getSecondaryCategory(printName),
      "`Tertiary Category": await this.getTertiaryCategory(printName),
      "`Measurement": this.getMeasurement(printName),
      "`LnFt": await this.getLNFT(),
      "`SqFt": this.getSQFT(),
    };
  }
}
