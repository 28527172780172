import * as cheerio from "cheerio";
import { Product } from "./Product";
import { Top } from "./Top";
import { Molding } from "./Molding";
import { getMozaikLibraryParam } from "../params";

export class Room {
  constructor(xml, roomNumber) {
    this.$ = cheerio.load(xml, { xmlMode: true });
    this.roomNumber = roomNumber;
  }
  getRoomName() {
    return this.$("Room").attr("Name");
  }
  getRoomNumber() {
    return this.roomNumber;
  }
  getRoomNameWithNumber() {
    return `${this.getRoomName()} [R${this.getRoomNumber()}]`;
  }
  getRoomSettings() {
    return this.$("RoomSet")[0].attribs;
  }
  getRoomSetting(key) {
    return this.getRoomSettings()[key];
  }
  getRoomParams() {
    return this.$("RoomParms")[0].attribs;
  }
  getNotes() {
    return this.$("Note")
      .toArray()
      .map(note => this.$(note).attr("Text"))
      .join("\n\n");
  }
  // getNotes() {
  //   return this.$("Note")
  //     .toArray()
  //     .map(note => this.$(note).attr("Text").split("\n"));
  // }
  getWallHeight() {
    return Number(this.$("Wall").attr("Height")).to("inches").toFixed(2);
  }
  getProducts() {
    return this.$("Product")
      .toArray()
      .map(product => new Product(product, this));
  }
  getLibraries() {
    const products = this.getProducts();
    return [...new Set(products.map(product => product.getProductLibrary()))];
  }
  getActiveWalls() {
    return this.$("Product")
      .toArray()
      .map(productElement => {
        const product = new Product(productElement, this);
        return `R${this.roomNumber}W${product.getWallNumber()}`;
      });
  }
  isActive() {
    return this.getActiveWalls().length > 0;
  }
  getTops() {
    return this.$("Top")
      .toArray()
      .map(top => new Top(top, this));
  }
  getMoldings() {
    return this.$("Molding")
      .toArray()
      .map(molding => new Molding(molding, this));
  }
  getBaseboard() {
    // get all the libraries used in the room
    const libraries = this.getLibraries();
    const baseboards = libraries.map(library => {
      const BBNH = Number(getMozaikLibraryParam(library, "BBNH") || 0)
        .to("inches")
        .toFixed(2);
      const BBND = Number(getMozaikLibraryParam(library, "BBND") || 0)
        .to("inches")
        .toFixed(2);
      const SHNH = Number(getMozaikLibraryParam(library, "SHNH") || 0)
        .to("inches")
        .toFixed(2);
      const SHND = Number(getMozaikLibraryParam(library, "SHND") || 0)
        .to("inches")
        .toFixed(2);
      console.log({ BBNH, BBND, SHNH, SHND });
      if ([BBNH, BBND, SHNH, SHND].every(val => val == "0.00")) return null;
      return BBNH + " x " + BBND + ", " + SHNH + " x " + SHND;
    });
    return baseboards.filter(Boolean).join(" | ");
  }
  getDoorHeight() {
    const libraries = this.getLibraries();
    const doorHeights = libraries.map(library => {
      const doorHeight = Number(getMozaikLibraryParam(library, "DoorHeight") || 0);
      if (doorHeight == 0) return null;
      else return doorHeight.to("inches").toFixed(2);
    });
    return doorHeights.filter(Boolean).join(" | ");
  }
}
