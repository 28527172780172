import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import MarkaeLogo from "../components/MarkaeLogo/MarkaeLogo";
import ProcessModeSelector from "../components/Home/ProcessModeSelector";
import JobSelect from "../components/Jobs/JobSelect";
import RecordSelect from "../components/Zoho/RecordSelect";
import PartDetailsModal from "../components/Modal/PartDetailsModal";
import Quote from "./Quote/Quote";
import Production from "./Home/Production";
import Drawings from "./Home/Drawings";

import { getPartReferences } from "../actions/mozaik/main";

import { useRef } from "react";
import SVGCanvas from "../actions/dxfs/canvas";
import { Drive } from "../actions/google";
import Optimizer from "./Home/Optimizer";
import OptimizerParts from "./Home/OptimizerParts";
import CNCOptimizer from "./Home/CNCOptimizer";
import InstallationPackage from "./InstallationPackage/InstallationPackage";
import Transfer from "./Home/Transfer";

const Home = ({ jobFolder, getPartReferences }) => {
  const params = useParams();
  const [mode, setMode] = useState(params.mode); // quote | production | drawings | optimizer | cnc-optimizer
  const [record, setRecord] = useState(null);
  const [jobParts, setJobParts] = useState([]);

  useEffect(() => {
    getPartReferences();
  }, []);

  useEffect(() => {
    setMode(params.mode);
  }, [params.mode]);

  useEffect(() => {
    if (jobFolder.name) {
      setJobParts([]);
      setRecord(null);
    }
  }, [jobFolder]);

  return (
    <div className="pt-10">
      <PartDetailsModal />
      <MarkaeLogo />
      <ProcessModeSelector mode={mode} setMode={setMode} />
      {mode == "quote" && <Quote record={record} setRecord={setRecord} jobParts={jobParts} setJobParts={setJobParts} />}
      {mode == "production" && <Production record={record} setRecord={setRecord} jobParts={jobParts} setJobParts={setJobParts} />}
      {mode == "drawings" && (
        <div className="mt-10">
          <JobSelect />
          <Drawings />
        </div>
      )}
      {mode == "optimizer" && <Optimizer jobParts={jobParts} setJobParts={setJobParts} />}
      {mode == "optimizer-parts" && <OptimizerParts jobParts={jobParts} setJobParts={setJobParts} />}
      {/* {mode == "cnc-optimizer" && <CNCOptimizer jobParts={jobParts} setJobParts={setJobParts} />} */}
      {mode == "installation" && <InstallationPackage record={record} setRecord={setRecord} jobParts={jobParts} setJobParts={setJobParts} />}
      {mode == "transfer" && <Transfer />}
      {/* 
      <CreateProduction images={dxfs} /> */}
      {/* {dxfs.map(dxf => {
        return <SVGCanvas key={dxf.id} svg={dxf.svg} jobFolderId={jobFolder.id} dxf={dxf} />;
      })} */}
    </div>
  );
};

const mapStateToProps = state => ({
  jobFolder: state.job.job,
});

export default connect(mapStateToProps, { getPartReferences })(Home);
