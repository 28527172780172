import React from "react";

const MarkaeLogo = () => {
  return (
    <div className="my-8 flex w-full justify-center">
      <a tabIndex="-1" href="https://markaeclosets.com" target="_blank" rel="noreferrer" className="block w-96 transition duration-300 hover:scale-105">
        <img className="w-96" src="https://markaeclosets.com/wp-content/uploads/2017/11/Asset-12@2x.png" alt="Markae Closets Logo" />
      </a>
    </div>
  );
};

export default MarkaeLogo;
