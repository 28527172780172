import _, { countBy, maxBy, sumBy } from "lodash";
import React, { useState, useEffect, useMemo } from "react";

const JobCoverPageRowRoomColumn = ({ room, count }) => {
  const data = useMemo(() => {
    if (!room || !room.length) return {};
    const roomName = room[0]["`Room Name"];
    const material = _(room).countBy("`Material").entries().maxBy(_.last)[0];
    const drwFace = room.find(part => part["`Primary Category"] === "Drawer Face");
    const drwStyle = drwFace && drwFace["`PrintName"].exclude("Drawer Face | ");
    const doorFace = room.find(part => part["`Primary Category"] === "Door Face");
    const doorStyle = doorFace && doorFace["`PrintName"].exclude("Door Face | ");
    const drwPull = [...new Set(room.filter(part => part["`PrintName"].includes("Drw Pull")).map(part => part["`PrintName"].exclude("Drw Pull | ")))];
    const doorPull = room.find(part => part["`PrintName"].includes("Door Pull"));
    const molding = room.find(part => part["`PrintName"].includes("Molding"));
    const shelves = room.filter(part => part["`Secondary Category"] === "Shelf");
    const gablesAndBackings = room.filter(part => part["`Secondary Category"] === "Gable" || part["`Secondary Category"] === "Backing");
    const closetRods = room.filter(part => part["`PrintName"].includes("Closet Rod"));
    const accessories = [...new Set(room.filter(part => part["`Tertiary Category"] === "Accessory" && part["`Secondary Category"] !== "Closet Rod").map(part => part["`PrintName"].excludeAll(" |")))];
    return { roomName, material, drwStyle, drwPull, doorStyle, doorPull, molding, closetRods, shelves, gablesAndBackings, accessories };
  }, [room]);

  return (
    <div class="w-[210px] overflow-hidden border border-l-0 border-black text-xs" style={{ fontSize: "13px" }}>
      <div class="h-8 border-b border-b-black pl-1 font-bold">{data.roomName}</div>
      <div class="h-8 pl-1">{data.material || "-"}</div>
      <div class="h-8 pl-1">{data.drwStyle || "-"}</div>
      <div class="h-8 pl-1">{data.drwPull.length ? data.drwPull.join(", ") : "-"}</div>
      <div class="h-8 pl-1">{data.doorStyle || "-"}</div>
      <div class="h-8 pl-1">{(data.doorPull && data.doorPull["`PrintName"].exclude("Door Pull | ")) || "-"}</div>
      <div class="h-8 pl-1">{data.molding ? data.molding["`PrintName"].split(" | ")[1] : "-"}</div>
      <div class="h-8 pl-1">{data.closetRods.length ? data.closetRods[0]["`PrintName"].exclude("Closet Rod | ") : "-"}</div>
      <div class="h-20 pl-1">{data.accessories.length ? data.accessories.join(", ") : "-"}</div>
      <div class="border-t border-t-black pl-2 font-bold">{data.shelves.length ? sumBy(data.shelves, "`Qty") : 0}</div>
      <div class="pl-2 font-bold">{data.gablesAndBackings.length ? sumBy(data.gablesAndBackings, "`Qty") : 0}</div>
      <div class="pl-2 font-bold">{data.closetRods.length ? sumBy(data.closetRods, "`Qty") : 0}</div>
    </div>
  );
};

export default JobCoverPageRowRoomColumn;
