import { SET_RESPONSE_STATE, CLEAR_RESPONSE_STATE, SET_PENDING_PART } from "../actions/types";

const defaultState = {
  input: {},
  pendingPart: null,
};

const userInputReducer = (state = { ...defaultState }, { type, payload }) => {
  switch (type) {
    case SET_PENDING_PART:
      return {
        ...state,
        pendingPart: payload,
      };
    case SET_RESPONSE_STATE:
      return {
        ...state,
        input: {
          ...state.input,
          [payload.key]: payload.value,
        },
      };
    case CLEAR_RESPONSE_STATE:
      return {
        ...state,
        input: {
          ...state.input,
          [payload.key]: undefined,
        },
        pendingPart: null,
      };
    default:
      return state;
  }
};

export default userInputReducer;
