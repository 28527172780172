import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Select, { components } from "react-select";
import { fetchJobs, setJob, setJobData } from "../../actions/job";
import moment from "moment";
import { getRooms, resetRoomState } from "../../actions/mozaik/rooms";
import { getMozaikLibraryParams } from "../../actions/mozaik/params";

const JobSelect = ({ value, options, autoFetch = true, fetchJobs, setJob, setJobData, getRooms, resetRoomState, getMozaikLibraryParams, images, getDXFs }) => {
  const [folder, setFolder] = useState("data");

  useEffect(() => {
    if (!options.length && autoFetch) fetchJobs();
  }, [fetchJobs, options.length]);

  useEffect(() => {
    if (options.length) fetchJobs(folder);
  }, [folder]);

  const styles = {
    control: (styles, { isFocused }) => ({
      ...styles,
      outline: "none",
      boxShadow: "none",
      borderColor: isFocused ? "#fff !important" : "transparent",
      ":hover": { borderColor: "#4b5563" },
      borderRadius: "0.5rem",
      borderWidth: "2px",
      backgroundColor: "rgb(31 41 55)",
      fontSize: "1rem",
      color: "white",
    }),
    multiValueRemove: styles => ({
      ...styles,
      ":hover": {
        backgroundColor: "lightgray",
        color: "black",
      },
    }),
    indicatorSeparator: styles => ({ display: "none" }),
    input: styles => ({ ...styles, color: "white", fontSize: "1rem", padding: "0.5rem", paddingLeft: "0", margin: "0" }),
    valueContainer: styles => ({ ...styles, margin: "0", paddingLeft: "1rem" }),
    singleValue: styles => ({ ...styles, color: "white", fontSize: "1rem" }),
    multiValue: styles => ({ ...styles, backgroundColor: "rgb(107 114 128)", marginLeft: "0", marginRight: "0.5rem", borderRadius: "0.5rem", color: "white", fontSize: "1rem" }),
    multiValueLabel: styles => ({ ...styles, color: "white" }),
    placeholder: styles => ({ ...styles, fontSize: "1rem", color: "rgb(107 114 128)", paddingLeft: "0" }),
    option: (styles, { isFocused }) => ({
      ...styles,
      fontSize: "0.75rem",
      borderRadius: "0.25rem",
      backgroundColor: isFocused ? "rgb(107 114 128)" : "transparent",
      ":hover": {
        backgroundColor: "rgb(107 114 128)",
      },
    }),
    menu: styles => ({ ...styles, zIndex: "50", backgroundColor: "rgb(31 41 55)", color: "white", borderRadius: "0.5rem" }),
  };

  const Option = props => {
    return (
      <components.Option {...props}>
        <span class="text-sm font-medium">{props.data.label}</span>
        <div class="text-xs text-gray-300">{moment(props.data.modifiedTime).format("MMMM Do YYYY, h:mm a")}</div>
      </components.Option>
    );
  };

  const handleChange = jobFolder => {
    resetRoomState();
    setJob(jobFolder);
    if (jobFolder) {
      getRooms(jobFolder.id);
      getMozaikLibraryParams(jobFolder.id);
      setJobData(jobFolder);
    }
  };

  //TODO: Utilize the information in advanced options to determine which rooms to include in the script run

  return (
    <div class="relative">
      <div class="mb-3 flex items-center space-x-2 text-sm font-medium text-gray-400">
        <div>Mozaik Project</div>
        {value.id && (
          <a className="opacity-75 hover:opacity-100" target="_blank" href={`https://drive.google.com/drive/u/1/folders/${value.id}`}>
            <img src="https://img.icons8.com/material-outlined/20/9ca3af/external-link.png" />
          </a>
        )}
      </div>
      <Select autoFocus={true} value={value.id ? value : null} components={{ Option }} placeholder="Select a job..." onChange={handleChange} isClearable={true} options={options} styles={styles} />
      <div className="flex space-x-4">
        <div className="mt-3 flex items-center">
          <input className=" h-4 w-4" type="checkbox" value={folder == "taylor"} checked={folder == "taylor"} onChange={() => setFolder("taylor")} />
          <div class="pl-1 text-sm text-gray-400">Taylor's Computer</div>
        </div>
        <div className="mt-3 flex items-center">
          <input className=" h-4 w-4" type="checkbox" checked={folder == "kaylie"} value={folder == "kaylie"} onChange={() => setFolder("kaylie")} />
          <div class="pl-1 text-sm text-gray-400">Kaylie's Computer</div>
        </div>
      </div>
      {/* {value.id && images.length > 0 && (
        <Link to="/options" tabIndex={-1} class="absolute right-0 my-3 mt-2 block cursor-pointer text-right text-sm font-medium text-gray-400 hover:underline">
          Advanced Options
        </Link>
      )} */}
      {value.id && images.length == 0 && <div class="absolute right-0 my-3 mt-2 block cursor-pointer text-right text-sm font-medium text-gray-400">Loading...</div>}
    </div>
  );
};

const mapStateToProps = state => ({
  options: state.job.jobs,
  value: state.job.job,
  images: state.room.images,
});

export default connect(mapStateToProps, { fetchJobs, setJob, getRooms, resetRoomState, getMozaikLibraryParams, setJobData })(JobSelect);
