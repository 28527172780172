import { Room } from "./classes/Room";
import { mergeDuplicates, addFaceKickStrips } from "./utils";
import { awaitUserInput } from "../userInput";

export const getRoomParts = async roomData => {
  const room = new Room(roomData.xml, roomData.number);
  const roomName = room.getRoomName();
  const roomSet = room.getRoomSettings();
  const roomParams = room.getRoomParams();
  const { MatCabTemplate, MatBandingTemplate } = roomSet;
  const notes = room.getNotes();
  const wallHeight = room.getWallHeight();
  const baseboardObj = room.getBaseboard();
  const doorHeight = room.getDoorHeight();

  const products = room.getProducts();
  console.log("Products: ", products.length);

  let parts = [];

  for (const product of products) {
    console.log(product.getRoom().getRoomName(), product.getWallNumber(), product.getCabNumber());
    const cabProdParts = product.getCabProdParts();
    for (const cabProdPart of cabProdParts) {
      // const printName = cabProdPart.getPrintName() || (await getPartDetailsFromUser(cabProdPart));
      const printName = cabProdPart.getPrintName() || (await awaitUserInput("printName", cabProdPart));
      if (await cabProdPart.isExcluded(printName)) continue;
      const part = await cabProdPart.getPart(printName);
      const data = await part.json();
      parts.push(data);
    }

    const doors = product.getDoors();
    for (const door of doors) {
      const doorFace = await door.getDoorFace().json();
      const doorInsert = await door.getDoorInsert().json();
      const doorPull = await door.getDoorPull().json();
      const hinges = await door.getDoorHinges().json();
      parts = [...parts, doorFace, doorInsert, doorPull, hinges];
    }

    const raisedEndPanels = product.getRaisedEndPanels();
    for (const raisedPanel of raisedEndPanels) {
      const raisedPanelFace = await raisedPanel.getFace().json();
      parts = [...parts, raisedPanelFace];
    }

    const drawers = product.getDrawers();
    // console.log("drawers: ", drawers.length);
    for (const drawer of drawers) {
      const drawerFace = await drawer.getDrawerFace().json();
      const drawerPull = await drawer.getDrawerPull().json();
      const drawerBoxSides = await drawer.getDrawerBoxSides().json();
      const drawerBoxFrontBack = await drawer.getDrawerBoxFrontBack().json();
      const drawerBoxBottom = await drawer.getDrawerBoxBottom().json();
      const drawerSlides = await drawer.getDrawerSlides().json();
      const drawerFileRails = await drawer.getFileRails().json();
      const drawerFileCorners = await drawer.getFileCorners().json();
      const drawerDividers = await drawer.getDrawerDividers().json();
      const drawerLock = await drawer.getDrawerLock().json();
      const drawerFelt = await drawer.getDrawerFelt().json();
      const drawerJewelryInsert = await drawer.getDrawerJewelryInsert().json();
      parts = [
        ...parts,
        drawerFace,
        drawerPull,
        drawerBoxFrontBack,
        drawerBoxSides,
        drawerSlides,
        drawerBoxBottom,
        drawerFileRails,
        drawerFileCorners,
        drawerDividers,
        drawerLock,
        drawerFelt,
        drawerJewelryInsert,
      ];
    }

    const ROTrays = product.getROTrays();
    // console.log("ROTrays: ", ROTrays.length);
    for (const ROTray of ROTrays) {
      const ROTraySides = await ROTray.getROTraySides().json();
      const ROTrayFrontBack = await ROTray.getROTrayFrontBack().json();
      const ROTrayBottom = await ROTray.getROTrayBottom().json();
      const ROTraySlides = await ROTray.getROTraySlides().json();
      parts = [...parts, ROTrayFrontBack, await ROTraySides, ROTraySlides, ROTrayBottom];
    }

    const ROShelves = product.getROShelves();
    // console.log("ROShelves: ", ROShelves.length);
    for (const ROShelf of ROShelves) {
      const Shelf = await ROShelf.getShelf().json();
      const Slides = await ROShelf.getSlides().json();
      parts = [...parts, Shelf, Slides];
    }

    // @TODO: Confirm that all accessories (i.e. Mozaik Inserts) get captured by CabProdParts
  }

  const moldings = room.getMoldings();
  // console.log("moldings: ", moldings.length);
  for (const molding of moldings) {
    const lengths = await molding.getLengths();
    parts = [...parts, ...lengths];
  }

  const tops = room.getTops();
  // console.log("tops: ", tops.length);

  for (const top of tops) {
    const topPart = await top.json();
    parts = [...parts, topPart];
  }

  // Calculate number of strips for Face Kicks

  parts = addFaceKickStrips(parts);

  // remove any parts where quantity is 0
  const filteredParts = parts.filter(part => Number(part["`Qty"]) > 0);

  const mergedParts = mergeDuplicates(filteredParts, ["`PrintName", "`Room Name", "`CabNo", "`Width", "`Length", "`Material"], ["Drawer", "Door", "Tray", "Rollout Shelf"]);

  return mergedParts;
};

function getCabNumberWithLeadingZeros(cabNo) {
  cabNo = cabNo.replace(/R[0-9]+/, "R" + cabNo.extract(/R([0-9]+)/).padStart(2, "0"));
  return cabNo.replace(/C[0-9]+/, "C" + cabNo.extract(/C([0-9]+)/).padStart(2, "0"));
}

export function getSheetValues(parts) {
  parts.sort((a, b) => {
    const aKey = getCabNumberWithLeadingZeros(a["`CabNo"]) + a["`PrintName"];
    const bKey = getCabNumberWithLeadingZeros(b["`CabNo"]) + b["`PrintName"];
    if (aKey < bKey) return -1;
    else if (aKey > bKey) return 1;
    else return 0;
  });
  const values = parts.map((part, i) => {
    return [i, ...Object.values(part)];
  });
  return values;
}
