import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";

import Button from "../../components/Elements/Button";
import JobSelect from "../../components/Jobs/JobSelect";
import RecordSelect from "../../components/Zoho/RecordSelect";
import RecentlyCreatedCutlists from "../Home/RecentlyCreatedCutlists";

import { SpreadsheetApp } from "../../actions/sheets";
import { DriveApp } from "../../actions/DriveApp";
import { Drive } from "../../actions/google";
import { Zoho } from "../../actions/zoho";
import { setAlert } from "../../actions/alert";
import InstallerAgreementForm from "./InstallerAgreementForm";
import { addPartsToPriceSheet, createNewPriceSheet } from "../Quote/quoteUtils";
import Drawings from "../Home/Drawings";
import { InstallationPackageDates } from "./InstallationPackageDates";
import { waitForCondition } from "../../utils/general";
import { useCutlists } from "../../stores/cutlists";
import { useJobParts } from "../../stores/parts";

const InstallationPackage = ({ record, setRecord, rooms, jobFolder, setAlert }) => {
  const { fetchCutlists } = useCutlists();
  const [downloadPDF, setDownloadPDF] = useState(null);
  const [comp, setComp] = useState({ actual: 0, total: 0, percent: 0 });
  const [installDates, setInstallDates] = useState([]);
  const [drawings, setDrawings] = useState([]);
  const [ready, setReady] = useState(false);

  console.log({ drawings });

  const spreadsheetRef = useRef(null);
  const drawingsRef = useRef(null);

  const { fetchParts } = useJobParts();

  async function calculateInstallerComp() {
    const ss = await createNewPriceSheet();
    const jobParts = await fetchParts(rooms);
    spreadsheetRef.current.href = ss.getUrl();
    addPartsToPriceSheet(ss, jobParts);

    async function isPriceSheetReady(ss) {
      const sheet = await ss.getSheetByName("Summary");
      const values = await sheet.getRange("F38:F41").getValues();
      console.log("waitforcondition: ", values.flat()[0]);
      return values.flat()[0] !== "$0";
    }

    await waitForCondition(isPriceSheetReady, [ss], 30000, 1000);
    const sheet = await ss.getSheetByName("Summary");
    const values = await sheet.getRange("F38:F41").getValues();
    const [actual, additional, total, percent] = values.flat();
    setComp({ actual, additional, total, percent });
  }

  async function updateDrawings() {
    setReady(false);
    setDrawings([]);
    const drawingsFolder = await getDrawingsFolder();
    const files = await drawingsFolder.getFiles();
    if (files.length) files.forEach(file => file.setTrashed(true));
    await Promise.all([upload3DImages(drawingsFolder), uploadInstallPackagePDF(drawingsFolder)]);
    setReady(true);
  }

  async function uploadDrawings() {
    console.log("Upload Drawings function called...");
    const drawingsFolder = await getDrawingsFolder();
    drawingsRef.current.href = drawingsFolder.getUrl();
    const files = await drawingsFolder.getFiles();
    console.log({ files });
    if (files.length) {
      console.log("Drawings already exist in the folder, setting state now...");
      setDrawings(files);
      setReady(true);
      return;
    }
    await Promise.all([upload3DImages(drawingsFolder), uploadInstallPackagePDF(drawingsFolder)]);
    const filesAfterUpload = await drawingsFolder.getFiles();
    setDrawings(filesAfterUpload);
    setReady(true);
  }

  async function getDrawingsFolder() {
    const potentialId = record.id;
    const potential = await Zoho.getRecord("Potentials", potentialId);
    const potentialFolderId = potential.Job_Folder_Link.exclude("https://drive.google.com/open?id=");
    const potentialFolder = await DriveApp.getFolderById(potentialFolderId);
    const installationFolder = await potentialFolder.getFolder("Installation", true);
    const drawingsFolder = await installationFolder.getFolder("Drawings", true);
    return drawingsFolder;
  }

  async function upload3DImages(drawingsFolder) {
    console.log("Uploading 3D Images...");
    await waitForCondition(() => typeof downloadPDF === "function", [], 30000, 1000);
    console.log("Finished waiting for downloadPDF function...");
    const pdf = await downloadPDF();
    console.log("Retrieved the 3D images PDF...");
    const filename = `${jobFolder.name} 3D Drawings`;
    const drawings = await drawingsFolder.createFile(filename, pdf, "application/pdf");
    const drawingsId = drawings.getId();
    const drawingsUrl = drawings.getUrl();
    console.log({ drawingsId, drawingsUrl });

    return drawings;
  }

  async function uploadInstallPackagePDF(drawingsFolder) {
    const cutlists = await fetchCutlists({
      job_id: jobFolder?.id,
      potential_id: record?.module === "Potential" && record?.id ? record.id : undefined,
    });
    if (!cutlists.length) return setAlert("error", "You must first generate a cutlist for this job.");
    const latestCutlist = cutlists[0];
    const ssId = latestCutlist.spreadsheet_id;
    console.log({ ssId });
    const ss = await SpreadsheetApp.openById(ssId);
    const sheets = await ss.getSheets();
    const sheetsToHide = sheets.filter(sheet => sheet.getName().includes("Cutlist"));
    await Promise.all(sheetsToHide.map(sheet => sheet.hideSheet()));
    const base64 = await ss.exportPDF(false, true, false, 0.1, 0.1, 0.1, 0.1);
    const filename = `${jobFolder.name} 2D Drawings`;
    const file = await Drive.createFile(filename, "application/pdf", base64, drawingsFolder.getId());
    console.log("finishing uploading the install package...");
    console.log({ file });
    return file;
  }

  useEffect(() => {
    if (record?.id) uploadDrawings();
  }, [record]);

  useEffect(() => {
    setRecord(null);
  }, [jobFolder]);

  useEffect(() => {
    if (rooms.length) calculateInstallerComp();
  }, [rooms]);

  async function handlePrint() {
    // const file = pkg.pdf;
    // const fileId = file.id;
    // const name = jobFolder.name;
    // const params = { fileId, name };
    // console.log({ params });
    // axios.get("/api/gapi/print-cutlist", { params });
    // alert("Success! The cutlist has been sent to the printer");
  }

  return (
    <div className="grid grid-cols-12">
      <div className="col-span-8 mt-1 rounded-lg border border-gray-700 p-8">
        <div className="mb-6 flex items-center">
          <div className="mr-2 text-xl font-medium text-gray-200">INSTALLATION PACKAGE & AGREEMENT</div>
          <img src="https://img.icons8.com/windows/24/374151/New-production-order.png" />
        </div>
        <JobSelect />
        <RecordSelect record={record} setRecord={setRecord} jobFolder={jobFolder} />

        {record && !comp.total && (
          <div className="mt-12">
            <div className="text-xl font-medium text-white">Calculating Installation Compensation...</div>
          </div>
        )}

        <div className={`mt-4 hidden ${comp.total && "!block"}`}>
          <InstallationPackageDates potential={record} installDates={installDates} setInstallDates={setInstallDates} />
          <div className="my-6 text-sm flex items-center space-x-4 text-white">
            <a ref={drawingsRef} className="py-2 px-4  bg-cyan-500 rounded-md" target="_blank">
              Drawings Folder
            </a>
            <a ref={spreadsheetRef} className="py-2 px-4  bg-cyan-500 rounded-md" target="_blank">
              View Spreadsheet
            </a>
          </div>
          <InstallerAgreementForm
            comp={comp}
            record={record}
            jobFolder={jobFolder}
            numberOfInstallDays={installDates.length}
            drawings={drawings}
            ready={ready}
            setReady={setReady}
            updateDrawings={updateDrawings}
            setAlert={setAlert}
          />
        </div>
      </div>

      <div className="col-span-4 mt-1 ml-2">
        <div className="h-full w-full rounded-lg border border-gray-700 p-8">
          <RecentlyCreatedCutlists record={record} jobFolder={jobFolder} />
        </div>
      </div>

      <div id="drawings" className={`col-span-12 mt-2 mb-6 rounded-lg border border-gray-700 p-8 hidden ${record && "!block"}`}>
        <Drawings setDownloadPDF={setDownloadPDF} includeDownloadBtn={false} />
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  jobFolder: state.job.job,
  rooms: state.room.rooms,
});

export default connect(mapStateToProps, { setAlert })(InstallationPackage);
