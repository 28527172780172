import React from "react";

const Image = ({ src, filename }) => {
  return (
    <div className="flex flex-1 flex-col">
      <div className="border bg-white py-2">
        <img className="w-full " src={"https://res.cloudinary.com/markae/image/fetch/" + src} />
        {/* <img className="w-full " src={src} referrerPolicy="no-referrer" /> */}
      </div>
      <div className="flex h-full items-center justify-center bg-gray-200 text-center">
        {/* <input className="bg-gray-200 text-center outline-none" /> */}
        <div className="flex h-full items-center justify-center bg-gray-200 text-center">{filename}</div>
      </div>
    </div>
  );
};

export default Image;
