import { getRoomParts, getSheetValues } from "../../actions/mozaik/parts";
import { SpreadsheetApp } from "../../actions/sheets";

export async function createNewPriceSheet() {
  const priceTemplateId = "1AOKB_5_ebVbK4KY8q4-ANFCS5m24fLyqWk7mEcXB3OA";
  const folderId = "1S8luHGRupq20AU9TllrOa5P3WNV_DRFp";
  const ss = await SpreadsheetApp.createSpreadsheet("New", folderId, priceTemplateId);
  return ss;
}

export async function getJobParts(rooms) {
  const parts = await Promise.all(rooms.map(room => getRoomParts(room)));
  return parts.flat();
}

export async function addPartsToPriceSheet(spreadsheet, jobParts) {
  const sheetValues = getSheetValues(jobParts);
  const sheet = await spreadsheet.getSheetByName("Components");
  sheet.getRange(2, 1, sheetValues.length, sheetValues[0].length).setValues(sheetValues);
}
