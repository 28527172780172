import React, { useState, useEffect, useMemo } from "react";
import axios from "../actions/axios";
import axiosX from "../actions/axiosX";
import { Drive } from "../actions/google";
import { Trimble } from "../actions/trimble";
import { Zoho } from "../actions/zoho";

import * as cheerio from "cheerio";
import { getRoomFiles } from "../actions/mozaik/rooms";
import { SpreadsheetApp } from "../actions/sheets";
import { getPriceCategoryCriteria } from "../actions/mozaik/priceCategory";

const Playground = () => {
  const [term, setTerm] = useState("");
  const [records, setRecords] = useState([]);

  const fetchOptions = async potentialId => {
    const res = await axiosX.get("https://drive.google.com/file/d/19Dzjrrx4Y0Fr1AIBYu97lQEIj4aSg8rr/view?usp=sharing");
    console.log(res);

    // const priceTemplateId = "1AOKB_5_ebVbK4KY8q4-ANFCS5m24fLyqWk7mEcXB3OA";
    // const folderId = "";
    // const ss = await SpreadsheetApp.createSpreadsheet("New", folderId, priceTemplateId);
    // const sheet = await ss.getSheetByName("Components");
    // const row = await sheet.getLastRow();
    // console.log(row);
    // const column = await sheet.getLastColumn();
    // console.log(column);
    // const res = await getRoomFiles("1q4VlcbBcWQWSlc9qdBSsf6likGYuxOSB", true);
    // const room = res[0];
    // const xml = room.content.slice(room.content.indexOf("<?xml"));
    // const $ = cheerio.load(xml, { xmlMode: true });
    // const roomName = $("Room").attr("Name");
    // const roomSet = $("RoomSet")[0].attribs;
    // const roomParams = $("RoomParms")[0].attribs;
    // const { MatCabTemplate, MatBandingTemplate } = roomSet;
    // const notes = $("Note")
    //   .toArray()
    //   .map(note => note.attr("Text").split("\n"));
    // console.log({ roomSet, roomParams, MatCabTemplate });

    // const priceTemplateId = "1AOKB_5_ebVbK4KY8q4-ANFCS5m24fLyqWk7mEcXB3OA";
    // const folderId = "";
    // const ss = await SpreadsheetApp.createSpreadsheet("New", folderId, priceTemplateId);
    // const sheets = await ss.getSheets();
    // const sheet = sheets[0];
    // console.log(sheet.getName());
    // await sheet.hideRows(4, 3);
    // await sheet.setColumnWidth(2, 200);
    // await sheet.formatColumn(3, { bold: true, backgroundColor: "yellow-400", align: "right", borders: { outer: false, inner: false }, borderColor: "red-500", borderWidth: 3 });
    // const range = sheet.getRange(1, 1, 2, 2);
    // await range.formatRange({
    //   bold: true,
    //   backgroundColor: "indigo-200",
    //   align: "left",
    //   borders: { outer: true },
    // });
    // console.log(range.getA1Notation(true));
    // const values = await range.getValues();
    // console.log(values);
    // await range.clearValues();
  };

  useEffect(() => {
    const potentialId = "1494268000050398065";
    fetchOptions(potentialId);
  }, []);

  // const debounce = (fn, delay) => {
  //   let timer;
  //   return function (...args) {
  //     clearTimeout(timer);
  //     timer = setTimeout(() => {
  //       fn(...args);
  //     }, delay);
  //   };
  // };

  // function b64toBlob(dataURI, type) {
  //   var byteString = atob(dataURI.split(",")[1]);
  //   var ab = new ArrayBuffer(byteString.length);
  //   var ia = new Uint8Array(ab);

  //   for (var i = 0; i < byteString.length; i++) {
  //     ia[i] = byteString.charCodeAt(i);
  //   }
  //   return new Blob([ab], { type });
  // }

  // let getFiles = async term => {
  //   // const res = await Zoho.getRecord("Deals", "1494268000049675051");
  //   console.log("searching...");

  //   const res = await Drive.copyFileToTrimble("159dgv2B8brkoH-8MBdr8I40z1fYHlu7H", "CiVGzCZ0adA");
  //   console.log(res);

  //   // setRecords(res);
  // };

  // getFiles = useMemo(() => debounce(getFiles, 300), []);

  // useEffect(() => {
  //   if (term) getFiles(term);

  //   // const folders = drive('getFolders', { id: '', name: '', parent: folder })

  //   // folders.getFiles()
  // }, [term]);

  const [isVisible, setModalVisibility] = useState(false);

  const handleChange = e => {
    const value = e.target.value;
    setTerm(value);
  };

  const handleClose = e => {
    setTerm("Closed Modal");
    setModalVisibility(false);
  };

  const handleClick = async (SpreadsheetApp, term) => {
    // const code = `(async () => {
    //   const { SpreadsheetApp } = ${require("../actions/sheets")}
    //   ${term}
    // })()`;
    // console.log(code);
    // eval(code);
  };

  return (
    <div className="mt-6">
      <textarea class="w-3/4 rounded-lg px-2 py-2 text-xs" onChange={handleChange} value={term} />
      <button className="ml-4 rounded-lg bg-gray-500 px-6 py-2 text-white" onClick={e => handleClick(SpreadsheetApp, term)}>
        Submit
      </button>
    </div>
  );
};

export default Playground;
