import { create } from "zustand";
import { getJobParts } from "../pages/Quote/quoteUtils";

export const useJobParts = create(set => ({
  jobParts: [],
  fetchParts: async rooms => {
    const parts = await getJobParts(rooms);
    set({ jobParts: parts });
    return parts;
  },
}));
