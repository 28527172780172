import { roundDownToArrayValue, roundNearest16th } from "../../../utils/general";
import { Part } from "./Part";

export class ROShelf extends Part {
  getShelf() {
    return new Shelf(this.PartElement, this.Product);
  }
  getSlides() {
    return new Slides(this.PartElement, this.Product);
  }

  // @TODO: Confirm the below is correct - seems suspect
  getBoxStyle() {
    const productInteriorValue = this.getProductInterior().attr("ROTrayOR");
    // const productValue = this.product.attr("MatDrawerOR"); // No productValue?
    const roomValue = this.getRoomSetting("ROTray");
    return productInteriorValue || roomValue;
  }
  getStyle() {
    return this.getBoxStyle();
  }
}

export class Shelf extends ROShelf {
  getPrintName() {
    return "Rollout Shelf";
  }
  getQuantity() {
    return 1;
  }
  getWidth() {
    return roundNearest16th(this.PartElement.attr("D"));
  }
  getLength() {
    return roundNearest16th(this.PartElement.attr("W"));
  }
  getMaterial() {
    const productInteriorValue = this.getProductInterior().attr("MatCabOR");
    const productValue = this.getProductAttribute("MatCabOR");
    const roomValue = this.getRoomSetting("MatCabTemplate");
    return (productInteriorValue || productValue || roomValue).replace("0.75 inch", '3/4"').replace("0.625 inch", '5/8"');
  }
}

export class Slides extends ROShelf {
  getSlideName() {
    // This uses attribute "D" instead of "L"
    const sideL = Math.floor(this.PartElement.attr("D"));
    return this.getSlideType(sideL);
  }
  getSlideType(sideL) {
    // https://docs.google.com/spreadsheets/d/1Y_z4UBHkGduOzmW_37kIVKnM9Bu1kephiW1f8X8JT6c/edit#gid=104635460
    const slideRef = this.getSlideRef();
    const key = roundDownToArrayValue(sideL, Object.keys(slideRef));
    const mozaikSlideName = this.PartElement.attr("GuideName");
    return slideRef[key].replace("Slide | Soft Close", mozaikSlideName).exclude("Standard");
  }
  getPrintName() {
    return this.getSlideName();
  }
  getStyle() {
    return this.getSlideName();
  }
  getQuantity() {
    // each tray has two slides (or 1 set)
    return 2;
  }
  getWidth() {
    return 0;
  }
  getLength() {
    const slide = this.getSlideName();
    return slide
      .slice(slide.length - 3)
      .exclude('"')
      .to("mm");
  }
}
