// Root Reducer
import { combineReducers } from "redux";

const reducers = ["auth", "alert", "tag", "bookmark", "user", "job", "room", "reference", "userInput", "progress"];

const obj = reducers.reduce((res, reducer) => {
  const r = require(`./${reducer}`);
  res[reducer] = r.default;
  return res;
}, {});

export default combineReducers(obj);
