import axios from "./axios";
import { SET_JOB, SET_JOBS, RESET_JOB, DELETE_JOB, UPDATE_JOB, CREATE_JOB, SET_JOB_DATA, SET_PARTS } from "./types";
import { setAlert } from "./alert";
import { Drive } from "./google";
import { getJobData } from "./mozaik/main";

export const fetchJobs = folder => async dispatch => {
  try {
    // @TODO: Need to limit number of folders retrieved (i.e. fetch 1000 most recent jobs)
    // Option to search archive

    dispatch({
      type: SET_JOBS,
      payload: [{ label: `Pulling ${folder}'s files...` }],
    });

    let rootFolderId;

    if (folder === "taylor") rootFolderId = "1--LLq2iJshmhqJtfLdpZ0OjKtMTjuLl9";
    else if (folder === "cnc") rootFolderId = "0B8Du9lMtaJzBMXYxX0NIdGNlWE0";
    else if (folder === "kaylie") rootFolderId = "1-0B6rI3mXXUHEpqjaL6sOmg1NNfiEKMU";
    else rootFolderId = "1f6CQY1iWqXQnQkxN_Ng13FuL2FTSlRV1";

    console.log({ rootFolderId });

    const jobFolders = await Drive.getFolders({
      parent: rootFolderId,
      options: { fields: "modifiedTime", limit: 1000 },
    });
    dispatch({
      type: SET_JOBS,
      payload: jobFolders.map(folder => ({ ...folder, label: folder.name, value: folder.id })),
    });
  } catch (err) {
    if (err.response) dispatch(setAlert("error", err.response.data.msg));
  }
};

export const setJobData = jobFolder => async dispatch => {
  const jobData = await getJobData(jobFolder);
  dispatch({
    type: SET_JOB_DATA,
    payload: jobData,
  });
};

export const setParts = parts => async dispatch => {
  dispatch({
    type: SET_PARTS,
    payload: parts,
  });
};

export const setJob = job => async dispatch => {
  dispatch({
    type: SET_JOB,
    payload: job,
  });
};

export const setJobs = jobs => async dispatch => {
  dispatch({
    type: SET_JOBS,
    payload: jobs,
  });
};

export const resetJob = () => async dispatch => {
  dispatch({
    type: RESET_JOB,
  });
};

// export const upsertJob =
//   (data, showAlert = true) =>
//   async dispatch => {
//     try {
//       const res = await axios.post(`/api/jobs`, data);
//       const job = res.data;
//       if (showAlert) dispatch(setAlert("success", "Success! The changes were successfully saved."));
//       dispatch({
//         type: data._id ? UPDATE_JOB : CREATE_JOB,
//         payload: job,
//       });
//       return job;
//     } catch (err) {
//       if (err.response) dispatch(setAlert("error", err.response.data.msg));
//     }
//   };

// export const deleteJob = id => async dispatch => {
//   try {
//     await axios.delete(`/api/jobs/${id}`);
//     dispatch({
//       type: DELETE_JOB,
//       payload: { id },
//     });
//     dispatch(setAlert("success", "Successfully deleted the job"));
//   } catch (err) {
//     if (err.response) dispatch(setAlert("error", err.response.data.msg));
//   }
// };
