import React, { useState, useEffect } from "react";
import axios from "../../actions/axios";
import moment from "moment";
import { useCutlists } from "../../stores/cutlists";
import { Package } from "../../actions/packages/init";
import { SpreadsheetApp } from "../../actions/sheets";
import { Zoho } from "../../actions/zoho";

const RecentlyCreatedCutlists = ({ jobFolder, record, cutlist }) => {
  const { cutlists, fetchCutlists } = useCutlists();

  const [isRunning, setIsRunning] = useState(false);

  async function getCutlists() {
    const res = await fetchCutlists({
      job_id: jobFolder?.id,
      potential_id: record?.module === "Potential" && record?.id ? record.id : undefined,
      cutlist_id: cutlist?.value && cutlist.value !== "new_cutlist" ? cutlist.value : undefined,
    });
    console.log({ res });
  }

  useEffect(() => {
    getCutlists();
  }, [jobFolder, record, cutlist]);

  async function handlePrint(file) {
    const fileId = file.pdf_id;
    const name = jobFolder.name;
    const params = { fileId, name };
    const res = axios.get("/api/gapi/print-cutlist", { params });
    alert("Success! The cutlist has been sent to the printer");
  }

  async function syncAll(file) {
    try {
      console.log({ file });

      setIsRunning(true);

      const { _id, cutlist_id, spreadsheet_id, potential_id } = file;

      const data = {
        jobName: jobFolder.name,
        folderId: jobFolder.id,
      };

      if (!jobFolder || !jobFolder.id) {
        alert("Error! Job folder not found");
        return;
      }

      const ss = await SpreadsheetApp.openById(spreadsheet_id);

      const pkg = new Package(null, data, null, ss);

      await pkg.exportPDF();
      console.log("done exporting pdf...");

      const ssId = pkg.getSpreadsheetId();
      const spreadsheetUrl = `https://docs.google.com/spreadsheets/d/${ssId}/edit`;

      console.log(`https://drive.google.com/file/d/${pkg.pdf.id}/view`);
      console.log({ spreadsheetUrl });

      // create install package
      pkg.exportInstallPackage(potential_id);
      console.log("done exporting install package...");

      Zoho.updateRecord("Cutlists", {
        id: cutlist_id,
        Spreadsheet: spreadsheetUrl,
        PDF: `https://drive.google.com/file/d/${pkg.pdf.id}/view`,
        Mozaik_Folder: `https://drive.google.com/drive/u/1/folders/${jobFolder.id}`,
      });

      console.log("done updating cutlist...");

      const res = await axios.post("/api/cutlist", {
        _id,
        job_id: jobFolder.id,
        job_name: jobFolder.name,
        cutlist_id: cutlist_id,
        potential_id: potential_id,
        pdf_id: pkg.pdf.id,
        spreadsheet_id: ssId,
      });

      console.log({ res });

      console.log("DONE!!!!!! FINISHED ALL PRODUCTION");

      getCutlists();

      setIsRunning(false);
    } catch (err) {
      console.log(err);
      alert("Error! Something went wrong: " + err.message);
      setIsRunning(false);
    }
  }

  return (
    <div>
      {isRunning && (
        <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50 flex items-center justify-center">
          <div className="bg-white p-5 rounded-lg">
            <div className="text-lg font-semibold text-gray-800">Generating PDF...</div>
            <div className="text-sm text-gray-600">Please wait while we export the cutlist to PDF</div>
          </div>
        </div>
      )}
      <div class="mb-3 text-sm font-medium text-gray-400 ">Recently Created</div>
      {cutlists.map(file => {
        return (
          <div className="mb-3 flex items-center justify-between overflow-scroll text-sm text-white">
            <div>{moment(file.date_updated).format("LLL")}</div>
            <div className="flex space-x-2">
              <div onClick={() => syncAll(file)} className="cursor-pointer hover:opacity-75">
                <img src="https://img.icons8.com/?size=22&id=69347&format=png&color=9ca3af" />
              </div>
              <a href={`https://docs.google.com/spreadsheets/d/${file.spreadsheet_id}/edit`} target="_blank" className="cursor-pointer hover:opacity-75">
                <img src="https://img.icons8.com/?size=22&id=30453&format=png&color=9ca3af" />
              </a>
              <a href={`https://crm.zoho.com/crm/org51589495/tab/CustomModule6/${file.cutlist_id}`} target="_blank" className="cursor-pointer hover:opacity-75">
                <img src="https://img.icons8.com/ios-filled/22/9ca3af/sellsy.png" />
              </a>
              <a href={`https://drive.google.com/file/d/${file.pdf_id}/view`} target="_blank" className="cursor-pointer hover:opacity-75">
                <img src="https://img.icons8.com/?size=22&id=78741&format=png&color=9ca3af" />
              </a>
              <a href={`https://drive.google.com/drive/u/1/folders/${file.job_id}`} target="_blank" className="cursor-pointer hover:opacity-75">
                <img src="https://img.icons8.com/fluency-systems-filled/22/9ca3af/folder-invoices.png" />
              </a>
              <a onClick={() => handlePrint(file)} target="_blank" className="cursor-pointer hover:opacity-75">
                <img src="https://img.icons8.com/ios-filled/22/9ca3af/print.png" />
              </a>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default RecentlyCreatedCutlists;
