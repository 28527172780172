import { Drive } from "../../../actions/google";
import * as cheerio from "cheerio";

export function remove_non_ascii(str) {
  if (str === null || str === "") return false;
  else str = str.toString();
  return str.replace(/[^\x20-\x7E]/g, "");
}

export async function load(file) {
  const utf16 = await Drive.readFile(file.id);
  const content = remove_non_ascii(utf16);
  const xml = content.slice(content.indexOf("<?xml"));
  console.log({ xml });
  return cheerio.load(xml, { xmlMode: true });
}

export function getMaxLineBoreQuantity($, part) {
  const lineBoreQuantities = $(part)
    .find("OperationLineBore")
    .toArray()
    .map(bore => $(bore).attr("Quan"));
  return Math.max(0, ...lineBoreQuantities);
}

export function createLinkedProductFunction(rooms) {
  const cache = {};
  return function (assemblyNumber) {
    // @TODO: Think about how to handle N cabinets that span multiple products (because that means there isnt a linked product)
    if (assemblyNumber.includes("N")) return null;

    if (!cache[assemblyNumber]) {
      const roomNumber = assemblyNumber.extract(/R([0-9]+)C/);
      const cabNumber = assemblyNumber.extract(/R[0-9]+C([0-9]+)/);
      console.log({ roomNumber, cabNumber });
      const room = rooms[roomNumber - 1];
      console.log({ room });
      const $ = cheerio.load(room.xml, { xmlMode: true });
      const product = $(`Product[CabNo=${cabNumber}]`)[0];
      cache[assemblyNumber] = product;
    }
    return cache[assemblyNumber];
  };
}

export function isNumber(str) {
  return !isNaN(str);
}

export function isManuallyAddedHole($, hole) {
  return $(hole).attr("IdTag") != "9999";
}

export function adjustHoles($) {
  function getRotation(partID) {
    let rotation = $(`OptimizePartLocation[PartID="${partID}"]`).attr("Rotation");
    if (rotation == "270") {
      $(`OptimizePartLocation[PartID="${partID}"]`).attr("Rotation", "90");
      return 90;
    } else if (rotation == "180") {
      $(`OptimizePartLocation[PartID="${partID}"]`).attr("Rotation", "0");
      return 0;
    }
    return rotation;
  }

  function moveHole(hole, axis, amountToIncrease) {
    const value = $(hole).attr(`Original${axis}`) || $(hole).attr(axis);
    $(hole).attr(`Original${axis}`, value);
    console.log("Original: ", $(hole).attr(`Original${axis}`));
    $(hole).attr(axis, String(Number(value) + amountToIncrease));
  }

  function resetHole(hole) {
    console.log("resetting the hole...");
    const X = $(hole).attr(`OriginalX`) || $(hole).attr("X");
    $(hole).attr("X", X);
    const Y = $(hole).attr(`OriginalY`) || $(hole).attr("Y");
    $(hole).attr("Y", Y);
  }

  function moveHolesForPart(part, axis, amountToIncrease) {
    $(part)
      .find("OperationHole")
      .toArray()
      .forEach(hole => {
        resetHole(hole);
        moveHole(hole, axis, amountToIncrease);
      });

    $(part)
      .find("OperationLineBore")
      .toArray()
      .forEach(hole => {
        resetHole(hole);
        moveHole(hole, axis, amountToIncrease);
      });
  }

  // function increaseYAxis(part, amountToIncrease) {
  //   const OperationHoles = $(part).find("OperationHole").toArray();
  //   const LineBores = $(part).find("OperationLineBore").toArray();

  //   OperationHoles.forEach(hole => {
  //     const Y = $(hole).attr("OriginalY") || $(hole).attr("Y");
  //     $(hole).attr("OriginalY", Y);
  //     $(hole).attr("Y", String(Number(Y) + amountToIncrease));
  //   });

  //   LineBores.forEach(hole => {
  //     const Y = $(hole).attr("OriginalY") || $(hole).attr("Y");
  //     $(hole).attr("OriginalY", Y);
  //     $(hole).attr("Y", String(Number(Y) + amountToIncrease));
  //   });
  // }

  // function increaseXAxis(part, amountToIncrease) {
  //   const OperationHoles = $(part).find("OperationHole").toArray();
  //   const LineBores = $(part).find("OperationLineBore").toArray();

  //   OperationHoles.forEach(hole => {
  //     const X = $(hole).attr("OriginalX") || $(hole).attr("X");
  //     $(hole).attr("OriginalX", X);
  //     $(hole).attr("X", String(Number(X) + amountToIncrease));
  //   });

  //   LineBores.forEach(hole => {
  //     const X = $(hole).attr("OriginalX") || $(hole).attr("X");
  //     $(hole).attr("OriginalX", X);
  //     $(hole).attr("X", String(Number(X) + amountToIncrease));
  //   });
  // }

  $("OptimizePart")
    .toArray()
    .forEach(part => {
      const amountToIncrease = 1; // millimetres

      const partId = $(part).attr("PartID");
      const rotation = getRotation(partId);

      console.log({ partId, rotation });

      console.log("Rotation: ", Number(rotation));

      // If 90 degree rotation, need to adjust X axis
      if (Number(rotation) == 90) {
        moveHolesForPart(part, "X", amountToIncrease);
      }
      // If 0 degree rotation, need to adjust Y axis
      else if (Number(rotation) == 0) {
        moveHolesForPart(part, "Y", amountToIncrease);
      }
      // else throw an error
      else {
        alert(`Error: rotation ${rotation}`);
      }
    });
}
