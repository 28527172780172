import React, { useState, useEffect, useMemo } from "react";
import ModalContainer from "./ModalContainer";
import Button from "../Elements/Button";
import { connect } from "react-redux";
import { setUserInput } from "../../actions/userInput";
import Input from "../Elements/Input";
import { addToPartReference } from "../../actions/reference";
import { SpreadsheetApp } from "../../actions/sheets";
import PartDetailsModalSelect from "./PartDetailsModalSelect";
import { getPriceCategoriesForPart } from "../../actions/mozaik/priceCategory";

const PartDetailsModal = ({ part, setUserInput, addToPartReference, priceCategories }) => {
  const [data, setData] = useState({});
  const [error, setError] = useState("");
  const [templateValue, setTemplateValue] = useState(null);

  useEffect(() => {
    setData({
      ...data,
      "Print Name": part ? part.getPrintName() || part.attr("ReportName") : "",
      "Room Name": part && part.getRoom().getRoomNameWithNumber(),
      CabNo: part && part.getCabNumber(),
    });
  }, [part]);

  const handleClose = () => {
    // user cannot close modal until they have added the new part
    setError("You must specify the part details before you can continue!");
  };

  const reset = () => setData({});

  const handleSubmit = async () => {
    const isValid = validateData(data);
    if (isValid) {
      await addPartToReferenceSheet(data);
      addToPartReference(data);
      setUserInput("printName", data["Print Name"]);
      reset();
    }
  };

  const addPartToReferenceSheet = async data => {
    const ssId = "1Y_z4UBHkGduOzmW_37kIVKnM9Bu1kephiW1f8X8JT6c";
    const ss = await SpreadsheetApp.openById(ssId);
    const sheet = await ss.getSheetByName("Reference");
    const values = [data["Print Name"], data["Print Name"], data["Primary Category"], data["Secondary Category"], data["Tertiary Category"], data["Material Category"]];
    await sheet.appendRow(values);
  };

  const validateData = data => {
    const required = ["Print Name", "Primary Category", "Secondary Category", "Tertiary Category", "Material"];
    const missing = required.filter(field => !data[field]);
    if (!missing.length) return true;
    setError(`You are missing the following information: <ul>${missing.map(field => `<li>- ${field}</li>`).join("")}</ul>`);
  };

  const handleChange = e => {
    console.log("handleChange: ", e);
    setData({
      ...data,
      [e.target.id]: e.target.value,
    });
  };

  const handleTemplateSelect = obj => {
    const e = obj.target;
    setTemplateValue(e);
    setData({
      ...data,
      "Primary Category": e ? e["Primary Category"] : "",
      "Secondary Category": e ? e["Secondary Category"] : "",
      "Tertiary Category": e ? e["Tertiary Category"] : "",
      Material: e ? e["Material"] : "",
    });
  };

  const categories = useMemo(() => getPriceCategoriesForPart(data, priceCategories), [data]);

  return (
    <div>
      <ModalContainer isVisible={part} handleClose={handleClose}>
        <div className="pt-10 pb-6 text-center text-2xl text-white">Add New Part</div>
        <div className="py-6 text-center text-base italic text-gray-300 flex flex-col">
          <div>Room: {data["Room Name"] || ""}</div>
          <div>CabNo: {data["CabNo"] || ""}</div>
        </div>
        <div className="flex flex-col gap-4">
          <div class="border-b border-b-gray-400 pb-4">
            <PartDetailsModalSelect onChange={handleTemplateSelect} value={templateValue} />
          </div>

          <Input label="Print Name" id="Print Name" onChange={handleChange} value={data["Print Name"]} autoFocus={true} />
          <Input label="Primary Category" id="Primary Category" onChange={handleChange} value={data["Primary Category"]} />
          <Input label="Secondary Category" id="Secondary Category" onChange={handleChange} value={data["Secondary Category"]} />
          <Input label="Tertiary Category" id="Tertiary Category" onChange={handleChange} value={data["Tertiary Category"]} />
          <Input label="Material" id="Material" onChange={handleChange} value={data["Material"]} />
          <label for="priceCategories" className={`block text-sm font-medium leading-5 text-gray-400`}>
            Price Categories
          </label>
          <div id="priceCategories" className="w-full text-base font-medium text-white placeholder-gray-500 transition duration-200 hover:border-white focus:border-white focus:outline-none">
            {categories.join(", ")}
          </div>
        </div>
        <Button onClick={handleSubmit}>Submit</Button>
        <div dangerouslySetInnerHTML={{ __html: error }} className="mt-2 text-center text-sm font-medium text-red-500"></div>
      </ModalContainer>
    </div>
  );
};

const mapStateToProps = state => ({
  part: state.userInput.pendingPart,
  priceCategories: state.reference.priceCategories,
});

export default connect(mapStateToProps, { setUserInput, addToPartReference })(PartDetailsModal);
