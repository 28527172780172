import React from "react";

const ModalContainer = ({ children, isVisible, handleClose }) => {
  if (!isVisible) return null;

  return (
    <div class="fixed inset-x-0 bottom-0 z-10 p-10 transition duration-300 sm:inset-0 sm:flex sm:items-center sm:justify-center sm:p-0">
      <div onClick={handleClose} class="fixed inset-0 transition-opacity">
        <div class="absolute inset-0 bg-gray-900 opacity-80"></div>
      </div>
      <div class="transform overflow-y-scroll rounded-lg border-2 border-gray-400 bg-gray-900 py-14 px-14 shadow-xl shadow-gray-800 transition-all sm:w-full sm:max-w-xl">{children}</div>
    </div>
  );
};

export default ModalContainer;
