import { findClosest, roundDownToArrayValue } from "../../../utils/general";
import { getMozaikParam } from "../params";
import { Part } from "./Part";

// @TODO: Confirm ROTrays dont have dividers, felt, or jewelry inserts
export class ROTray extends Part {
  getROTrayElement() {
    return this.PartElement;
  }
  getROTraySides() {
    const ROTraySide = this.getROTrayElement().find("DrawerProdPart[Type=TraySide]");
    const ROTray = this;
    return new ROTraySides(this.PartElement, this.Product, ROTraySide, ROTray);
  }
  getROTrayFrontBack() {
    const ROTrayFront = this.getROTrayElement().find("DrawerProdPart[Type=TrayBack]");
    const ROTray = this;
    return new ROTrayFrontBack(this.PartElement, this.Product, ROTrayFront, ROTray);
  }
  getROTrayBottom() {
    const TrayBottom = this.getROTrayElement().find("DrawerProdPart[Type=TrayBottom]");
    const ROTray = this;
    return new ROTrayBottom(this.PartElement, this.Product, TrayBottom, ROTray);
  }
  getROTraySlides() {
    // @TODO: Confirm this should be Type=TraySide and not Type=TraySide
    const TraySide = this.getROTrayElement().find("DrawerProdPart[Type=TraySide]");
    const ROTray = this;
    return new ROTraySlides(this.PartElement, this.Product, TraySide, ROTray);
  }

  // @TODO: Return error if there is no drawer box style (missing drawer box!)
  getBoxStyle() {
    return this.getPartAttribute("DrawerBoxStyle");
  }
  getStyle() {
    return this.getPartAttribute("DrawerBoxStyle");
  }
  getQuantity() {
    return 1;
  }
  getProductInterior() {
    const SectIdx = Number(this.attr("SectIdx"));
    return this.findProductParts("ProductInterior Section")[SectIdx - 1];
  }
  getMaterial() {
    const productInteriorValue = this.getProductInterior().attr("MatDrawerOR");
    const productValue = this.getProductAttribute("MatDrawerOR");
    const roomValue = this.getRoomSetting("MatDrawerTemplate");
    return (productInteriorValue || productValue || roomValue).replace("0.75", '3/4"').replace("3-4 ", '3/4"').replace("0.625", '5/8"');
  }
}

export class ROTraySides extends ROTray {
  constructor(PartElement, Product, TraySide) {
    super(PartElement, Product);
    this.TraySideElement = TraySide;
  }
  getPrintName() {
    return super.getPrintName("Tray Side");
  }
  getTrayBoxHeight() {
    const boxHeightValues = Object.values(this.getDrawerFaceHeightRef());
    const mozaikHeight = this.TraySideElement.attr("W");
    return findClosest(mozaikHeight, boxHeightValues);
  }
  getWidth() {
    return Math.floor(this.getTrayBoxHeight());
  }
  getLength() {
    return Math.floor(this.TraySideElement.attr("L"));
  }
  getQuantity() {
    return 2;
  }
  getMaterial() {
    const template = super.getMaterial();
    if (template === "Tray Template (Colour)") {
      const material = this.getRoomSetting("MatCabTemplate");
      return material.replace("0.75", '3/4"').replace("3-4 ", '3/4"').replace("0.625", '5/8"').trim();
    }
    return '5/8" White (L191)';
  }
}

export class ROTrayFrontBack extends ROTray {
  constructor(PartElement, Product, TrayFront) {
    super(PartElement, Product);
    this.TrayFrontElement = TrayFront;
  }
  getPrintName() {
    return super.getPrintName("Tray Front / Back");
  }
  getTrayBoxHeight() {
    const boxHeightValues = Object.values(this.getDrawerFaceHeightRef());
    const mozaikHeight = this.TrayFrontElement.attr("W");
    return findClosest(mozaikHeight, boxHeightValues);
  }
  getWidth() {
    return Math.floor(this.getTrayBoxHeight());
  }
  getLength() {
    return Math.floor(this.TrayFrontElement.attr("L"));
  }
  getQuantity() {
    return 2;
  }
  getMaterial() {
    const template = super.getMaterial();
    if (template === "Tray Template (Colour)") {
      const material = this.getRoomSetting("MatCabTemplate");
      return material.replace("0.75", '3/4"').replace("3-4 ", '3/4"').replace("0.625", '5/8"').trim();
    }
    return '5/8" White (L191)';
  }
}

export class ROTrayBottom extends ROTray {
  constructor(PartElement, Product, TrayBottom) {
    super(PartElement, Product);
    this.TrayBottomElement = TrayBottom;
  }
  getPrintName() {
    return super.getPrintName("Tray Bottom");
  }
  getWidth() {
    return Math.floor(this.TrayBottomElement.attr("W"));
  }
  getLength() {
    return Math.floor(this.TrayBottomElement.attr("L"));
  }
  getMaterial() {
    const template = super.getMaterial();
    if (template === "Tray Template (Colour)") {
      const material = this.getRoomSetting("MatCabTemplate");
      return material.replace("0.75", '3/4"').replace("3-4 ", '3/4"').replace("0.625", '5/8"').trim();
    }
    return '5/8" White (L191)';
  }
}

export class ROTraySlides extends ROTray {
  constructor(PartElement, Product, TraySide) {
    super(PartElement, Product);
    this.TraySideElement = TraySide;
  }
  getSlideName() {
    const BBNH = this.getMozaikParam("BBNH");
    const sideL = Math.floor(this.TraySideElement.attr("L"));
    const Z = this.TraySideElement.attr("Z");

    if (Z < BBNH) return this.getSlideType(sideL);
    // if drawer overlaps the baseboard notch, then don't add anything to side length
    else return this.getSlideType(sideL + (0.875).to("mm")); // else add 7/8" to the side length
  }
  getSlideType(sideL) {
    // https://docs.google.com/spreadsheets/d/1Y_z4UBHkGduOzmW_37kIVKnM9Bu1kephiW1f8X8JT6c/edit#gid=104635460
    const slideRef = this.getSlideRef();
    const key = roundDownToArrayValue(sideL, Object.keys(slideRef));
    return slideRef[key];
  }
  getPrintName() {
    return this.getSlideName();
  }
  getStyle() {
    return this.getSlideName();
  }
  getQuantity() {
    // each tray has two slides (or 1 set)
    return 2;
  }
  getLength() {
    const slide = this.getSlideName();
    return slide
      .slice(slide.length - 3)
      .exclude('"')
      .to("mm");
  }
  getWidth() {
    return 0;
  }
  getMaterial() {
    return "";
  }
}

export class ROTraySpacers extends ROTray {
  constructor(PartElement, Product, TraySide) {
    super(PartElement, Product);
    this.TraySideElement = TraySide;
  }
  getSlideName() {
    const BBNH = this.getMozaikParam("BBNH");
    const sideL = Math.floor(this.TraySideElement.attr("L"));
    const Z = this.TraySideElement.attr("Z");

    if (Z < BBNH) return this.getSlideType(sideL);
    // if drawer overlaps the baseboard notch, then don't add anything to side length
    else return this.getSlideType(sideL + (0.875).to("mm")); // else add 7/8" to the side length
  }
  getSlideType(sideL) {
    // https://docs.google.com/spreadsheets/d/1Y_z4UBHkGduOzmW_37kIVKnM9Bu1kephiW1f8X8JT6c/edit#gid=104635460
    const slideRef = this.getSlideRef();
    const key = roundDownToArrayValue(sideL, Object.keys(slideRef));
    return slideRef[key];
  }
  getPrintName() {
    return super.getPrintName("Tray Spacer");
  }
  getStyle() {
    return "";
  }
  getQuantity() {
    // each tray has two slides (or 1 set)
    return 2;
  }
  getLength() {
    const slide = this.getSlideName();
    return slide
      .slice(slide.length - 3)
      .exclude('"')
      .to("mm");
  }
  getWidth() {
    return 1.75;
  }
  getMaterial() {
    const material = this.getRoomSetting("MatCabTemplate").replace("0.75", '3/4"').replace("3-4 ", '3/4"').replace("0.625", '5/8"').trim();
    const isWhite = material.split(" ").at(-1) === "(L191)" || material.split(" ").at(-1)?.toLowerCase() === "white";
    return isWhite ? '1" White' : material;
  }
}
