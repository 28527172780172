import React from "react";

const JobCoverPageRowHeader = () => {
  return (
    <div class="w-[151px] border border-black bg-gray-200 text-xs font-bold" style={{ fontSize: "13px" }}>
      <div class="h-8 border-b border-b-black pl-1">Room Name</div>
      <div class="h-8 pl-1">Material</div>
      <div class="h-8 pl-1">Drw Style</div>
      <div class="h-8 pl-1">Drw Handle/Knob</div>
      <div class="h-8 pl-1">Door Style</div>
      <div class="h-8 pl-1">Door Handle/Knob</div>
      <div class="h-8 pl-1">Moulding</div>
      <div class="h-8 pl-1">Rod Style</div>
      <div class="h-20 pl-1">Accessories</div>
      <div class="border-t border-t-black pl-1">Shelves</div>
      <div class="pl-1">Gables & Backs</div>
      <div class="pl-1">Rods</div>
    </div>
  );
};

export default JobCoverPageRowHeader;
