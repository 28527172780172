import { Drive } from "./google";

export const DriveApp = {
  getFileById: async function (fileId) {
    const file = await Drive.getFileById(fileId);
    return new File(file);
  },
  getFolderById: async function (folderId) {
    const folder = await Drive.getFolder({ id: folderId });
    return new Folder(folder);
  },
};

export class File {
  constructor(file) {
    this.file = file;
  }
  getId() {
    return this.file.id;
  }
  getName() {
    return this.file.name;
  }
  getParents() {
    return this.file.parents;
  }
  getUrl() {
    return this.file.webViewLink;
  }
  getDownloadUrl() {
    return this.file.webContentLink;
  }
  async getContent() {
    const fileId = this.getId();
    const content = await Drive.readFile(fileId);
    return content;
  }
  async getParentFolder() {
    const parentId = this.file.parents[0];
    const folder = await DriveApp.getFolderById(parentId);
    return folder;
  }
  async makeCopy(name, destinationFolder) {
    name = name || this.getName() + " (Copy)";
    const parent = destinationFolder || this.getParents()[0];
    const parentId = typeof parent === "string" ? parent : parent.getId();
    const fileId = this.getId();
    const res = await Drive.copyFile(fileId, name, parentId);
    return await DriveApp.getFileById(res.id);
  }
  async moveTo(destinationFolder) {
    const desinationFolderId = typeof destinationFolder === "string" ? destinationFolder : destinationFolder.getId();
    const fileId = this.getId();
    await Drive.move(fileId, desinationFolderId);
    this.file.parents = [desinationFolderId];
    return this;
  }
  async setName(name) {
    const fileId = this.getId();
    this.file.name = name;
    Drive.update({ fileId: fileId, requestBody: { ...this.file } });
    return this;
  }
  async setTrashed(boolean) {
    const fileId = this.getId();
    if (boolean) Drive.deleteFile(fileId);
    this.file.trashed = boolean;
    // Drive.update({ fileId: fileId, requestBody: { ...this.file } });
    return this;
  }
  async addViewers(emails) {}
  async getViewers() {}
  async removeViewers(emails) {}
  async addEditors(emails) {}
  async getEditors() {}
  async removeEditors(emails) {}
}

export class Folder {
  constructor(folder) {
    this.folder = folder;
  }
  getId() {
    return this.folder.id;
  }
  getName() {
    return this.folder.name;
  }
  getParents() {
    return this.folder.parents;
  }
  getUrl() {
    return this.folder.webViewLink;
  }
  async createFile(filename, data, mimeType) {
    const folderId = this.getId();
    mimeType = mimeType || data.extract(/data:([a-z\/]*);/);
    const file = await Drive.createFile(filename, mimeType, data, folderId);
    console.log(file);
    return await DriveApp.getFileById(file.id);
  }
  async getParentFolder() {
    const parentId = this.folder.parents[0];
    const folder = await DriveApp.getFolderById(parentId);
    return folder;
  }
  async getFiles() {
    const folderId = this.getId();
    const files = await Drive.getFilesByFolder(folderId);
    return files.filter(file => file.mimeType !== "application/vnd.google-apps.folder").map(file => new File(file));
  }
  async getFilesByName(name) {
    const folderId = this.getId();
    const files = await Drive.getFilesByFolder(folderId, name);
    return files.filter(file => file.mimeType !== "application/vnd.google-apps.folder").map(file => new File(file));
  }
  async getFolders() {
    const folderId = this.getId();
    const folders = await Drive.getFolders({ parent: folderId });
    return folders.filter(file => file.mimeType == "application/vnd.google-apps.folder").map(folder => new Folder(folder));
  }
  async getFolder(name, create) {
    const folders = await this.getFoldersByName(name);
    return folders.length ? folders[0] : create ? await this.createFolder(name) : null;
  }
  async getFoldersByName(name) {
    const folderId = this.getId();
    const folders = await Drive.getFolders({ parent: folderId, name: name });
    return folders.map(folder => new Folder(folder));
  }
  async moveTo(destinationFolder) {
    const desinationFolderId = typeof destinationFolder === "string" ? destinationFolder : destinationFolder.getId();
    const folderId = this.getId();
    await Drive.move(folderId, desinationFolderId);
    this.folder.parents = [desinationFolderId];
    return this;
  }
  async createFolder(name) {
    const folderId = this.getId();
    const res = await Drive.createFolder(name, folderId);
    console.log(res);
    const folder = await Drive.getFolder({ id: res.id });
    return new Folder(folder);
  }
  async setName(name) {
    const folderId = this.getId();
    this.folder.name = name;
    Drive.update({ fileId: folderId, requestBody: { ...this.folder } });
    return this;
  }
  async setTrashed(boolean) {
    const folderId = this.getId();
    this.folder.trashed = boolean;
    Drive.update({ fileId: folderId, requestBody: { ...this.folder } });
    return this;
  }
  async addViewers(emails) {}
  async addEditors(emails) {}
  async removeViewers(emails) {}
  async removeEditors(emails) {}
}
