import { SET_RESPONSE_STATE, CLEAR_RESPONSE_STATE, SET_PENDING_PART } from "./types";
import store from "../store";

export const setUserInput = (key, value) => dispatch => {
  dispatch({
    type: SET_RESPONSE_STATE,
    payload: { key, value },
  });
};

export const clearUserInput = key => dispatch => {
  dispatch({
    type: CLEAR_RESPONSE_STATE,
    payload: { key },
  });
};

export const addPartToPendingParts = part => dispatch => {
  dispatch({
    type: SET_PENDING_PART,
    payload: part,
  });
};

export const awaitUserInput = async (key, part) => {
  store.dispatch(addPartToPendingParts(part));
  return new Promise(resolve => {
    const interval = setInterval(() => {
      const value = store.getState().userInput.input[key];
      if (value) {
        store.dispatch(clearUserInput(key));
        clearInterval(interval);
        resolve(value);
      }
    }, 1000);
  });
};
