import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import axios from "../../actions/axios";

import Button from "../../components/Elements/Button";
import ProgressBar from "../../components/Elements/ProgressBar";
import JobSelect from "../../components/Jobs/JobSelect";
import RecordSelect from "../../components/Zoho/RecordSelect";
import RecentlyCreatedCutlists from "./RecentlyCreatedCutlists";

import { SpreadsheetApp } from "../../actions/sheets";
import { DriveApp } from "../../actions/DriveApp";
import { Drive } from "../../actions/google";
import { Zoho } from "../../actions/zoho";
import { setParts } from "../../actions/job";
import { getRoomParts } from "../../actions/mozaik/parts";
import { get2DImages } from "../../actions/dxfs/main";
import { createPotential, createCutlist } from "../../actions/zohoActions";
import { Package } from "../../actions/packages/init";
import CutlistSelect from "../../components/Zoho/CutlistSelect";
import { setAlert } from "../../actions/alert";

const Production = ({ record, setRecord, jobParts, setJobParts, params, rooms, jobFolder, parts, jobData, get2DImages, setAlert, images3D }) => {
  const [cutlist, setCutlist] = useState(null);
  const [progress, setProgress] = useState(null);
  const [dxfs, setDXFs] = useState([]);
  const [pkg, setPkg] = useState(null);

  useEffect(() => {
    if (jobFolder.name) {
      // if (spreadsheet == null) createPriceSheet();
      // else if (spreadsheet.getName() !== "New") {
      //   setSpreadsheet(null); // remove previously loaded spreadsheet
      // }
    }
    if (!jobFolder.name) {
      setRecord(null);
    }

    setJobParts([]);
    setProgress(null);
    setDXFs([]);
  }, [jobFolder]);

  useEffect(() => {
    if (rooms.length && !dxfs.length) get2Ds(rooms);
  }, [rooms]);

  async function get2Ds(rooms) {
    const activeWalls = rooms.map(room => room.activeWalls).flat();
    const files = await get2DImages(jobFolder, activeWalls);
    console.log({ images2D: files });
    setDXFs(files);
  }

  const handleStart = () => {
    setProgress(0); // triggers the "getParts()" function below to run
  };

  useEffect(() => {
    setTimeout(() => {
      if (progress !== null && progress <= rooms.length - 1) getParts();
      else if (progress !== null && progress == rooms.length) createPackage();
      else if (progress == rooms.length + 1) onScriptCompletion();
    }, 100);
  }, [progress]);

  const getParts = async () => {
    if (progress % 1) return;
    const room = rooms[progress];
    const parts = await getRoomParts(room);
    setJobParts([...jobParts, ...parts]);
    setProgress(progress => progress + 1);
  };

  async function handlePrint() {
    const file = pkg.pdf;
    const fileId = file.id;
    const name = jobFolder.name;
    const params = { fileId, name };
    console.log({ params });
    axios.get("/api/gapi/print-cutlist", { params });
    alert("Success! The cutlist has been sent to the printer");
  }

  //TODO - Need to re-generate the PDF and upload to the Zoho Cutlist to reflect manual changes made to the cutlist Google Sheet
  async function handlePDFUpdate() {
    console.log({ pkg });
    // alert("Coming Soon! This feature has not been finished...");
    setAlert("success", "Re-generating cutlist PDF...");
    await pkg.exportPDF();
    const clone = Object.assign(Object.create(Object.getPrototypeOf(pkg)), pkg);
    // we create a clone and set it to state to re-render the component with the updated properties of the pkg
    // must be a clone because calling setPkg without making a clone does not trigger a re-render due to referential equality
    setPkg(clone);
    console.log("re-exported pdf...");
    console.log({ pkg });
    await onScriptCompletion();
    setAlert("success", "Success! The PDF has been updated");
  }

  const createPackage = async () => {
    try {
      console.log("creating package...");
      const pkg = await new Package(jobParts, jobData, rooms).createPackage();

      pkg.addImageData(dxfs);
      await pkg.buildRoomPages(dxfs);
      await Promise.all([pkg.addParts(), pkg.addRoomParams(), pkg.addJobData(), pkg.buildCoverPage(), pkg.buildDrawerPage(), pkg.hideTemplateSheets()]);
      console.log("done building pages...");
      await pkg.exportPDF();
      console.log("done exporting pdf...");
      setPkg(pkg);
      setProgress(rooms.length + 1);
    } catch (err) {
      setAlert("error", err.message, 60000);
    }
  };

  const onScriptCompletion = async () => {
    console.log("Running on script completion actions...");

    const ssId = pkg.getSpreadsheetId();
    const spreadsheetUrl = `https://docs.google.com/spreadsheets/d/${ssId}/edit`;

    // get or create "Cutlists" subfolder in the jobFolder
    const projectFolder = await DriveApp.getFolderById(jobFolder.id);
    const cutlistFolder = await projectFolder.getFolder("Cutlists", true);
    console.log({ projectFolder, cutlistFolder });

    // move spreadsheet to the cutlistFolder
    Drive.move(ssId, cutlistFolder.getId());

    const potentialId = record.module === "Contact" ? await createPotential(record.id) : record.id;

    // create install package
    pkg.exportInstallPackage(potentialId);

    const cutlistId = record.module === "Contact" || cutlist.value === "new_cutlist" ? await createCutlist(jobFolder.name, potentialId) : cutlist.value;
    console.log({ potentialId, cutlistId });

    // upload the link for the spreadsheet to the Zoho cutlist

    Zoho.updateRecord("Cutlists", {
      id: cutlistId,
      Spreadsheet: spreadsheetUrl,
      PDF: `https://drive.google.com/file/d/${pkg.pdf.id}/view`,
      Mozaik_Folder: `https://drive.google.com/drive/u/1/folders/${jobFolder.id}`,
    });

    // create JSON file for the worklist app

    const jsonData = pkg.getJSON(dxfs, images3D);
    const jsonFile = await projectFolder.createFile("JSON.txt", JSON.stringify(jsonData), "text/plain");
    const jsonUrl = "https://drive.google.com/file/d/" + jsonFile.getId();

    console.log({ jsonUrl });

    // update the potential with the JSON file link

    Zoho.updateRecord("Potentials", {
      id: potentialId,
      JSON: jsonUrl,
    });

    // save the metadata to database regarding links between mozaik, crm, google drive, etc.
    const res = await axios.post("/api/cutlist", {
      job_id: jobFolder.id,
      job_name: jobFolder.name,
      cutlist_id: cutlistId,
      potential_id: potentialId,
      pdf_id: pkg.pdf.id,
      spreadsheet_id: ssId,
    });
    console.log({ res });

    console.log("DONE!!!!!! FINISHED ALL PRODUCTION");
  };

  return (
    <div className="grid grid-cols-12">
      <div className="col-span-8 mt-1 rounded-lg border border-gray-700 p-8">
        <div className="mb-6 flex items-center">
          <div className="mr-2 text-xl font-medium text-gray-200">CUTLIST</div>
          <img src="https://img.icons8.com/windows/24/374151/New-production-order.png" />
        </div>
        <JobSelect />
        <RecordSelect record={record} setRecord={setRecord} jobFolder={jobFolder} />
        {record && <CutlistSelect cutlist={cutlist} setCutlist={setCutlist} record={record} />}
        {progress === null && (
          <Button disabled={!record || rooms.length == 0 || params.length == 0} onClick={handleStart}>
            Start
          </Button>
        )}
        {progress !== null && progress <= rooms.length && (
          <div>
            <div className="mt-12 block w-full cursor-pointer items-center justify-center rounded-md bg-cyan-500 px-4 py-4 text-center text-lg leading-6 text-white shadow-sm transition duration-150 ease-in-out hover:bg-cyan-400 focus:outline-none sm:leading-5">
              Running...
            </div>
            <ProgressBar progress={progress} roomLength={rooms.length} />
          </div>
        )}
        {rooms.length > 0 && progress > rooms.length && (
          <div className="grid grid-cols-4 gap-1">
            <a
              className="col-span-1 mt-12 inline-flex w-full cursor-pointer items-center justify-center rounded-md bg-cyan-500 py-4 text-center text-base leading-6 text-white shadow-sm transition duration-150 ease-in-out hover:bg-cyan-400 focus:outline-none sm:leading-5"
              href={`https://drive.google.com/file/d/${pkg.pdf.id}/view`}
              target="_blank"
            >
              View Cutlist PDF
            </a>
            <a
              className="col-span-1 mt-12 inline-flex w-full cursor-pointer items-center justify-center rounded-md bg-cyan-500 py-4 text-center text-base leading-6 text-white shadow-sm transition duration-150 ease-in-out hover:bg-cyan-400 focus:outline-none sm:leading-5"
              href={`https://docs.google.com/spreadsheets/d/${pkg.getSpreadsheetId()}/edit`}
              target="_blank"
            >
              View Cutlist Sheet
            </a>
            <button
              className="col-span-1 mt-12 inline-flex w-full cursor-pointer items-center justify-center rounded-md bg-cyan-500 py-4 text-center text-base leading-6 text-white shadow-sm transition duration-150 ease-in-out hover:bg-cyan-400 focus:outline-none sm:leading-5"
              onClick={handlePrint}
            >
              Print Cutlist
            </button>
            <button
              className="col-span-1 mt-12 inline-flex w-full cursor-pointer items-center justify-center rounded-md bg-cyan-500 py-4 text-center text-base leading-6 text-white shadow-sm transition duration-150 ease-in-out hover:bg-cyan-400 focus:outline-none sm:leading-5"
              onClick={handlePDFUpdate}
            >
              Update PDF
            </button>
          </div>
        )}
      </div>
      <div className="col-span-4 mt-1 ml-2">
        <div className="h-full w-full rounded-lg border border-gray-700 p-8">
          <RecentlyCreatedCutlists record={record} jobFolder={jobFolder} cutlist={cutlist} />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  jobFolder: state.job.job,
  rooms: state.room.rooms,
  images3D: state.room.images,
  params: state.room.params,
  parts: state.job.parts,
  jobData: state.job.jobData,
});

export default connect(mapStateToProps, { setParts, get2DImages, setAlert })(Production);
