import { SET_ROOM, SET_ROOMS, UPDATE_ROOM, RESET_ROOM, RESET_ROOM_STATE, SET_ROOM_IMAGES, DELETE_ROOM, ADD_ROOM, UPDATE_IMAGE, SET_PARAMS } from "../actions/types";

const defaultState = {
  room: {},
  rooms: [],
  images: [],
  params: [],
};

const roomReducer = (state = { ...defaultState }, { type, payload }) => {
  switch (type) {
    case ADD_ROOM:
      return {
        ...state,
        rooms: [...state.rooms, payload],
      };
    case SET_ROOM:
      return {
        ...state,
        room: {
          ...payload,
        },
      };
    case SET_ROOM_IMAGES:
      return {
        ...state,
        images: payload,
      };
    case UPDATE_ROOM:
      return {
        ...state,
        rooms: state.rooms.map(room => (room.id === payload.id ? payload : room)),
        images: state.images.map(image => {
          return image.number === payload.number ? { ...image, visible: payload.active } : image;
        }),
      };
    case UPDATE_IMAGE:
      return {
        ...state,
        images: state.images.map(image => (image.id === payload.id ? payload : image)),
      };
    case DELETE_ROOM:
      return {
        ...state,
        room: { ...defaultState.room },
        rooms: [...state.rooms.filter(room => room._id !== payload.id)],
      };
    case RESET_ROOM:
      return {
        ...state,
        room: { ...defaultState.room },
      };
    case RESET_ROOM_STATE:
      return {
        ...defaultState,
      };
    case SET_ROOMS:
      return {
        ...state,
        rooms: [...payload],
      };
    case SET_PARAMS:
      return {
        ...state,
        params: payload,
      };
    default:
      return state;
  }
};

export default roomReducer;
