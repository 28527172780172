import { SET_PART_REFERENCES, ADD_PART } from "../actions/types";

const defaultState = {
  mozaikPartRef: {},
  partRef: {},
  drawerFaceHeightRef: {},
  drawerFaceWidthRef: {},
  slideRef: {},
  priceCategories: {},
};

const referenceReducer = (state = { ...defaultState }, { type, payload }) => {
  switch (type) {
    case SET_PART_REFERENCES:
      return {
        ...payload,
      };
    case ADD_PART:
      return {
        ...state,
        mozaikPartRef: {
          ...state.mozaikPartRef,
          [payload["Report Name"]]: payload,
          [payload["Print Name"]]: payload,
        },
        partRef: {
          ...state.partRef,
          [payload["Report Name"]]: payload,
          [payload["Print Name"]]: payload,
        },
      };
    default:
      return state;
  }
};

export default referenceReducer;
