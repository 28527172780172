import { SET_JOB, SET_JOB_DATA, SET_JOBS, RESET_JOB, DELETE_JOB, SET_PARTS } from "../actions/types";

const defaultState = {
  job: {},
  jobData: {},
  parts: [],
  jobs: [],
};

const jobReducer = (state = { ...defaultState }, { type, payload }) => {
  switch (type) {
    case SET_JOB:
      return {
        ...state,
        job: { ...payload },
      };
    case SET_PARTS:
      return {
        ...state,
        parts: [...payload],
      };
    case SET_JOB_DATA:
      return {
        ...state,
        jobData: { ...payload },
      };
    case DELETE_JOB:
      return {
        ...state,
        job: {},
        jobs: [...state.jobs.filter(job => job._id !== payload.id)],
      };
    case RESET_JOB:
      return {
        ...state,
        job: {},
        jobData: {},
        parts: [],
      };
    case SET_JOBS:
      return {
        ...state,
        jobs: [...payload],
      };
    default:
      return state;
  }
};

export default jobReducer;
