// Holds all of our constants / variables for the different actions

export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";

export const SET_ALERT = "SET_ALERT";
export const SET_ALERT_GENERATOR = "SET_ALERT_GENERATOR";
export const REMOVE_ALERT = "REMOVE_ALERT";
export const REMOVE_ALL = "REMOVE_ALL";

export const SET_TAG = "SET_TAG";
export const SET_TAGS = "SET_TAGS";
export const RESET_TAG = "RESET_TAG";
export const DELETE_TAG = "DELETE_TAG";

export const SET_BOOKMARK = "SET_BOOKMARK";
export const SET_BOOKMARKS = "SET_BOOKMARKS";
export const RESET_BOOKMARK = "RESET_BOOKMARK";
export const DELETE_BOOKMARK = "DELETE_BOOKMARK";
export const UPDATE_BOOKMARK = "UPDATE_BOOKMARK";
export const CREATE_BOOKMARK = "CREATE_BOOKMARK";

export const SET_USER = "SET_USER";
export const SET_USERS = "SET_USERS";
export const RESET_USER = "RESET_USER";
export const DELETE_USER = "DELETE_USER";
export const UPDATE_USER = "UPDATE_USER";
export const CREATE_USER = "CREATE_USER";
export const ADD_BOOKMARK_TO_USER = "ADD_BOOKMARK_TO_USER";
export const ADD_BOOKMARK_TO_USERS = "ADD_BOOKMARK_TO_USERS";

export const SET_JOB = "SET_JOB";
export const SET_PARTS = "SET_PARTS";
export const SET_JOB_DATA = "SET_JOB_DATA";
export const SET_JOBS = "SET_JOBS";
export const RESET_JOB = "RESET_JOB";
export const DELETE_JOB = "DELETE_JOB";
export const UPDATE_JOB = "UPDATE_JOB";
export const CREATE_JOB = "CREATE_JOB";

export const ADD_ROOM = "ADD_ROOM";
export const SET_ROOM = "SET_ROOM";
export const SET_ROOM_IMAGES = "SET_ROOM_IMAGES";
export const SET_ROOMS = "SET_ROOMS";
export const RESET_ROOM = "RESET_ROOM";
export const RESET_ROOM_STATE = "RESET_ROOM_STATE";
export const DELETE_ROOM = "DELETE_ROOM";
export const UPDATE_ROOM = "UPDATE_ROOM";
export const CREATE_ROOM = "CREATE_ROOM";

export const UPDATE_IMAGE = "UPDATE_IMAGE";
export const SET_PARAMS = "SET_PARAMS";
export const SET_PART_REFERENCES = "SET_PART_REFERENCES";
export const ADD_PART = "ADD_PART";

export const SET_RESPONSE_STATE = "SET_RESPONSE_STATE";
export const CLEAR_RESPONSE_STATE = "CLEAR_RESPONSE_STATE";
export const SET_PENDING_PART = "SET_PENDING_PART";

export const SET_PROGRESS = "SET_PROGRESS";
