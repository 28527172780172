import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";

import QuoteSelect from "../../components/Zoho/QuoteSelect";
import Button from "../../components/Elements/Button";
import ProgressBar from "../../components/Elements/ProgressBar";

import { SpreadsheetApp } from "../../actions/sheets";
import { setParts } from "../../actions/job";
import { getRoomParts, getSheetValues } from "../../actions/mozaik/parts";
import { Zoho } from "../../actions/zoho";
import { createPotential, createQuote } from "../../actions/zohoActions";
import { Drive } from "../../actions/google";
import { DriveApp } from "../../actions/DriveApp";
import axios from "../../actions/axios";
import Drawings from "../Home/Drawings";
import JobSelect from "../../components/Jobs/JobSelect";
import RecordSelect from "../../components/Zoho/RecordSelect";
import RecentlyCreatedQuotes from "../Home/RecentlyCreatedQuotes";
import { createNewPriceSheet } from "./quoteUtils";

const Quote = ({ record, setRecord, jobParts, setJobParts, params, rooms, jobFolder, images, clientName }) => {
  const [quote, setQuote] = useState(null);
  const [spreadsheet, setSpreadsheet] = useState(null);
  const [progress, setProgress] = useState(null);
  const [downloadPDF, setDownloadPDF] = useState(null);
  const [drawingsReady, setDrawingsReady] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const drawingsBtnRef = useRef(null);

  console.warn({
    record,
    quote,
    spreadsheet,
  });

  async function onJobFolderChange() {
    if (jobFolder.name) {
      if (spreadsheet == null || spreadsheet.getName() !== "New") {
        const ss = await createNewPriceSheet();
        setSpreadsheet(ss);
      }
    }
    setJobParts([]);
    setQuote(null);
    setProgress(null);
    setRecord(null);
  }

  useEffect(() => {
    onJobFolderChange();
  }, [jobFolder]);

  const getParts = async () => {
    if (progress % 1) return;
    const room = rooms[progress];
    const parts = await getRoomParts(room);
    setJobParts([...jobParts, ...parts]);
    setProgress(progress => progress + 1);
  };

  const addToSheet = async () => {
    const sheetValues = getSheetValues(jobParts);

    const sheet = await spreadsheet.getSheetByName("Components");
    setProgress(progress => progress + 0.5);
    sheet.getRange(2, 1, sheetValues.length, sheetValues[0].length).setValues(sheetValues);

    const imagesSheet = await spreadsheet.getSheetByName("Images");
    const imagesSheetValues = images.map(image => [image.name, image.label, image.number, `https://drive.google.com/thumbnail?id=${image.id}`, image.id]);
    imagesSheet.getRange(2, 1, imagesSheetValues.length, imagesSheetValues[0].length).setValues(imagesSheetValues);

    const quoteSheet = await spreadsheet.getSheetByName("Quote");
    const [firstName, lastName] = (clientName || "").split(" ");
    const quoteSheetValues = [
      [
        true,
        clientName,
        firstName,
        lastName,
        null,
        null,
        "https://quote.markaeclosets.com?id=" + spreadsheet.getId(),
        "Promotion",
        new Date(),
        `https://crm.zoho.com/crm/org51589495/tab/Quotes/${quote.value}`,
      ],
    ];
    quoteSheet.getRange(2, 1, quoteSheetValues.length, quoteSheetValues[0].length).setValues(quoteSheetValues);

    setParts(jobParts);
    setProgress(rooms.length + 1);
  };

  async function refreshDrawings() {
    setIsLoading(true);
    const pdf = await downloadPDF();
    const filename = `${jobFolder.name} Quote 3D Drawings`;
    const projectFolder = await DriveApp.getFolderById(jobFolder.id);
    const quoteFolder = await projectFolder.getFolder("Quotes", true);

    const drawings = await quoteFolder.createFile(filename, pdf, "application/pdf");
    console.log({ drawings });

    const drawingsId = drawings.getId();
    const drawingsUrl = drawings.getUrl();

    console.log({ drawingsUrl });

    console.log(drawingsBtnRef);

    drawingsBtnRef.current.href = drawingsUrl;

    const quoteId = quote.value;
    console.log({ quoteId });

    // upload the link for the spreadsheet to the Zoho quote
    Zoho.updateRecord("Quotes", {
      id: quoteId,
      Quote_Drawings: drawings.getUrl(),
    });

    setDrawingsReady(true);
    setIsLoading(false);
  }

  const onScriptCompletion = async () => {
    console.log("Running on script completion actions...");

    const ssId = spreadsheet.getId();
    const spreadsheetUrl = `https://docs.google.com/spreadsheets/d/${ssId}/edit`;

    // get or create "Quotes" subfolder in the jobFolder
    const projectFolder = await DriveApp.getFolderById(jobFolder.id);
    const quoteFolder = await projectFolder.getFolder("Quotes", true);

    // move spreadsheet to the quoteFolder
    Drive.move(ssId, quoteFolder.getId());

    // generate the drawings PDF and upload to quote
    const pdf = await downloadPDF();
    console.log(pdf);
    const filename = `${jobFolder.name} Quote 3D Drawings`;
    console.log({ filename });

    const drawings = await quoteFolder.createFile(filename, pdf, "application/pdf");
    console.log({ drawings });
    const drawingsId = drawings.getId();

    const drawingsUrl = drawings.getUrl();
    console.log({ drawingsUrl });

    console.log(drawingsBtnRef);

    drawingsBtnRef.current.href = drawingsUrl;

    const potentialId = record.module === "Contact" ? await createPotential(record.id) : record.id;
    const quoteId = record.module === "Contact" || quote.value === "new_quote" ? await createQuote(potentialId) : quote.value;
    console.log({ potentialId, quoteId });

    // upload the link for the spreadsheet to the Zoho quote
    Zoho.updateRecord("Quotes", {
      id: quoteId,
      Quote_Spreadsheet: spreadsheetUrl,
      Quote_Drawings: drawings.getUrl(),
    });

    // save the metadata to database regarding links between mozaik, crm, google drive, etc.
    const res = await axios.post("/api/quote", {
      job_id: jobFolder.id,
      job_name: jobFolder.name,
      quote_id: quoteId,
      potential_id: potentialId,
      drawings_id: drawingsId,
      spreadsheet_id: ssId,
    });
    console.log({ res });
    setDrawingsReady(true);
  };

  const handleStart = () => {
    spreadsheet.setName(jobFolder.name);
    setProgress(0); // triggers the "getParts()" function below to run
  };

  useEffect(() => {
    setTimeout(() => {
      if (progress !== null && progress <= rooms.length - 1) getParts();
      else if (progress !== null && progress == rooms.length) addToSheet();
      else if (progress == rooms.length + 1) onScriptCompletion();
    }, 100);
  }, [progress]);

  return (
    <div className="grid grid-cols-12">
      <div className="col-span-8 mt-1 rounded-lg border border-gray-700 p-8">
        <div className="mb-6 flex items-center">
          <div className="mr-2 text-xl font-medium text-gray-200">QUOTE</div>
          <img src="https://img.icons8.com/windows/28/374151/receipt-dollar.png" />
        </div>

        <JobSelect />
        <RecordSelect record={record} setRecord={setRecord} jobFolder={jobFolder} />
        {record && <QuoteSelect quote={quote} setQuote={setQuote} record={record} />}
        {progress === null && progress !== 9999 && !isLoading && (
          <Button disabled={!record || rooms.length == 0 || !spreadsheet || params.length == 0} onClick={handleStart}>
            Start
          </Button>
        )}
        {progress !== null && progress <= rooms.length && (
          <div>
            <div className="mt-12 block w-full cursor-pointer items-center justify-center rounded-md bg-cyan-500 px-4 py-4 text-center text-lg leading-6 text-white shadow-sm transition duration-150 ease-in-out hover:bg-cyan-400 focus:outline-none sm:leading-5">
              Running...
            </div>
            <ProgressBar progress={progress} roomLength={rooms.length} />
          </div>
        )}

        {isLoading && <div className="py-3 px-4 animate-pulse bg-cyan-300 my-8 font-semibold text-cyan-900 text-center">Running. Please Wait...</div>}
        {rooms.length > 0 && progress > rooms.length && (
          <div className="grid grid-cols-2 space-x-1">
            {spreadsheet !== null && (
              <a
                style={!isLoading ? { display: "block" } : { display: "none" }}
                className="grid-span-1 mt-12 inline-flex w-full cursor-pointer items-center justify-center rounded-md bg-cyan-500 px-4 py-4 text-center text-lg leading-6 text-white shadow-sm transition duration-150 ease-in-out hover:bg-cyan-400 focus:outline-none sm:leading-5"
                href={`https://docs.google.com/spreadsheets/d/${spreadsheet.getId()}/edit`}
                target="_blank"
                rel="noreferrer"
              >
                View Spreadsheet
              </a>
            )}
            <a
              ref={drawingsBtnRef}
              rel="noreferrer"
              style={drawingsReady && !isLoading ? { display: "block" } : { display: "none" }}
              className="grid-span-1 mt-12 inline-flex w-full cursor-pointer items-center justify-center rounded-md bg-cyan-500 px-4 py-4 text-center text-lg leading-6 text-white shadow-sm transition duration-150 ease-in-out hover:bg-cyan-400 focus:outline-none sm:leading-5"
              href="https://markaeclosets.com"
              target="_blank"
            >
              View Drawings
            </a>
          </div>
        )}
        {!isLoading && progress === 9999 && (
          <div className="grid grid-cols-2 space-x-1">
            {!drawingsReady && (
              <div
                onClick={refreshDrawings}
                className="grid-span-1 mt-12 inline-flex w-full cursor-pointer items-center justify-center rounded-md bg-cyan-500 px-4 py-4 text-center text-lg leading-6 text-white shadow-sm transition duration-150 ease-in-out hover:bg-cyan-400 focus:outline-none sm:leading-5"
              >
                Refresh Drawings
              </div>
            )}
            <a
              target="_blank"
              rel="noreferrer"
              href={`https://script.google.com/a/macros/markaeclosets.com/s/AKfycbw7YGknx8SZ21_b6ptHp1uKc_-LXzqhjs5vq88Lm38lIIPorG4/exec?quoteId=${quote?.value}&potentialId=${record?.id}&action=Edit_Quote`}
              className="grid-span-1 mt-12 inline-flex w-full cursor-pointer items-center justify-center rounded-md bg-cyan-500 px-4 py-4 text-center text-lg leading-6 text-white shadow-sm transition duration-150 ease-in-out hover:bg-cyan-400 focus:outline-none sm:leading-5"
            >
              Send Quote
            </a>
          </div>
        )}
        <a
          className="mt-4 block w-full text-right text-sm text-gray-400 hover:underline"
          href="https://docs.google.com/spreadsheets/d/1Y_z4UBHkGduOzmW_37kIVKnM9Bu1kephiW1f8X8JT6c/edit#gid=937292652"
          target="_blank"
          tabIndex={-1}
        >
          Mozaik Reference Sheet
        </a>
      </div>
      <div className="col-span-4 mt-1 ml-2">
        <div className="h-full w-full rounded-lg border border-gray-700 p-8">
          <RecentlyCreatedQuotes
            record={record}
            jobFolder={jobFolder}
            quote={quote}
            setQuote={setQuote}
            setRecord={setRecord}
            setSpreadsheet={setSpreadsheet}
            setProgress={setProgress}
            refreshDrawings={refreshDrawings}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
          />
        </div>
      </div>
      {rooms.length && record && (
        <div className="col-span-12 mt-2 mb-6 rounded-lg border border-gray-700 p-8">
          <Drawings setDownloadPDF={setDownloadPDF} includeDownloadBtn={false} />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  jobFolder: state.job.job,
  clientName: state.job?.jobData?.clientName,
  rooms: state.room.rooms,
  params: state.room.params,
  images: state.room.images,
});

export default connect(mapStateToProps, { setParts })(Quote);
