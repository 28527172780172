import { SET_PROGRESS } from "../actions/types";

const defaultState = { progress: 0 };

const progressReducer = (state = { ...defaultState }, { type, payload }) => {
  switch (type) {
    case SET_PROGRESS:
      return {
        progress: payload,
      };
    default:
      return state;
  }
};

export default progressReducer;
