import axios from "./axios";
import { SET_JOB, SET_JOBS, RESET_JOB, DELETE_JOB, UPDATE_JOB, CREATE_JOB } from "./types";
import { setAlert } from "./alert";

export const Trimble = {
  getFolders: async function (parentId = "ZATmBTmgQNM") {
    const path = `/folders/${parentId}/items`;
    const res = await axios.post("/api/trimble/get", { path });
    return res.data;
  },
  getFolder: async function (name, parentId = "ZATmBTmgQNM") {
    const path = `/folders/${parentId}/item?name=${encodeURIComponent(name)}`;
    const res = await axios.post("/api/trimble/get", { path });
    return res.data;
  },
  createFolder: async function (name, parentId = "ZATmBTmgQNM") {
    const path = `/folders`;
    const payload = { name, parentId };
    const res = await axios.post("/api/trimble/post", { path, payload });
    return res.data;
  },
  getUploadUrl: async function (name, parentId = "ZATmBTmgQNM") {
    const path = `/files/fs/initiate`;
    const payload = {
      parentId: parentId,
      parentType: "FOLDER",
      name: name,
    };
    const res = await axios.post("/api/trimble/post", { path, payload });
    return res.data;
  },
  uploadFile: async function (url, base64) {
    const res = await axios.post("/api/trimble/upload", { url, base64 });
    return res.data;
  },
  commitFileUpload: async function (uploadId) {
    const path = `/files/fs/commit`;
    const payload = { uploadId };
    const res = await axios.post("/api/trimble/post", { path, payload });
    return res.data;
  },
  // getRecord: async function (module, recordId) {
  //   const path = "/" + module.toLowerCase() + "/" + recordId;
  //   const res = await axios.post("/api/zoho/get", { path });
  //   return res.data.length ? res.data[0] : null;
  // },
  // /**
  //  * Get the records for a particular module
  //  * @param {String} module
  //  * @param {Object} options { start, filter, maxResults, orderBy }
  //  */
  // getRecords: async function (module, options = {}) {
  //   let query = "Select * from " + module;
  //   if (options.filter) query += " WHERE " + options.filter;
  //   if (options.orderBy) query += " ORDERBY " + options.orderBy;
  //   if (options.start) query += " STARTPOSITION " + options.start;
  //   query += " MAXRESULTS " + (options.maxResults || "1000");
  //   const path = "/query?query=" + encodeURIComponent(query);
  //   const res = await axios.post("/api/zoho/get", { path });
  //   return res.data.length ? res.data : [];
  // },
  // getAllRecords: async function (module, options = {}) {
  //   let allRecords = [];
  //   options.start = 1;
  //   while (true) {
  //     const res = this.getRecords(module, options);
  //     const records = res.QueryResponse[module];
  //     if (!records) break;
  //     allRecords = [...allRecords, ...records];
  //     const count = records.length;
  //     if (count !== 1000) break;
  //     options.start = options.start + count;
  //   }
  //   return allRecords;
  // },
  // updateRecord: async function (module, data, useTriggers = true) {
  //   const path = "/" + module.toLowerCase();
  //   const payload = { data: [data] };
  //   if (!useTriggers) payload.trigger = [];
  //   const res = await axios.post("/api/zoho/put", { path, payload });
  //   return res.data.length ? res.data[0] : null;
  // },
  // getRelatedListsForRecord: async function (module, recordId, relatedList) {
  //   const path = "/" + module.toLowerCase() + "/" + recordId + "/" + relatedList;
  //   const res = await axios.post("/api/zoho/get", { path });
  //   return res.data.length ? res.data : [];
  // },
  // getRelatedLists: async function (module) {
  //   const path = "/settings/related_lists?module=" + module;
  //   const res = await axios.post("/api/zoho/get", { path });
  //   return res.data.length ? res.data : [];
  // },
  // queryRecords: async function (query) {
  //   const payload = { select_query: query.trim() };
  //   const path = "/coql";
  //   const res = await axios.post("/api/zoho/post", { path, payload });
  //   return res.data.length ? res.data : [];
  // },
  // searchContacts: async function (term) {
  //   const res = await this.queryRecords(`
  //     select Full_Name, Email, Modified_Time
  //     from Contacts
  //     where Full_Name like '%${term}%'
  //     order by Modified_Time DESC
  //     limit 30
  //   `);
  //   return res;
  // },
  // searchPotentials: async function (term) {
  //   const res = await this.queryRecords(`
  //     select Deal_Name, Contact_Name, Modified_Time
  //     from Deals
  //     where Deal_Name like '%${term}%'
  //     order by Modified_Time DESC
  //     limit 30
  //   `);
  //   return res;
  // },
  // searchContactsAndPotentials: async function (term) {
  //   const contacts = this.searchContacts(term);
  //   const potentials = this.searchPotentials(term);
  //   const res = await Promise.all([contacts, potentials]);
  //   const data = res
  //     .flat()
  //     .map(record => {
  //       const { Full_Name, Deal_Name } = record;
  //       return {
  //         ...record,
  //         label: Deal_Name || Full_Name,
  //         module: Deal_Name ? "Potential" : "Contact",
  //       };
  //     })
  //     .sort((a, b) => a.label.trim().toLowerCase().localeCompare(b.label.trim().toLowerCase()));
  //   return data;
  // },
};
