import React from "react";

const Button = ({ onClick, children, disabled, ref }) => {
  return (
    <button
      type="button"
      ref={ref}
      disabled={disabled}
      onClick={onClick}
      class={`mt-12 block w-full cursor-pointer items-center justify-center rounded-md bg-cyan-500 px-4 py-4 text-lg leading-6 text-white shadow-sm outline-gray-200 transition duration-150 ease-in-out hover:bg-cyan-400 focus:outline-offset-1 sm:leading-5 ${
        disabled ? "cursor-not-allowed opacity-50 hover:bg-cyan-500" : "opacity-100"
      }`}
    >
      {children}
    </button>
  );
};

export default Button;
