import { create } from "zustand";
import axios from "../actions/axios";

export const useCutlists = create(set => ({
  cutlists: [],
  latestCutlist: null,
  setCutlists: cutlists => set({ cutlists }),
  addCutlist: cutlist => set(state => ({ cutlists: [...state.cutlists, cutlist] })),
  updateCutlist: cutlist => {
    set(state => ({ cutlists: state.cutlists.map(c => (c._id === cutlist._id ? cutlist : c)) }));
  },
  fetchCutlists: async params => {
    console.log({ params });
    if (params && !Object.values(params).some(Boolean)) return;
    const res = await axios.get("/api/cutlist", { params });
    set({ cutlists: res.data, latestCutlist: res.data[0] });
    return res.data;
  },
}));
