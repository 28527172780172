import React from "react";

const JobCoverPageHeader = ({ jobData }) => {
  const { clientName, address, phone, email, jobNotes } = jobData;
  const subHeading = [address, phone].filter(Boolean).join(" | ");
  return (
    <>
      <div class="border border-black bg-gray-200 text-center text-xl font-bold leading-normal">{clientName}</div>
      <div class="text-md border border-t-0 border-b-0 border-black p-1 text-center font-semibold leading-normal">{subHeading}</div>
      <div id="notes" class="flex border border-black">
        <div class="h-16 w-[151px] border border-r-black bg-gray-200 pl-1 font-bold" style={{ fontSize: "13px" }}>
          Job Notes
        </div>
        <div class="w-[1065px] bg-white pl-1">{jobNotes || ""}</div>
      </div>
    </>
  );
};

export default JobCoverPageHeader;
