import React, { useState, useMemo, useEffect } from "react";
import AsyncSelect from "react-select/async";
import { components } from "react-select";
import { Zoho } from "../../actions/zoho";
import { debounce } from "../../utils/general";
import moment from "moment";

const RecordSelect = ({ record, setRecord, jobFolder = {} }) => {
  const [inputValue, setInputValue] = useState("");

  // useEffect(() => {
  //   if (jobFolder.name) setInputValue(jobFolder.name.exclude(/([0-9]+)/).trim() + " ");
  // }, [jobFolder.name]);

  const styles = {
    control: (styles, { isFocused }) => ({
      ...styles,
      outline: "none",
      boxShadow: "none",
      borderColor: isFocused ? "#fff !important" : "transparent",
      ":hover": { borderColor: "#4b5563" },
      borderRadius: "0.5rem",
      borderWidth: "2px",
      backgroundColor: "rgb(31 41 55)",
      fontSize: "1rem",
      color: "white",
    }),
    multiValueRemove: styles => ({
      ...styles,
      ":hover": {
        backgroundColor: "lightgray",
        color: "black",
      },
    }),
    indicatorSeparator: styles => ({ display: "none" }),
    input: styles => ({ ...styles, color: "white", fontSize: "1rem", padding: "0.5rem", paddingLeft: "0", margin: "0" }),
    valueContainer: styles => ({ ...styles, margin: "0", paddingLeft: "1rem" }),
    singleValue: styles => ({ ...styles, color: "white", fontSize: "1rem" }),
    multiValue: styles => ({ ...styles, backgroundColor: "rgb(107 114 128)", marginLeft: "0", marginRight: "0.5rem", borderRadius: "0.5rem", color: "white", fontSize: "1rem" }),
    multiValueLabel: styles => ({ ...styles, color: "white" }),
    placeholder: styles => ({ ...styles, fontSize: "1rem", color: "rgb(107 114 128)", paddingLeft: "0" }),
    option: (styles, { isFocused }) => ({
      ...styles,
      fontSize: "0.75rem",
      cursor: "pointer",
      borderRadius: "0.25rem",
      backgroundColor: isFocused ? "rgb(107 114 128)" : "transparent",
      ":hover": {
        backgroundColor: "rgb(107 114 128)",
      },
    }),
    menu: styles => ({ ...styles, zIndex: "50", backgroundColor: "rgb(31 41 55)", color: "white", borderRadius: "0.5rem" }),
  };

  const handleChange = (e = {}) => setRecord(e);

  const handleInputChange = e => setInputValue(e);

  let loadOptions = async (term, callback) => {
    if (term) {
      const records = await Zoho.searchContactsAndPotentials(term);
      callback(records);
    }
  };

  loadOptions = useMemo(() => debounce(loadOptions, 300), []);

  const Option = props => {
    const { label, module, Email, Modified_Time } = props.data;
    return (
      <components.Option {...props}>
        <div class="flex cursor-pointer items-center">
          <div class="text-sm font-medium">{label}</div>
          <div class={`ml-4 rounded-full ${module === "Contact" ? "bg-teal-100 text-teal-900" : "bg-indigo-100 text-indigo-900"} px-2 text-xs text-gray-300`}>{module}</div>
        </div>
        {Email && <div class="text-xs text-gray-400">{Email}</div>}
        {module === "Potential" && Modified_Time && <div class="text-xs text-gray-400">{moment(Modified_Time).format("MMMM Do YYYY, h:mm a")}</div>}
      </components.Option>
    );
  };

  return (
    <div>
      <div className="my-3 mt-10 flex items-center space-x-2">
        <div className="text-sm font-medium text-gray-400">CRM Record</div>
        {record && (
          <a className="opacity-75 hover:opacity-100" target="_blank" href={`https://crm.zoho.com/crm/org51589495/tab/${record.module}s/${record.id}`}>
            <img src="https://img.icons8.com/material-outlined/20/9ca3af/external-link.png" />
          </a>
        )}
      </div>
      <AsyncSelect
        cacheOptions={true}
        value={record}
        isClearable
        inputValue={inputValue}
        components={{ Option }}
        placeholder="Search for the corresponding record..."
        onChange={handleChange}
        onInputChange={handleInputChange}
        loadOptions={loadOptions}
        styles={styles}
      />
      {record && record.module === "Contact" && <p class="mt-2 ml-2 text-sm italic text-gray-400">* A new potential will be created for this contact</p>}
    </div>
  );
};

export default RecordSelect;
