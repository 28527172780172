import colors from "./colors";

export const chunk = (arr, size) => Array.from({ length: Math.ceil(arr.length / size) }, (v, i) => arr.slice(i * size, i * size + size));

export const debounce = (fn, delay) => {
  let timer;
  return function (...args) {
    clearTimeout(timer);
    timer = setTimeout(() => {
      fn(...args);
    }, delay);
  };
};

// rounds the value to the closest value in a list of options. may round up or down.
export const findClosest = (value, options) => {
  return options.reduce((prev, curr) => (Math.abs(curr - value) < Math.abs(prev - value) ? curr : prev));
};

// rounds down the value provided to meet the closest option in the list without exceeding it
export const roundDownToArrayValue = (value, options) => {
  return options.reduce((prev, curr) => (Math.abs(curr - value) < Math.abs(prev - value) && curr <= value ? curr : prev));
};

// rounds to the nearest number, rounded up or down
export const roundNearest = (num, divisor) => {
  return Math.round(num * (1 / divisor)) / (1 / divisor);
};

export const roundDownNearest = (num, divisor) => {
  return Math.floor(num * (1 / divisor)) / (1 / divisor);
};

export const roundDownTo16th = (num, unit = "mm") => {
  if (unit == "mm") return roundDownNearest(num.to("inch"), 0.0625).to("mm");
  else return roundDownNearest(num, 0.0625);
};

export const roundNearest16th = (num, unit = "mm") => {
  if (unit == "mm") return roundNearest(num.to("inch"), 0.0625).to("mm");
  else return roundNearest(num, 0.0625);
};

export const round = (num, digits = 0) => Math.round(num * 10 ** digits) / 10 ** digits;
export const roundDown = (num, digits = 0) => Math.floor(num * 10 ** digits) / 10 ** digits;
export const roundUp = (num, digits = 0) => Math.ceil(num * 10 ** digits) / 10 ** digits;

String.prototype.exclude = function (str) {
  return this.replace(str, "");
};
String.prototype.excludeAll = function (str) {
  return this.replaceAll(str, "");
};
Number.prototype.to = function (unit) {
  if (unit === "inch" || unit === "inches" || unit === "in") return this / 25.4;
  else if (unit === "mm") return this * 25.4;
  // assumes number is in inches
  else if (unit === "ft") return this / 304.8; // assumes number is in mm
};
String.prototype.to = function (unit) {
  return Number(this).to(unit);
};
String.prototype.extract = function (regexp) {
  const match = this.match(regexp);
  return match && match.length ? match[1] : null;
};

export const formattedCurrencyToNumber = str => {
  if (typeof str !== "string") return str;
  return Number(str.exclude("$").exclude(","));
};

export const formattedPercentToNumber = str => {
  if (typeof str !== "string") return str;
  return Number(str.exclude("%")) / 100;
};

export const sum = array => array.reduce((a, b) => a + b);
export const removeDuplicates = arr => [...new Set(arr)];

export const transpose = array => array[0].map((_, colIndex) => array.map(row => row[colIndex]));

export function colorToRGB(color) {
  console.log(color);
  if (!color) return null;
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color);
  return result
    ? {
        red: parseInt(result[1], 16) / 255,
        green: parseInt(result[2], 16) / 255,
        blue: parseInt(result[3], 16) / 255,
      }
    : colorToRGB(colors[color]);
}

export const waitForCondition = (fn, args = [], timeout = 30000, interval = 100) =>
  new Promise(resolve => {
    let time = 0;
    const poller = setInterval(async () => {
      if (time >= timeout) resolve(clearInterval(poller));
      const res = await fn(...args);
      if (!res) return (time += 100);
      else resolve(clearInterval(poller) || res);
    }, interval);
  });
