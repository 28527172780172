import React, { useMemo } from "react";
import { connect } from "react-redux";
import GenericSelect from "../Layout/Select";

const PartDetailsModalSelect = ({ onChange, value, partRef }) => {
  console.log("PartDetailsModalSelect: ", value);

  const options = useMemo(
    () =>
      Object.values(partRef).map(part => ({
        label: part["Print Name"],
        value: part["Print Name"],
        ...part,
      })),
    [partRef]
  );

  console.log({ options });

  return (
    <div>
      <GenericSelect onChange={onChange} options={options} value={value} isClearable={true} isCreatable={true} placeholder="Copy From Existing Part..." />
    </div>
  );
};

const mapStateToProps = state => ({
  partRef: state.reference.partRef,
});

export default connect(mapStateToProps, {})(PartDetailsModalSelect);
