import React, { useContext, useMemo } from "react";
import { roundUp } from "../../utils/general";
import { DocumentContext } from "./DocumentContext";
import { getPageDimensions } from "./utils";

const Page = ({ children, style }) => {
  const { width, settings } = useContext(DocumentContext);

  console.log({ width, settings });

  const [pageWidth, pageHeight] = useMemo(() => {
    return getPageDimensions(settings.pageSize, settings.orientation, settings.unit);
  }, [settings.pageSize, settings.orientation, settings.unit]);

  console.log({ pageWidth, pageHeight });

  const styles = {
    ...style,
    width: width || "unset",
    height: width ? width / (pageWidth / pageHeight) : "unset",
    aspectRatio: String(`${pageWidth}/${pageHeight}`),
    borderBottom: settings.preview ? settings.previewBorder : "",
  };

  const classes = settings.centered ? "absolute top-1/2 left-1/2 w-full -translate-x-1/2 -translate-y-1/2 overflow-hidden" : "w-full overflow-hidden";

  return (
    <div className="page relative" style={styles}>
      <div className={classes}>{children}</div>
    </div>
  );
};

export default Page;
