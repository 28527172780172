import { roundDownTo16th, roundNearest16th } from "../../../utils/general";
import { Part } from "./Part";

export class Top extends Part {
  constructor(TopElement, Room) {
    super(TopElement, Room);
    this.$ = Room.$;
    this.TopElement = Room.$(TopElement);
    this.Room = Room;
  }
  attr(key) {
    return this.TopElement.attr(key);
  }
  getTopsTexture() {
    return this.Room.getRoomSetting("TopsTexture");
  }
  getMatCabTemplate() {
    return this.Room.getRoomSetting("MatCabTemplate");
  }
  getPrintName() {
    return "Countertop";
  }
  getMaterial() {
    const texture = this.getTopsTexture();
    const material = texture === "MATCHING" ? this.getMatCabTemplate() : texture;
    return material.replace("0.75", '3/4"').replace("3-4 ", '3/4"').replace("0.625", '5/8"');
  }
  getWidth() {
    return roundNearest16th(this.TopElement.attr("Length"));
  }
  getLength() {
    return roundNearest16th(this.TopElement.attr("D"));
  }
  getStyle() {
    return this.getPrintName();
  }
  getQuantity() {
    return 1;
  }
  getWallNumber() {
    // 2_1 => 2
    return this.TopElement.attr("Wall").split("_")[0];
  }
  getCabNumber() {
    return `R${this.Room.getRoomNumber()}C0`;
  }
}
