import React, { useMemo } from "react";
import Page from "../../components/PDF/Page";
import { chunk } from "../../utils/general";
import JobCoverPageHeader from "./JobCoverPageHeader";
import JobCoverPageRow from "./JobCoverPageRow";

const JobCoverPage = ({ jobData, rooms }) => {
  const pages = chunk(rooms, 10);

  return (
    <div className="p-2">
      {pages.map(rooms => {
        const [firstRow, secondRow] = chunk(rooms, 5);
        return (
          <>
            <JobCoverPageHeader jobData={jobData} />
            <JobCoverPageRow rooms={firstRow} />
            {secondRow && <JobCoverPageRow rooms={secondRow} />}
          </>
        );
      })}
    </div>
  );
};

export default JobCoverPage;
