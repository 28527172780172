import React, { useEffect, useState } from "react";
import Input from "../../components/Elements/Input";
import Select from "../../components/Layout/Select";
import Button from "../../components/Elements/Button";
import { Zoho } from "../../actions/zoho";
import axios from "../../actions/axios";
import { formattedCurrencyToNumber, formattedPercentToNumber } from "../../utils/general";

const installers = ["Kwaku", "Kelson", "Lee", "Marty", "Rodney", "Elliot"];

export default function InstallerAgreementForm({ comp, record, numberOfInstallDays, drawings, ready, setReady, updateDrawings, setAlert }) {
  const [done, setDone] = useState(false);

  const [error, setError] = useState("");
  const [link, setLink] = useState("");

  const [data, setData] = useState({
    Installer: "Kwaku",
    Installer_Compensation: "0",
    Delivery: "0",
    Helper: "0",
    LD_Travel: "0",
    Tear_Out: "0",
    Include_Tolls: "No",
    Service: "0",
    Notes_For_Installer: "",
  });

  useEffect(() => {
    if (!comp.total) return;
    if (!numberOfInstallDays || numberOfInstallDays === 0) {
      setAlert("error", "There are no install dates confirmed!");
      return;
    }
    if (data.Installer === "Kwaku") {
      const total = formattedCurrencyToNumber(comp.total);
      console.log({ total });
      const helper = 200 * numberOfInstallDays;
      const base = total - helper;
      setData({
        ...data,
        Installer_Compensation: base,
        Helper: helper,
        Delivery: "0",
      });
    } else if (data.Installer === "Kelson") {
      const total = formattedCurrencyToNumber(comp.total);
      const helper = 150 * numberOfInstallDays;
      const delivery = 75 * numberOfInstallDays;
      const base = total - helper - delivery;
      setData({
        ...data,
        Installer_Compensation: base,
        Helper: helper,
        Delivery: delivery,
      });
    }
  }, [comp.total, numberOfInstallDays, data.Installer]);

  function handleChange(e) {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  }

  async function sendInstallerAgreement() {
    setReady(false);
    setDone(false);
    setError("");
    setLink("");

    console.log("sending agreement...");

    const res = await Zoho.updateRecord("Potentials", { id: record.id, ...data });
    if (res.error) return setAlert("error", res.error);

    const agreementRes = await axios.get("/api/gapi/send-installer-agreement", { params: { potentialId: record.id } });
    console.log({ agreementRes });

    if (agreementRes.data.error) {
      setAlert("error", agreementRes.data.error);
      setError(agreementRes.data.error);
    } else {
      setLink(agreementRes.data.link);
      setDone(true);
    }

    setReady(true);
  }

  useEffect(() => {
    if (record?.id) checkTearOut();
  }, [record]);

  async function checkTearOut() {
    const salesOrder = await Zoho.getRelatedListsForRecord("Potentials", record.id, "SalesOrders");
    if (!salesOrder.length) return false;
    const lines = salesOrder["Product_Details"];
    const tearOut = lines?.find(line => ["tearout", "tear out", "tear-out"].includes(line.product.name.toLowerCase()));
    if (tearOut?.total > 0) setData({ ...data, Tear_Out: (tearOut.total * 0.7).toFixed(0) });
  }

  const subtotal = formattedCurrencyToNumber(comp.total) / formattedPercentToNumber(comp.percent);
  const total = +data.Installer_Compensation + +data.Delivery + +data.Helper + +data.LD_Travel + +data.Tear_Out + +data.Service;
  const calculatedPercent = (total / subtotal) * 100;

  return (
    <div>
      <div className="grid grid-cols-2 gap-4">
        <div className="col-span-1">
          <Select label="Installer" name="Installer" options={installers} data={data} onChange={handleChange} />
        </div>
        <div className="col-span-1">
          <Input label="Base Compensation" name="Installer_Compensation" data={data} onChange={handleChange} />
        </div>
        <div className="col-span-1">
          <Input label="Delivery" name="Delivery" data={data} onChange={handleChange} />
        </div>
        <div className="col-span-1">
          <Input label="Helper" name="Helper" data={data} onChange={handleChange} />
        </div>
        <div className="col-span-1">
          <Input label="Long Distance" name="LD_Travel" data={data} onChange={handleChange} />
        </div>
        <div className="col-span-1">
          <Input label="Tear Out" name="Tear_Out" data={data} onChange={handleChange} />
        </div>
        <div className="col-span-1">
          <Select label="407 ETR" name="Include_Tolls" options={["Yes", "No"]} data={data} onChange={handleChange} />
        </div>
        <div className="col-span-1">
          <Input label="Service" name="Service" data={data} onChange={handleChange} />
        </div>
        <div className="col-span-2">
          <Input label="Important Notes" name="Notes_For_Installer" data={data} onChange={handleChange} />
        </div>
      </div>
      <div className={`text-gray-300 rounded mt-4 ${calculatedPercent > 15 && "bg-yellow-200 !text-red-500 p-4"}`}>
        Total:{" "}
        <span class={`font-bold text-white ${calculatedPercent > 15 && "!text-red-500"}`}>
          ${total} ({calculatedPercent.toFixed(2)}%)
        </span>
      </div>
      {drawings.length && ready && (
        <div>
          <Button onClick={updateDrawings}>Update Drawings</Button>
          <div class="text-gray-400 mt-1 text-sm italic">This will overwrite the existing drawings with the latest 3D images and install package.</div>
        </div>
      )}
      {ready && !done && (
        <Button className="mt-4" onClick={sendInstallerAgreement}>
          Send Agreement
        </Button>
      )}
      {!ready && (
        <Button className="mt-4" disabled={true}>
          Sending Agreement...
        </Button>
      )}
      {done && (
        <div className="mt-8">
          <div className="text-xl text-green-400 font-medium ">Agreement Sent!</div>
        </div>
      )}
      {error && (
        <div className="mt-8">
          <div className="text-xl text-red-400 font-medium ">{error}</div>
        </div>
      )}
      {link && (
        <div className="mt-8">
          <div className="text-xl text-blue-400 font-medium ">
            <a href={link} target="_blank" rel="noreferrer">
              View Agreement
            </a>
          </div>
        </div>
      )}
    </div>
  );
}
