import React from "react";

const ImagePageHeader = ({ jobData }) => {
  return (
    <div className="flex items-center justify-between bg-gray-200 p-[6px] pl-3">
      <div className="text-sm font-medium">{jobData.clientName} | 3D Renderings</div>
      <div className="text-sm">
        {jobData.address} | {jobData.phone}
      </div>
    </div>
  );
};

export default ImagePageHeader;
