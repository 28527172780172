import axios from "./axios";
import { SET_JOB, SET_JOBS, RESET_JOB, DELETE_JOB, UPDATE_JOB, CREATE_JOB } from "./types";
import { setAlert } from "./alert";

export const Zoho = {
  getRecord: async function (module, recordId) {
    const path = "/" + module + "/" + recordId;
    const res = await axios.post("/api/zoho/get", { path });
    const response = res.data;
    return response.data.length ? response.data[0] : null;
  },
  /**
   * Get the records for a particular module
   * @param {String} module
   * @param {Object} options { start, filter, maxResults, orderBy }
   */
  getRecords: async function (module, options = {}) {
    let query = "Select * from " + module;
    if (options.filter) query += " WHERE " + options.filter;
    if (options.orderBy) query += " ORDERBY " + options.orderBy;
    if (options.start) query += " STARTPOSITION " + options.start;
    query += " MAXRESULTS " + (options.maxResults || "1000");
    const path = "/query?query=" + encodeURIComponent(query);
    const res = await axios.post("/api/zoho/get", { path });
    const response = res.data;
    return response.data.length ? response.data : [];
  },
  getAllRecords: async function (module, options = {}) {
    let allRecords = [];
    options.start = 1;
    while (true) {
      const res = this.getRecords(module, options);
      const records = res.QueryResponse[module];
      if (!records) break;
      allRecords = [...allRecords, ...records];
      const count = records.length;
      if (count !== 1000) break;
      options.start = options.start + count;
    }
    return allRecords;
  },
  insertRecord: async function (module, data) {
    const path = "/" + module.toLowerCase();
    const payload = { data: [data] };
    const res = await axios.post("/api/zoho/post", { path, payload });
    if (res.data[0].code === "SUCCESS") return res.data[0].details;
    else {
      console.log(res.data);
      return res.data[0];
    }
  },
  updateRecord: async function (module, data, useTriggers = true) {
    const path = "/" + module;
    const payload = { data: [data] };
    if (!useTriggers) payload.trigger = [];
    const res = await axios.post("/api/zoho/put", { path, payload });
    if (res.data[0].code === "SUCCESS") return res.data[0].details;
    else {
      console.log(res.data);
      return res.data[0];
    }
  },
  getRelatedListsForRecord: async function (module, recordId, relatedList) {
    if (relatedList == "Potentials") relatedList = "Deals";
    if (module == "Potentials") module = "Deals";
    const path = "/" + module + "/" + recordId + "/" + relatedList;
    const res = await axios.post("/api/zoho/get", { path });
    const response = res.data;
    console.log(response);
    return response.data?.length ? response.data : [];
  },
  getRelatedLists: async function (module) {
    const path = "/settings/related_lists?module=" + module;
    const res = await axios.post("/api/zoho/get", { path });
    const response = res.data;
    return response.related_lists.length ? response.related_lists : [];
  },
  queryRecords: async function (query) {
    const payload = { select_query: query.trim() };
    const path = "/coql";
    const res = await axios.post("/api/zoho/post", { path, payload });
    return res.data.length ? res.data : [];
  },
  searchContacts: async function (term) {
    const res = await this.queryRecords(`
      select Full_Name, Email, Modified_Time
      from Contacts
      where Full_Name like '%${term}%'
      order by Modified_Time DESC
      limit 30
    `);
    return res;
  },
  searchPotentials: async function (term) {
    const res = await this.queryRecords(`
      select Deal_Name, Contact_Name, Modified_Time
      from Deals
      where Deal_Name like '%${term}%'
      order by Modified_Time DESC
      limit 30
    `);
    return res;
  },
  searchContactsAndPotentials: async function (term) {
    const contacts = this.searchContacts(term);
    const potentials = this.searchPotentials(term);
    const res = await Promise.all([contacts, potentials]);
    const data = res
      .flat()
      .map(record => {
        const { Full_Name, Deal_Name } = record;
        return {
          ...record,
          label: Deal_Name || Full_Name,
          module: Deal_Name ? "Potential" : "Contact",
        };
      })
      .sort((a, b) => a.label.trim().toLowerCase().localeCompare(b.label.trim().toLowerCase()));
    return data;
  },
};
