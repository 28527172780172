import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { updateImage } from "../../actions/mozaik/rooms";
import { Checkmark } from "../Elements/Checkmark";

const RoomImageSelector = ({ rooms, images, updateImage }) => {
  const toggleSelected = e => {
    const image = images.find(image => image.id === e.currentTarget.id);
    updateImage({
      ...image,
      included: !image.included,
    });
  };

  const handleChange = e => {
    const image = images.find(image => image.id === e.target.id);
    updateImage({
      ...image,
      label: e.target.value,
    });
  };

  return (
    <div className="py-4">
      <div class="my-3 text-sm font-medium text-gray-400">Included Pictures</div>
      <div className="grid w-full grid-cols-4 gap-4 transition">
        {images.map(image => {
          if (!image.visible) return null;
          const src = image.thumbnailLink.split("=")[0];
          return (
            <div className="group cursor-pointer rounded-md transition hover:shadow-gray-700 ">
              <div id={image.id} onClick={toggleSelected} className="fade-in-image rounded-t-md bg-white p-4">
                <Checkmark visible={image.included} />
                <img className="min-h-[175px] w-full" src={src} />
              </div>
              <div className="rounded-b-md bg-gray-200 transition group-hover:bg-gray-300">
                <div className="pt-2 pl-4 text-xs text-gray-500 group-hover:text-gray-600 ">Room {image.number}</div>
                <input
                  onChange={handleChange}
                  id={image.id}
                  className="border-bottom w-full rounded-b-md border-transparent bg-transparent p-2 pt-0 pl-4 text-sm font-medium text-gray-600 placeholder-gray-500 outline-none duration-200 focus:border-none  focus:outline-none group-hover:text-gray-900 "
                  value={image.label}
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  rooms: state.room.rooms,
  images: state.room.images,
});

export default connect(mapStateToProps, { updateImage })(RoomImageSelector);
