import axios from "../axios";
import { Drive } from "../google";
import { dxf2svg } from "./utils";

/**
 * Thus function is run whenever a job is selected and will download the file content for all the project dxfs
 * returns an array of objects including the file data
 */

export const get2DImages = (jobFolder, activeWalls) => async dispatch => {
  const { id: jobFolderId, name: jobName } = jobFolder;
  const dxfFolder = await Drive.getFolder({ name: "DXFs", parent: jobFolderId });
  const folders = await Drive.getFolders({ parent: dxfFolder });

  const elevationsFolder = folders.find(folder => folder.name === "Elevations");
  const plansFolder = folders.find(folder => folder.name === "Plans");

  // let imagesFolder = folders.find(folder => folder.name === "Images");
  // if (imagesFolder) Drive.deleteFolder(imagesFolder.id);
  // imagesFolder = await Drive.createFolder("Images", dxfFolder);

  const [planFiles, elevationsFiles] = await Promise.all([Drive.getFilesByFolder(plansFolder), Drive.getFilesByFolder(elevationsFolder)]);
  const dxfFiles = [...planFiles, ...elevationsFiles].filter(file => {
    const wallNumber = String(file.name.extract(/Elevation([0-9]+)/) || "");
    const roomNumber = String(file.name.extract(/Room([0-9]+)/) || "");
    const isWallIncluded = wallNumber ? activeWalls.includes(`R${roomNumber}W${wallNumber}`) : true;
    return file.mimeType === "application/dxf" && isWallIncluded;
  });

  // to circumvent google sheets image caching
  const version = parseInt(Math.random() * (99999999 - 10000) + 10000);

  const sanitizedJobName = jobName
    .replace(/&/g, "+")
    .replace(/\[/g, "(")
    .replace(/\]/g, ")")
    .replace(/[^0-9A-Za-z ()+-]/g, " ")
    .replace(/ /g, "%20");

  let files = dxfFiles.map(file => {
    const filename = file.name.replace(".dxf", "");
    return {
      ...file,
      filename,
      jobName,
      type: filename.includes("Elevation") ? "Elevation" : "Plan",
      roomNumber: filename.extract(/Room([0-9]+)/),
      wall: filename.extract(/Elevation([0-9]+)/) || "0",
      elevation: filename.extract(/Elevation([0-9_]+)/) || "0",
      url: `https://res.cloudinary.com/markae/image/upload/h_1392/v${version}/Mozaik/${sanitizedJobName}/${filename}.jpg`,
    };
  });

  // send the images to Google App Script to be uploaded to cloudinary

  axios.post("/api/gapi/cloudinary", { images: files });

  // @TODO: The only reason we need to make all the API calls to get the DXF content and convert them to SVG is to get the aspectRatio
  // which is used later when preparing the production package. Instead what we can do is have the Google Script convert the dxf to svg
  // and find the aspectRatio, then perform all the required production package calculations required and export this info to a Google Sheet
  // the cloudinary URL serves as the unique identifier. Then in the production package rather than saving the aspectRatio, height, and width
  // directly to the spreadsheet, instead the cloudinary URLs are set to the sheet and then the sheet uses that URL to look up the aspectRatio,
  // height, and width in the reference sheet. This info can then be saved to values when the sheet is opened using the onOpen() trigger.

  const filesContent = await Promise.all(dxfFiles.map(file => Drive.readFile(file.id))); // fetch in parallel to improve perf
  const filesWithContent = files.map((file, i) => {
    const content = filesContent[i];
    const { svg, width, height } = dxf2svg(content);
    return {
      ...file,
      // content,
      // svg,
      width,
      height,
      aspectRatio: Number(height) / Number(width),
    };
  });

  const images = filesWithContent.sort((a, b) => {
    // sort by room number, then plan before elevation, then wall number
    const aKey = String(a.roomNumber.padStart(2, "0")) + String(a.type.length) + String(a.wall.padStart(2, "0"));
    const bKey = String(b.roomNumber.padStart(2, "0")) + String(b.type.length) + String(b.wall.padStart(2, "0"));
    return aKey - bKey;
  });

  return images;
};
