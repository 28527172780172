import { transpose } from "../../utils/general";
import axios from "../axios";
import { Drive } from "../google";
import { Room } from "../mozaik/classes/Room";
import { SpreadsheetApp } from "../sheets";
import { buildCoverPage } from "./cover";
import { buildDrawerPage, groupDrawerParts } from "./drawerPage";

// Note: This function is different than the one used for "Quote" because it excludes the Part Id (which is just the index of the part)
function getSheetValues(parts) {
  const getCabNumberWithLeadingZeros = cabNo => {
    cabNo = cabNo.replace(/R[0-9]+/, "R" + cabNo.extract(/R([0-9]+)/).padStart(2, "0"));
    return cabNo.replace(/C[0-9]+/, "C" + cabNo.extract(/C([0-9]+)/).padStart(2, "0"));
  };
  // make a clone of parts array to avoid sorting/mutating the original array (this is important to preserve the sort order for the drawers sheet)
  const partsClone = [...parts];
  partsClone.sort((a, b) => {
    const aKey = getCabNumberWithLeadingZeros(a["`CabNo"]) + a["`PrintName"];
    const bKey = getCabNumberWithLeadingZeros(b["`CabNo"]) + b["`PrintName"];
    if (aKey < bKey) return -1;
    else if (aKey > bKey) return 1;
    else return 0;
  });
  return partsClone.map(part => Object.values(part));
}

export class Package {
  constructor(parts, data, rooms, ss = null, pdf = null) {
    this.parts = parts;
    this.data = data;
    this.rooms = rooms;
    this.ss = ss;
    this.pdf = pdf;
  }

  getSpreadsheetId() {
    return this.ss.getId();
  }

  async createPackage() {
    const folderId = "1EbXlZwRUUN3zbiICxKNTjHjG7O7G5Wek";
    const templateId = "1OSm2BTbo03tt1weVy2KUkHrYMrKu54iUcmnwuL2htgw";
    const ss = await SpreadsheetApp.createSpreadsheet(this.data.clientName, folderId, templateId);
    this.ss = ss;
    return this;
  }

  async addJobData() {
    const data = this.data;
    const subheading = [data.address, data.phone].filter(Boolean).join(" | ");
    const values = [...Object.values(data), subheading].map(val => [val]);
    const sheet = await this.ss.getSheetByName("JobData");
    sheet.getRange(1, 2, values.length, values[0].length).setValues(values);
  }

  async addImageData(images) {
    console.log("adding image data...");
    const values = images.map(({ type, roomNumber, wall, elevation, aspectRatio, url }) => {
      const pageName =
        type === "Elevation"
          ? `Room${roomNumber}Wall${elevation}` //
          : `Room${roomNumber}Cover`;

      const outputWidth = 1010;
      const outputHeight = type === "Elevation" ? 696 : 465;
      const outputAspectRatio = outputHeight / outputWidth;

      // if aspect ratio is greater than 1 (i.e. height > width) then need to rotate the image for the cover
      if (aspectRatio > 1 && type === "Plan") {
        url = url.replace("h_1392", "h_1392,a_90");
        aspectRatio = 1 / aspectRatio;
      }

      let height, width;

      if (aspectRatio > outputAspectRatio) {
        height = outputHeight;
        width = outputHeight / aspectRatio;
      } else {
        width = outputWidth;
        height = outputWidth * aspectRatio;
        url = url.replace("h_1392", "w_2020");
      }
      return [pageName, url, width, height, aspectRatio];
    });
    console.log({ values });
    const sheet = await this.ss.getSheetByName("Images");
    sheet.getRange(2, 1, values.length, values[0].length).setValues(values);
  }

  async exportPDF() {
    console.log("exporting PDF...");
    const { jobName, folderId } = this.data;
    const base64 = await this.ss.exportPDF(false, true, false, 0.1, 0.1, 0.1, 0.1);
    console.log("saving PDF to Google Drive...");
    const file = await Drive.createFile(jobName, "application/pdf", base64, folderId);
    console.log(file);
    console.log("https://docs.google.com/file/d/" + file.id);
    this.pdf = file;
    return file;
  }

  async exportInstallPackage(potentialId) {
    const { jobName, folderId } = this.data;
    const base64 = await this.ss.exportPDF(false, true, false, 0.1, 0.1, 0.1, 0.1);
    const filename = `${jobName} Install Package`;
    const file = await Drive.upsertFile(filename, "application/pdf", base64, folderId);

    await axios.post("/api/installPkg", {
      job_id: folderId,
      job_name: jobName,
      potential_id: potentialId,
      pdf_id: file.id,
    });
  }

  async addParts() {
    const parts = this.parts;
    const values = getSheetValues(parts);
    const sheet = await this.ss.getSheetByName("Parts");
    sheet.getRange(2, 1, values.length, values[0].length).setValues(values);
  }

  async addRoomParams() {
    // get the required information for each room in an array

    console.log("adding Room Params...");

    const values = transpose(
      this.rooms.map(roomData => {
        const room = new Room(roomData.xml, roomData.number);
        const roomSet = room.getRoomSettings();
        const roomName = room.getRoomNameWithNumber();
        console.log(roomName);
        const notes = room.getNotes();
        const wallHeight = room.getWallHeight();
        const baseboards = room.getBaseboard();
        const doorHeights = room.getDoorHeight();
        return [roomName, roomSet.MatBandingTemplate, wallHeight + " / " + doorHeights, baseboards, notes];
      })
    );

    console.log({ roomParamValues: values });

    // set the values to the "RoomParams" sheet

    const sheet = await this.ss.getSheetByName("RoomParams");
    await sheet.getRange(2, 4, values.length, values[0].length).setValues(values);
  }

  async recalculate() {
    const sheet = await this.ss.getSheetByName("Config");
    await sheet.getRange(1, 2, 1, 1).setValues([["true"]]);
    await sheet.getRange(2, 2, 1, 1).setValues([["true"]]);
  }

  async buildCoverPage() {
    console.log("building cover page...");
    await buildCoverPage(this.ss, this.parts);
  }

  async buildDrawerPage() {
    console.log("building drawer page...");
    await buildDrawerPage(this.ss, this.parts);
  }

  async buildRooms() {
    const rooms = [...new Set(this.parts.map(part => part["`Room Name"]))];
    rooms.map(roomName => this.buildRoomPages(roomName));
  }

  async hideTemplateSheets() {
    console.log("hiding sheets...");
    await this.ss.hideSheetsByName(["RoomCover", "RoomCutlist", "RoomWall"]);
    console.log("finished hiding sheets...");
  }

  async buildRoomPages(images) {
    const rooms = [
      ...new Set(
        this.parts.map(part => {
          return String(part["`Room Name"].match(/(\[.*\])/)[1].trim());
        })
      ),
    ];

    console.log("room numbers: ", rooms);

    // filter the list of images to only those rooms that have parts

    const filteredImages = images.filter(image => {
      return rooms.includes(`[R${String(image.roomNumber)}]`);
    });

    console.log("filtered rooms: ", filteredImages);

    console.log("building room pages...");
    // const roomNumber = roomName.extract(/\[([0-9]+)\]/);
    const requests = buildRequest(filteredImages);
    const sheets = await this.ss.batchUpdate(requests);
    console.log({ sheets });

    // add sheetNames to sheets
    const updates = sheets.map(sheet => {
      const sheetName = sheet.duplicateSheet.properties.title;
      return {
        range: `${sheetName}!B1`,
        values: [[sheetName]],
      };
    });

    const res = await this.ss.batchUpdateValues(updates);
    console.log({ res });
    console.log("finished building room pages!");
  }

  getJSON(images2D, images3D) {
    const { clientName, address, phone, email, jobNotes } = this.data;

    console.log({ images2D });

    const json = {
      Client_Name: clientName,
      Address: address,
      Phone: phone,
      Email: email,
      Job_Notes: jobNotes,
      Rooms: this.rooms
        .map(roomData => {
          const room = new Room(roomData.xml, roomData.number);
          if (!room.isActive()) return null;
          const roomNumber = roomData.number;
          const roomSet = room.getRoomSettings();
          const roomName = room.getRoomNameWithNumber();
          const notes = room.getNotes();
          const wallHeight = room.getWallHeight();
          const baseboards = room.getBaseboard();
          const doorHeights = room.getDoorHeight();
          return {
            Room_Number: roomNumber,
            Room_Name: roomData.name,
            Full_Room_Name: roomName,
            MatCabTemplate: roomSet.MatCabTemplate,
            MatBandingTemplate: roomSet.MatBandingTemplate,
            Room_Notes: [notes.split("\n")],
            BBNH: baseboards,
            BBND: "",
            SHNH: "",
            SHND: "",
            Door_Height: doorHeights,
            Wall_Height: wallHeight,
            Molding: "",
            Walls: images2D.filter(image => image.roomNumber == roomNumber && image.type === "Elevation").map(image => image.url),
            Top_View: images2D.filter(image => image.roomNumber == roomNumber && image.type === "Plan").map(image => image.url),
            Accessories: "",
            "3D_Pictures": images3D.filter(file => file.number === roomNumber).map(file => file.id),
            Parts: this.parts
              .filter(part => part["`Room Name"] === roomName)
              .map(part => {
                return {
                  "`ID": part["`Id"],
                  "`ReportName": part["`PrintName"],
                  "`Style": part["`Style"],
                  "`Q": part["`Qty"],
                  "`W": part["`Width"],
                  "`L": part["`Length"],
                  "`Wall": part["`Wall"],
                  "`CabNo": part["`CabNo"],
                  "`Material": part["`Material"],
                  "`Room Name": part["`Room Name"],
                  "`Primary Category": part["`Primary Category"],
                  "`Secondary Category": part["`Secondary Category"],
                  "`Tertiary Category": part["`Tertiary Category"],
                  "`Measurement": part["`Measurement"],
                };
              }),
          };
        })
        .filter(Boolean),
      Drawers_List: groupDrawerParts(this.parts),
      Rods_List: this.parts
        .filter(part => part["`Secondary Category"] === "Closet Rod")
        .map(part => {
          return [part["`PrintName"], part["`Qty"], part["`Length"], part["`CabNo"], part["`Wall"]];
        }),
      Extras_List: this.parts
        .filter(
          part =>
            (part["`Primary Category"] === "Kick" ||
              part["`PrintName"] === "Face Kick (8ft)" ||
              ["Filler", "Countertop", "Backing", "Molding", "Base Cabinet", "Stretcher", "End Panel"].includes(part["`Secondary Category"])) &&
            !part["`PrintName"].startsWith("[True Length]")
        )
        .map(part => {
          return [part["`PrintName"], part["`Qty"], part["`Width"], part["`Length"], part["`CabNo"], part["`Material"]];
        }),
    };

    return json;
  }
}

function buildRequest(images) {
  const startIndex = 15;
  const coverTemplateId = "1686834993";
  const cutlistTemplateId = "429274101";
  const wallTemplateId = "1476941620";
  const requests = [];

  let count = 0;

  images.forEach(image => {
    if (image.type == "Plan") {
      requests.push({
        duplicateSheet: {
          sourceSheetId: coverTemplateId,
          insertSheetIndex: startIndex + count++,
          newSheetName: `Room${image.roomNumber}Cover`,
        },
      });
      requests.push({
        duplicateSheet: {
          sourceSheetId: cutlistTemplateId,
          insertSheetIndex: startIndex + count++,
          newSheetName: `Room${image.roomNumber}Cutlist`,
        },
      });
    } else {
      requests.push({
        duplicateSheet: {
          sourceSheetId: wallTemplateId,
          insertSheetIndex: startIndex + count++,
          newSheetName: `Room${image.roomNumber}Wall${image.elevation}`,
        },
      });
    }
  });
  return requests;
}
