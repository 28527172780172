import { sum, round } from "../../utils/general";
import { v4 as uuidv4 } from "uuid";

export const mergeDuplicates = (parts, compare = [], exclude = []) => {
  const excludedParts = [];
  const mergedParts = parts.reduce((res, part) => {
    // if the secondary category of the part is in the exclusion list, then don't merge

    if (exclude.includes(part["`Secondary Category"])) {
      excludedParts.push(part);
      return res;
    }

    // create an id with the values of all the attributes we want to include in the comparison
    // therefore all parts that have the same id are parts we want to merge
    const id = compare.map(key => part[key]).join("-");

    // if "res" object already has the id then increment it by the quantity of the current part, otherwise create a new obj to add to "res"
    res[id] = res[id]
      ? {
          ...res[id],
          "`Qty": Number(res[id]["`Qty"]) + Number(part["`Qty"]),
        }
      : { ...part };

    return res;
  }, {});
  console.log(excludedParts);
  return [...Object.values(mergedParts), ...excludedParts];
};

export const addFaceKickStrips = parts => {
  const faceKicks = parts.filter(part => part["`PrintName"] == "Face Kick");
  if (!faceKicks.length) return parts;

  const stripLength = 8; // length of strip in feet
  const materials = new Set([...parts.map(part => part["`Material"])]);
  const rooms = new Set([...parts.map(part => part["`Room Name"])]);
  for (const room of rooms) {
    const roomNumber = room.extract(/\[(.*)\]/);
    for (const material of materials) {
      const matching = faceKicks.filter(part => part["`Material"] == material && part["`Room Name"] == room);
      if (!matching.length) continue;
      const W = matching[0]["`Length"];
      const totalLnFt = sum(matching.map(part => part["`Width"].to("ft") * part["`Qty"]));
      const totalLnFtPlus = totalLnFt + stripLength;
      const numStrips = round(totalLnFtPlus / stripLength, 0);

      parts.push({
        "`Id": uuidv4(),
        "`Room Name": room,
        "`PrintName": `Face Kick (8ft)`,
        "`Qty": numStrips,
        "`Material": material,
        "`CabNo": `${roomNumber}C0`,
        "`Wall": 0,
        "`Style": "8ft strip",
        "`Width": W, // inches
        "`Length": stripLength * 12, // inches
        "`Primary Category": "Face Kick",
        "`Secondary Category": "Kick",
        "`Tertiary Category": "Wood",
        "`Measurement": "",
        "`LnFt": stripLength,
        "`SqFt": stripLength * W.to("ft"),
      });
    }
  }
  return parts;
};
