import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { updateRoom } from "../actions/mozaik/rooms";
import MarkaeLogo from "../components/MarkaeLogo/MarkaeLogo";
import RoomSelector from "../components/Rooms/RoomSelector";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import RoomImageSelector from "../components/Rooms/RoomImageSelector";

const Options = () => {
  return (
    <div className="py-10">
      <MarkaeLogo />
      <Link className="mt-2 mb-10 block text-sm text-gray-400 " to="/quote">
        <div className="flex items-center text-gray-400 hover:text-gray-200">
          <svg className="h-3 w-3 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
            <path d="M224 480c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25l192-192c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l169.4 169.4c12.5 12.5 12.5 32.75 0 45.25C240.4 476.9 232.2 480 224 480z" />
          </svg>{" "}
          Go Back
        </div>
      </Link>
      <div className="mb-6 text-2xl font-medium text-gray-200">Configuration</div>
      <RoomSelector />
      <RoomImageSelector />
    </div>
  );
};

export default Options;
