import React, { useMemo } from "react";
import JobCoverPageRowHeader from "./JobCoverPageRowHeader";
import JobCoverPageRowRoomColumn from "./JobCoverPageRowRoomColumn";
import JobCoverPageRowRoomPlaceholder from "./JobCoverPageRowRoomPlaceholder";

const JobCoverPageRow = ({ rooms }) => {
  const columns = useMemo(() => {
    return [...rooms, ...Array(5 - rooms.length).fill("")];
  }, [rooms.length]);

  return (
    <div class="mt-2 flex">
      <JobCoverPageRowHeader />
      {columns.map(room => {
        if (room) return <JobCoverPageRowRoomColumn room={room} count={rooms.length + 1} />;
        else return <JobCoverPageRowRoomPlaceholder />;
      })}
    </div>
  );
};

export default JobCoverPageRow;
