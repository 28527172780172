import React, { useEffect, useState } from "react";
// rewrite the above correctly
import CreatableSelect from "react-select/creatable";
import Select from "react-select";

const GenericSelect = ({ value, options, onChange, placeholder, className, isClearable = false, styleOverride = {}, label = "", classes = {}, name = "", isCreatable = false, data = {} }) => {
  const [usedOptions, setUsedOptions] = useState([]);

  useEffect(() => {
    if (options.length && typeof options[0] == "object") setUsedOptions(options);
    else if (options.length)
      setUsedOptions(
        options.map(option => {
          return {
            value: option,
            label: option,
          };
        })
      );
  }, [options]);

  function handleChange(obj) {
    if (obj) onChange({ target: { ...obj, name } });
    else onChange({ target: { name, value: "" } });
  }

  const styles = {
    control: (styles, { isFocused }) => ({
      ...styles,
      outline: "none",
      boxShadow: "none",
      borderColor: isFocused ? "#fff" : "transparent",
      ":hover": { borderColor: "#fff" },
      borderRadius: "0.5rem",
      borderWidth: "2px",
      backgroundColor: "rgb(31 41 55)",
      fontSize: "1rem",
      fontWeight: "500",
      color: "white",
    }),
    multiValueRemove: styles => ({
      ...styles,
      ":hover": {
        backgroundColor: "lightgray",
        color: "black",
      },
    }),
    indicatorSeparator: styles => ({ display: "none" }),
    input: styles => ({ ...styles, color: "white", fontSize: "1rem", padding: "0.25rem", paddingLeft: "0", margin: "0" }),
    valueContainer: styles => ({ ...styles, padding: "0.25rem", margin: "0", paddingLeft: "1rem" }),
    singleValue: styles => ({ ...styles, color: "white", fontSize: "1rem" }),
    multiValue: styles => ({ ...styles, backgroundColor: "rgb(107 114 128)", marginLeft: "0", marginRight: "0.5rem", borderRadius: "0.5rem", color: "white", fontSize: "1rem" }),
    multiValueLabel: styles => ({ ...styles, color: "white" }),
    placeholder: styles => ({ ...styles, fontSize: "1rem", color: "rgb(107 114 128)", paddingLeft: "0" }),
    option: (styles, { isFocused }) => ({
      ...styles,
      fontSize: "0.75rem",
      borderRadius: "0.25rem",
      backgroundColor: isFocused ? "rgb(107 114 128)" : "transparent",
      ":hover": {
        backgroundColor: "rgb(107 114 128)",
      },
    }),
    menu: styles => ({ ...styles, zIndex: "999", backgroundColor: "rgb(31 41 55)", color: "white", borderRadius: "0.5rem" }),
    ...styleOverride,
  };

  const valueObj = usedOptions.find(option => option.value == value) || usedOptions.find(option => option.value == data[name]) || null;

  return (
    <div class={className}>
      {label && <label className={`mb-1 block text-sm font-medium leading-5 text-gray-400 ${classes.label}`}>{label}</label>}
      {isCreatable && <CreatableSelect name={name} value={valueObj} placeholder={placeholder || ""} onChange={handleChange} options={usedOptions} styles={styles} isClearable={isClearable} />}
      {!isCreatable && <Select name={name} value={valueObj} placeholder={placeholder || ""} onChange={handleChange} options={usedOptions} styles={styles} isClearable={isClearable} />}
    </div>
  );
};

export default GenericSelect;
