import React, { useState, useEffect } from "react";
import Document from "../../components/PDF/Document";
import Page from "../../components/PDF/Page";
import ImageGrid from "../../components/Drawings/ImageGrid";
import { chunk } from "../../utils/general";
import { connect } from "react-redux";
import Input from "../../components/Elements/Input";
import GenericSelect from "../../components/Layout/Select";
import ImagePageHeader from "../../components/Drawings/ImagePageHeader";
import moment from "moment";
import { groupBy } from "lodash";

const Drawings = ({ images, includeDownloadBtn = true, setDownloadPDF, jobData }) => {
  const [exportPDF, setExportPDF] = useState();
  const [chunkedImages, setChunkedImages] = useState([]);
  const [imagesPerPage, setImagesPerPage] = useState(4);
  const [splitByRoom, setSplitByRoom] = useState(false);

  useEffect(() => {
    if (Array.isArray(images)) {
      const filtered = images.filter(image => image.visible && image.included);
      if (!splitByRoom) {
        setChunkedImages(chunk(filtered, imagesPerPage));
      } else {
        setChunkedImages(Object.values(groupBy(filtered, "number")));
      }
    }
  }, [images, imagesPerPage, splitByRoom]);

  const filename = `${jobData.clientName} 3D Drawings (${moment().format("MMM D, yyyy @ hh:mm a")})`;

  const options = {
    pageSize: "letter",
    unit: "pt",
    orientation: "landscape",
    scale: 2,
    bgColor: "white",
    preview: true,
    previewBorder: "1px solid gray",
  };

  const handleChange = e => setImagesPerPage(e.target.value);

  return (
    <div>
      <div className="flex gap-4">
        <div className="my-6 flex w-full items-center justify-between">
          <div className="text-3xl font-medium text-gray-200">Drawings</div>
          <div className="flex items-center">
            <div className="mr-6 flex items-center">
              <div className="mr-2 text-sm text-gray-400">Split by Room</div>
              <input className="h-6 w-6" type="checkbox" checked={splitByRoom} onChange={() => setSplitByRoom(!splitByRoom)} />
            </div>
            <div>
              <div className="text-sm text-gray-400">Images Per Page</div>
              <GenericSelect className="mr-4 w-[200px] text-center" onChange={handleChange} value={imagesPerPage} isClearable={false} options={[1, 4]} />
            </div>
            {includeDownloadBtn && (
              <div>
                <div className="h-[20px]"></div>
                <button className="rounded border border-white py-2 px-24 text-white transition hover:bg-white hover:text-gray-900" onClick={exportPDF}>
                  Download
                </button>
              </div>
            )}
          </div>
        </div>
        {/* <button className="rounded border border-white py-2 px-6 text-white transition hover:bg-white hover:text-gray-900" onClick={getPreview}>
          Preview
        </button> */}
      </div>
      <Document filename={filename} options={options} setExportPDF={setExportPDF} setDownloadPDF={setDownloadPDF}>
        {chunkedImages.map(gridImages => (
          <Page>
            <ImagePageHeader jobData={jobData} />
            <ImageGrid images={gridImages} />
          </Page>
        ))}
      </Document>
    </div>
  );
};

const mapStateToProps = state => ({
  images: state.room.images,
  jobData: state.job.jobData,
});

export default connect(mapStateToProps, {})(Drawings);
