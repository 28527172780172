import { SET_PART_REFERENCES, SET_PROGRESS } from "../types";
import axios from "../axios";
import { Drive } from "../google";
import { getPriceCategoryCriteria } from "./priceCategory";

// export async function initQuoteProcess(folderId) {
//   const data = getDataFromMozaikFile(folderId);
// }

// export async function initProductionProcess(folderId) {
//   const data = getDataFromMozaikFile(folderId);
// }

// export async function initDrawingsProcess(folderId) {
//   const data = getDataFromMozaikFile(folderId);
// }

/**
 * Parse the JobDat.dat mozaik file
 * { clientName, address, phone, email, jobNotes }
 */

export async function getJobData(jobFolder) {
  const { id: folderId, name: jobName } = jobFolder;
  const file = await Drive.getFileByName("JobDat.dat", null, folderId);
  const res = await Drive.readFile(file.id);
  const lines = res.split(/\r?\n/);
  const jobNotes = lines[7].split(",").filter(Boolean);
  return {
    clientName: lines[1],
    address: lines[3] + ", " + lines[4],
    phone: lines[5],
    email: lines[6],
    jobNotes: jobNotes.length ? String.fromCharCode(...jobNotes) : "",
    folderId,
    jobName,
  };
}

/**
 * Fetches the data from the part references sheet as a JSON object
 * {mozaikPartRef: {}, partRef: {}, drawerFaceHeightRef: {}, drawerFaceWidthRef: {}, slideRef: {}}
 * https://docs.google.com/spreadsheets/d/1Y_z4UBHkGduOzmW_37kIVKnM9Bu1kephiW1f8X8JT6c/edit#gid=937292652
 */

// @TODO: Pull this directly using the Google API in parallel to improve perf

export const getPartReferences = () => async dispatch => {
  const [references, priceCategories] = await Promise.all([axios.get("/api/gapi/references"), getPriceCategoryCriteria()]);
  const ref = {
    ...references.data,
    priceCategories,
  };
  console.log(ref);

  dispatch({ type: SET_PART_REFERENCES, payload: ref });
};

export const setProgress = progress => async dispatch => {
  dispatch({ type: SET_PROGRESS, payload: progress });
};

export async function createOutputFolder() {}
