import React, { useState } from "react";
import { Link } from "react-router-dom";

const BookmarkModalIconSelector = ({ mode, setMode }) => {
  const inactiveTabClass = "w-full cursor-pointer rounded-md bg-gray-700 py-4 text-center text-white hover:bg-opacity-50";
  const activeTabClass = "w-full cursor-pointer rounded-md bg-gray-200 py-4 text-center text-gray-900";

  return (
    <div>
      <div class="mb-2 flex items-center justify-center space-x-2 text-sm">
        <Link to="/quote" class={mode === "quote" ? activeTabClass : inactiveTabClass}>
          Quote
        </Link>
        <Link to="/production" class={mode === "production" ? activeTabClass : inactiveTabClass}>
          Production
        </Link>
        <Link to="/drawings" class={mode === "drawings" ? activeTabClass : inactiveTabClass}>
          Drawings
        </Link>
        <Link to="/optimizer" class={mode === "optimizer" ? activeTabClass : inactiveTabClass}>
          Optimizer
        </Link>
        {/* <Link to="/optimizer-parts" class={mode === "optimizer-parts" ? activeTabClass : inactiveTabClass}>
          Optimizer Parts
        </Link> */}
        {/* <Link to="/cnc-optimizer" class={mode === "cnc-optimizer" ? activeTabClass : inactiveTabClass}>
          CNC Optimizer
        </Link> */}
        <Link to="/installation" class={mode === "installation" ? activeTabClass : inactiveTabClass}>
          Installation
        </Link>
        {/* <Link to="/transfer" class={mode === "transfer" ? activeTabClass : inactiveTabClass}>
          Transfer Jobs
        </Link> */}
        {/* <div onClick={() => setMode("quote")} class={mode === "quote" ? activeTabClass : inactiveTabClass}>
          Quote
        </div>
        <div onClick={() => setMode("production")} class={mode === "production" ? activeTabClass : inactiveTabClass}>
          Production
        </div>
        <div onClick={() => setMode("drawings")} class={mode === "drawings" ? activeTabClass : inactiveTabClass}>
          Drawings
        </div>
        <div onClick={() => setMode("optimizer")} class={mode === "optimizer" ? activeTabClass : inactiveTabClass}>
          Optimizer
        </div> */}
      </div>
    </div>
  );
};

export default BookmarkModalIconSelector;
