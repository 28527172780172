import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { setAlert } from "../../actions/alert";
import { Drive } from "../../actions/google";
import JobSelect from "../../components/Jobs/JobSelect";

const OptimizerParts = ({ jobFolder, jobParts, rooms, setAlert }) => {
  const [files, setFiles] = useState([]);
  const [complete, setComplete] = useState(false);

  async function fetchFiles(jobFolder) {
    if (jobFolder.id) {
      const files = (await Drive.getFilesByFolder(jobFolder.id)).filter(file => file.name.endsWith(".opt"));
      if (!files.length) setAlert("error", "No optimizer files found.");
      setFiles(files);
    }
  }

  useEffect(() => {
    fetchFiles(jobFolder);
  }, [jobFolder.id]);

  function handleClick() {
    console.log("todo");
  }

  return (
    <div className="mt-10">
      <JobSelect />
      <button
        onClick={handleClick}
        class={`mt-12 block w-full cursor-pointer items-center justify-center rounded-md bg-cyan-500 px-4 py-4 text-lg leading-6 text-white shadow-sm outline-gray-200 transition duration-150 ease-in-out hover:bg-cyan-400 focus:outline-offset-1 sm:leading-5 ${
          !files.length || !rooms.length ? "cursor-not-allowed opacity-50 hover:bg-cyan-500" : "opacity-100"
        }`}
      >
        {complete ? "Finished" : `Start`}
      </button>
      {files.length === 0 && <p className="my-1 text-sm font-semibold tracking-wide text-red-500">Unable to find any optimizer files</p>}
    </div>
  );
};

const mapStateToProps = state => ({
  jobFolder: state.job.job,
  rooms: state.room.rooms,
  params: state.room.params,
});

export default connect(mapStateToProps, { setAlert })(OptimizerParts);
