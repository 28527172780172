import { round, sum, roundNearest, roundDownToArrayValue } from "../../../utils/general";
import { Part } from "./Part";

export class Door extends Part {
  getDoorFace() {
    return new DoorFace(this.PartElement, this.Product);
  }
  getDoorInsert() {
    return new DoorInsert(this.PartElement, this.Product);
  }
  getDoorPull() {
    return new DoorPull(this.PartElement, this.Product);
  }
  getDoorHinges() {
    return new DoorHinges(this.PartElement, this.Product);
  }
  getStyle() {
    return this.getPartAttribute("DoorStyle");
  }
  getQuantity() {
    return 1;
  }
  getDoorElement() {
    return this.PartElement;
  }
}

export class DoorFace extends Door {
  getPrintName() {
    return "Door Face | " + this.getStyle();
  }
  getMaterial() {
    const SectAC = this.getDoorElement().attr("SectAC"); // "A" or "C"
    const frontFaceValue = this.getFrontFace().attr("MatDoorOR" + SectAC);
    const productValue = this.getProductAttribute("MatDoorOR");
    const roomValue = this.getRoomSetting("MatDoorTemplate");
    const material = frontFaceValue || productValue || roomValue;
    // @TODO: Should change this to be more specific door face material
    if (material === "Thermofoil") {
      return "Thermofoil | " + super.getMaterial().exclude("0.75 inch ");
    }
    return '3/4" | ' + material.exclude("SLAB").exclude("0.75 inch ").trim();
  }
  getWidth() {
    const width = this.getPartAttribute("W");
    return Math.floor(roundNearest(width / 0.5, 1) * 0.5); // rounds down, unless .8mm or higher
  }
  getLength() {
    const length = this.getPartAttribute("H");
    return Math.floor(roundNearest(length / 0.5, 1) * 0.5); // rounds down, unless .8mm or higher
  }
}

export class DoorHinges extends Door {
  getPrintName() {
    return "Hinge | " + this.getHingeType();
  }
  // @TODO: Need to retest this under all conditions
  getHingeType() {
    const DoorProdPart = this.getDoorElement().find("DoorProdPart");
    if (DoorProdPart.attr("Y") > 0) return "Inset Hinge";

    const isSecondOfPair = this.getDoorElement().attr("IsSecondOfPair") == "True";
    const FrontFace = this.getFrontFace();

    // Need to look at HingeA and HingeC to see if "Blind Corner Hinge"

    const SectAC = this.getDoorElement().attr("SectAC");
    const HingeOR = SectAC === "A" ? FrontFace.attr("HingeA") : FrontFace.attr("HingeC");

    // @TODO: Do we still need to say verify with office?
    // if (HingeOR == 'Blind Corner Hinge') return 'Verify with Office'
    if (HingeOR !== "0") return "Verify with Office";

    // We need to know the hingeSide to know whether to use deltaL or deltaR
    const hingeEdgeOR = SectAC === "A" ? FrontFace.attr("AHinge") : FrontFace.attr("CHinge");
    const hingeEdge = this.getDoorElement().attr("HingeEdge")[0];
    const hingeSide = hingeEdgeOR === "N" ? hingeEdge : hingeEdgeOR;

    console.log({ hingeEdge, hingeEdgeOR, hingeSide });

    if (hingeSide === "B") {
      return "Halfover Hinge";
    } else if (hingeSide === "T") {
      return "Fullover Hinge";
    } else if (hingeSide !== "L" && hingeSide !== "R") {
      throw new Error("Invalid hinge side!");
    }

    // Need to pull the correct delta based on the section of the door (A or C) and whether the hinges are on the left or right
    // AdeltaL | AdeltaR | CdeltaL | CdeltaR
    const delta = FrontFace.attr(`${SectAC}delta${hingeSide}`);

    const isAdjusted = delta > 0;

    const FLRevC = this.getMozaikParam("FLRevC").to("inches");

    // if (FLRevC == -0.3125 && isAdjusted && isSecondOfPair) return "Fullover Hinge";
    if (FLRevC == -0.3125 && isAdjusted) return "Fullover Hinge";
    else if (FLRevC == 0.0625 || FLRevC == -0.6875) return "Fullover Hinge";
    else return "Halfover Hinge";
  }
  getStyle() {
    return this.getHingeType();
  }
  getQuantity() {
    const doorHeight = round(this.getPartAttribute("H").to("inch"), 4);
    const qtyRef = { 0: 2, 48: 3, 70: 4, 85: 5, 100: 6 };
    return qtyRef[roundDownToArrayValue(doorHeight, Object.keys(qtyRef))];
  }
  getWidth() {
    return 0;
  }
  getLength() {
    return 0;
  }
}

export class DoorPull extends Door {
  getPrintName() {
    return "Door Pull | " + this.getDoorPullStyle();
  }
  getStyle() {
    return this.getDoorPullStyle();
  }
  getDoorPullStyle() {
    if (this.doorPullStyle) return this.doorPullStyle;
    const SectAC = this.getPartAttribute("SectAC"); // "A" or "C"
    const frontFaceValue = this.getFrontFace().attr("Pull" + SectAC);
    if (isNaN(frontFaceValue)) return frontFaceValue;
    const productValue = this.getProductAttribute("DoorPullOR");
    const roomValue = this.getRoomSetting("DoorPulls") || this.getRoomSetting("BasePulls");
    const doorPullStyle = productValue || roomValue;
    this.doorPullStyle = doorPullStyle;
    return doorPullStyle;
  }
  getQuantity() {
    const doorPullStyle = this.getStyle();
    return doorPullStyle ? 1 : 0;
  }
  getWidth() {
    return 0;
  }
  getLength() {
    return 0;
  }
}

export class DoorInsert extends Door {
  getPrintName() {
    const doorStyle = this.getStyle();
    if (doorStyle.includes("Acid-Etched")) return "Glass | Acid-etched | 3mm";
    else if (doorStyle.includes("Beveled Clear Glass")) return "Glass | Clear | 3mm | Beveled";
    else if (doorStyle.includes("Clear Glass")) return "Glass | Clear | 3mm";
    else if (doorStyle.includes("Exterior Mirror")) return "Mirror | 5mm";
    else if (doorStyle.includes("Beveled Mirror")) return "Mirror | 3mm | Beveled";
    else if (doorStyle.includes("Mirror")) return "Mirror | 3mm";
    else return "NONE";
  }
  getQuantity() {
    return this.getPrintName() == "NONE" ? 0 : 1;
  }
  getLength() {
    // use full door measurements for exterior mirror, otherwise for all other inserts deduct approx 2 inches from each side (4" total)
    const doorStyle = this.getStyle();
    if (doorStyle.includes("Exterior Mirror")) return Math.floor(this.getPartAttribute("H"));
    return Math.floor(this.getPartAttribute("H")) - 4 * 25.4;
  }
  getWidth() {
    // use full door measurements for exterior mirror, otherwise for all other inserts deduct approx 2 inches from each side (4" total)
    const doorStyle = this.getStyle();
    if (doorStyle.includes("Exterior Mirror")) return Math.floor(this.getPartAttribute("W"));
    return Math.floor(this.getPartAttribute("W")) - 4 * 25.4;
  }
}
