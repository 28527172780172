import { SpreadsheetApp } from "../sheets";

export async function getPriceCategoryCriteria() {
  const ss = await SpreadsheetApp.getSpreadsheet("1AOKB_5_ebVbK4KY8q4-ANFCS5m24fLyqWk7mEcXB3OA");
  const sheet = await ss.getSheetByName("Price List");
  const values = await sheet.getRange("A2:C").getValues();
  const criteria = {};
  values.forEach(([category, query, condition]) => {
    if (condition) criteria[category] = sanitizeCondition(condition);
  });
  return criteria;
}

function sanitizeCondition(condition) {
  const exclusions = [
    /<[^ ]*>/gi, // remove all html elements (ie. <script>, <link>, etc)
    /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/gi, // remove IP addresses
    /:/gi, // eliminate ability to specify ports
    /function/gi, // prevent function declarations
    / const[ ]*=/gi, // prevent variable assignments
    / var[ ]*=/gi, // prevent variable assignments
    / let[ ]*=/gi, // prevent variable assignments
    /http/gi, // remove any website reference
    /href/gi, // remove any link references
    /data:/gi, // remove data attributes
    /xlink/gi, // remove xlink
    / window./gi,
    / global./gi,
    / alert\(/gi,
  ];
  exclusions.forEach(exclusion => {
    condition = condition.excludeAll(exclusion);
  });
  condition = condition
    .replaceAll("  ", " ")
    .replaceAll(/AND Width [=><]+ [0-9.]+/g, "")
    .replaceAll(/AND Length [=><]+ [0-9.]+/g, "")
    .replaceAll(/AND SqFt [=><]+ [0-9.]+/g, "")
    .replaceAll(/AND LnFt [=><]+ [0-9.]+/g, "")
    .replaceAll(/ AND /gi, " && ")
    .replaceAll(/ OR /gi, " || ")
    .replaceAll(/ EQUALS /gi, " == ")
    .replaceAll(/ = /gi, " == ")
    .replaceAll(/ IS /gi, " == ")
    .replaceAll(/ ISNOT /gi, " != ")
    .replaceAll(/ CONTAINS[ ]+('.*?')/gi, ".includes($1)")
    .replaceAll(/ STARTS WITH[ ]+('.*?')/gi, ".startsWith($1)")
    .replaceAll(/ ENDS WITH[ ]+('.*?')/gi, ".endsWith($1)")
    .trim();
  return condition.slice(0, 300); // impose 300 character limit
}

export function getPriceCategoriesForPart(part, criteria) {
  const PrintName = part["Print Name"];
  const PrimaryCategory = part["Primary Category"];
  const SecondaryCategory = part["Secondary Category"];
  const TertiaryCategory = part["Tertiary Category"];
  const Material = part["Material"];
  // const Length = part["`Length"].to("inch");
  // const Width = part["`Width"].to("inch");
  // const SqFt = part["`SqFt"];
  // const LnFt = part["`LnFt"];

  const categories = Object.keys(criteria).filter(key => {
    const condition = criteria[key];
    return eval(`
      const PrintName = '${PrintName}';
      const PrimaryCategory = '${PrimaryCategory}';
      const SecondaryCategory = '${SecondaryCategory}';
      const TertiaryCategory = '${TertiaryCategory}';
      const Material = '${Material}';
      ${condition}
    `);
  });
  return categories;
}
