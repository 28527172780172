import React from "react";

function OptimizerVBEdgeFix({ operations, setOperations, files }) {
  return (
    <div>
      <h1 class="text-white my-4">VB Edge Fix - Only After Finished Optimizing!</h1>
      <div className="flex flex-col space-y-2">
        {files.map((file, i) => (
          <div className="flex items-center">
            <input
              className="h-4 w-4"
              type="checkbox"
              checked={operations.vbEdgeFix.includes(file.name)}
              onChange={() =>
                setOperations({
                  ...operations,
                  vbEdgeFix: operations.vbEdgeFix?.includes(file.name) ? operations.vbEdgeFix.filter(name => name !== file.name) : [...operations.vbEdgeFix, file.name],
                })
              }
            />
            <div class="pl-1 text-sm text-gray-400">{file.name}</div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default OptimizerVBEdgeFix;
