import { SET_ROOMS, SET_ROOM_IMAGES, RESET_ROOM_STATE, UPDATE_ROOM, UPDATE_IMAGE } from "../types";
import { Drive } from "../google";
import _ from "lodash";
import * as cheerio from "cheerio";

export const updateRoom = room => async dispatch => {
  dispatch({ type: UPDATE_ROOM, payload: room });
};

export const updateImage = image => async dispatch => {
  dispatch({ type: UPDATE_IMAGE, payload: image });
};

export const resetRoomState = () => async dispatch => {
  dispatch({ type: RESET_ROOM_STATE });
};

export async function getRoomFiles(jobFolderId, includeContent) {
  const files = await Drive.getFilesByFolder(jobFolderId);
  let roomFiles = files.filter(file => file.name.endsWith(".des") && file.name != "Room0.des");
  if (includeContent) roomFiles = await Promise.all(roomFiles.map(async file => ({ ...file, content: await Drive.readFile(file.id) })));
  return roomFiles;
}

export async function share3DImagesFolder(jobFolderId) {
  const imageFolder = await Drive.getFolder({ name: "2D3DViewerPictures", parent: jobFolderId });
  if (!imageFolder) return;
  const res = await Drive.shareFolder({
    folderId: imageFolder.id,
    editAccess: true,
    emailAddress: "production.spaceformers@gmail.com",
    sendNotificationEmail: false,
  });
  await Drive.shareFolderPublic(imageFolder.id);
  console.log("Sharing folder...");
  console.log(res);
  return res;
}

export async function shareQuotesFolder(jobFolderId) {
  const folder = await Drive.getFolder({ name: "Quotes", parent: jobFolderId });
  if (!folder) return;
  const res = await Drive.shareFolder({
    folderId: folder.id,
    editAccess: true,
    emailAddress: "info.spaceformers@gmail.com",
    sendNotificationEmail: false,
  });
  return res;
}

export const getRoomImages = jobFolderId => async dispatch => {
  const imageFolder = await Drive.getFolder({ name: "2D3DViewerPictures", parent: jobFolderId });

  if (!imageFolder) return [];

  const files = await Drive.getFilesByFolder(imageFolder);

  console.log({ files });
  const images = files
    .filter(file => file.mimeType == "image/jpeg")
    .map(image => ({
      ...image,
      label: image.name.match(/\[.*] [0-9]* ([^~]*).jpeg/)[1],
      visible: true,
      included: true,
      number: Number(image.name.match(/\[(.*?)]/)[1].replace("Room", "")),
      imageNumber: Number(image.name.match(/([0-9)]{3})/)[1]),
    }));
  images.sort((a, b) => a.imageNumber - b.imageNumber);
  console.log({ images });
  dispatch({ type: SET_ROOM_IMAGES, payload: images });
  return images;
};

export const getRooms = jobFolderId => async dispatch => {
  if (!jobFolderId) return dispatch({ type: RESET_ROOM_STATE });

  dispatch(getRoomImages(jobFolderId));
  const roomFiles = await getRoomFiles(jobFolderId, true);
  const rooms = roomFiles.map(file => {
    const roomNumber = Number(file.name.replace(".des", "").replace("Room", ""));
    const xml = file.content.slice(file.content.indexOf("<?xml"));
    const $ = cheerio.load(xml, { xmlMode: true });
    const roomName = $("Room").attr("Name");
    return {
      id: file.id,
      name: roomName,
      number: roomNumber,
      activeWalls: [...new Set(getActiveWalls($, roomNumber))],
      parts: [],
      active: true,
      xml: xml,
    };
  });
  rooms.sort((a, b) => a.number - b.number);
  dispatch({ type: SET_ROOMS, payload: rooms });
  share3DImagesFolder(jobFolderId);
  shareQuotesFolder(jobFolderId);
  return rooms;
};

const getActiveWalls = ($, roomNumber) => {
  return $("Product")
    .toArray()
    .map(productElement => {
      const wallNumber = $(productElement).attr("Wall").split("_")[0];
      return `R${roomNumber}W${wallNumber}`;
    });
};

export const getRoomXML = async fileId => {
  const content = await Drive.readFile(fileId);
  const xml = content.slice(content.indexOf("<?xml"));
  return xml;
};

export const getRoomNotes = $ => {
  const notes = $("Note")
    .toArray()
    .map(note => note.attr("Text").split("\n"));
  return notes;
};

export const getBaseboard = () => {
  // @TODO
};
export const getDoorHeight = () => {
  // @TODO
};

// export const getRooms = jobFolderId => async dispatch => {
//   if (!jobFolderId) return dispatch({ type: RESET_ROOM_STATE });
//   const promise1 = getRoomFiles(jobFolderId);
//   const promise2 = getImages(jobFolderId);
//   const [roomFiles, projectImages] = await Promise.all([promise1, promise2]);
//   const rooms = roomFiles.map(file => {
//     const roomName = file.name.replace(".des", "");
//     const roomNumber = Number(roomName.replace("Room", ""));
//     const images = projectImages.filter(image => image.name.startsWith(`[${roomName}]`)).map(image => ({ ...image, room: roomNumber }));
//     return {
//       id: file.id,
//       name: roomName,
//       number: roomNumber,
//       images: images,
//       parts: [],
//       active: true,
//       // xml: file.content.slice(file.content.indexOf("<?xml")),
//     };
//   });
//   rooms.sort((a, b) => a.number - b.number);
//   dispatch({ type: SET_ROOMS, payload: rooms });
// };

// export async function getImages(jobFolderId) {
//   const imageFolder = await Drive.getFolder({ name: "2D3DViewerPictures", parent: jobFolderId });
//   const images = await Drive.getFilesByFolder(imageFolder);
//   return images;
// }

// export const getRooms = jobFolderId => async dispatch => {
//   const files = await Drive.getFilesByFolder(jobFolderId);
//   const roomFiles = files.filter(file => file.name.endsWith(".des") && file.name != "Room0.des");
//   dispatch({ type: SET_ROOMS, payload: roomFiles });
// };
