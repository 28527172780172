import _, { countBy, maxBy, sumBy } from "lodash";
import React, { useState, useEffect, useMemo } from "react";

const JobCoverPageRowRoomPlaceholder = () => {
  return (
    <div class="w-[210px] overflow-hidden border border-l-0 border-gray-200 text-xs">
      <div class="h-8 border-b border-b-gray-200 pl-1 font-bold"></div>
      <div class="h-8 pl-1"></div>
      <div class="h-8 pl-1"></div>
      <div class="h-8 pl-1"></div>
      <div class="h-8 pl-1"></div>
      <div class="h-8 pl-1"></div>
      <div class="h-8 pl-1"></div>
      <div class="h-8 pl-1"></div>
      <div class="h-20 pl-1"></div>
      <div class="border-t border-t-gray-200 pl-1 font-bold"></div>
      <div class="pl-1 font-bold"></div>
      <div class="pl-1 font-bold"></div>
    </div>
  );
};

export default JobCoverPageRowRoomPlaceholder;
