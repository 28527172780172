import { groupBy } from "lodash";
import React, { useState } from "react";
import { connect } from "react-redux";
import Document from "../components/PDF/Document";
import Page from "../components/PDF/Page";
import JobCoverPage from "./JobCoverPage/JobCoverPage";

const options = {
  pageSize: "letter",
  unit: "pt",
  orientation: "landscape",
  scale: 4,
  filename: "Document.pdf",
  bgColor: "white",
  preview: true,
  previewBorder: "1px solid yellow",
  centered: false,
};

const ProductionPackage = ({ parts, jobData }) => {
  const rooms = Object.values(groupBy(parts, "`Room Name"));
  console.log({ rooms });

  const [exportPDF, setExportPDF] = useState();

  return (
    <div>
      <div>
        <div className="h-[20px]"></div>
        <button className="my-6 rounded border border-white py-2 px-24 text-white transition hover:bg-white hover:text-gray-900" onClick={exportPDF}>
          Download
        </button>
      </div>
      <Document options={options} setExportPDF={setExportPDF}>
        <JobCoverPage jobData={jobData} rooms={rooms} />
      </Document>
    </div>
  );
};

const mapStateToProps = state => ({
  parts: state.job.parts,
  jobData: state.job.jobData,
});

export default connect(mapStateToProps, {})(ProductionPackage);
