import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { useAlert } from 'react-alert';
import { setAlertGenerator } from '../../actions/alert';

const Alert = ({ setAlertGenerator }) => {
  const alertGenerator = useAlert();
  useEffect(() => setAlertGenerator(alertGenerator), [alertGenerator, setAlertGenerator]);
  return <Fragment />;
};

export default connect(null, { setAlertGenerator })(Alert);
