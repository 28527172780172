import { colorToRGB } from "../utils/general";
import axios from "./axios";
import { Drive } from "./google";

export const SpreadsheetApp = {
  getSpreadsheet: async function (spreadsheetId) {
    const res = await axios.post("/api/spreadsheets/get", { spreadsheetId });
    const spreadsheet = res.data;
    return new Spreadsheet(spreadsheet);
  },
  openById: async function (spreadsheetId) {
    return this.getSpreadsheet(spreadsheetId);
  },
  createSpreadsheet: async function (name, folderId, templateId) {
    if (templateId) {
      const file = await Drive.copyFile(templateId, name, folderId);
      const Spreadsheet = await this.getSpreadsheet(file.id);
      return Spreadsheet;
    }
    const res = await axios.post("/api/spreadsheets/create", {
      requestBody: {
        properties: { title: name },
      },
    });
    const spreadsheetId = res.data.spreadsheetId;
    if (folderId) await Drive.move(spreadsheetId, folderId);
    const Spreadsheet = await this.getSpreadsheet(spreadsheetId);
    Spreadsheet.allowExternalRequests();
    return Spreadsheet;
  },
};

export class Spreadsheet {
  constructor(spreadsheet) {
    this.spreadsheet = spreadsheet;
  }
  async exportPDF(portrait, freezeRows, gridlines, topMargin = "0.1", bottomMargin = "0.1", leftMargin = "0.1", rightMargin = "0.1") {
    const ssId = this.getId();
    const url = `https://docs.google.com/spreadsheets/d/${ssId}/export?format=pdf&portrait=${portrait}&fzr=${freezeRows}&gridlines=${gridlines}&top_margin=${topMargin}&bottom_margin=${bottomMargin}&left_margin=${leftMargin}&right_margin=${rightMargin}`;
    console.log(url);
    const res = await axios.post("/api/spreadsheets/export", { url });
    return res.data.base64;
  }
  async batchUpdate(request) {
    const res = await axios.post("/api/spreadsheets/batchUpdate", {
      spreadsheetId: this.getId(),
      requestBody: { requests: Array.isArray(request) ? request : [request] },
    });
    return res.data.replies;
  }
  /**
   * {
      range: range,
      values: [value],
    }
   */
  async batchUpdateValues(request, valueInputOption = "USER_ENTERED") {
    const res = await axios.post("/api/spreadsheets/values/batchUpdate", {
      spreadsheetId: this.getId(),
      requestBody: {
        valueInputOption,
        data: Array.isArray(request) ? request : [request],
      },
    });
    return res.data.responses;
  }
  async insertSheet(options) {
    const index = options.index || this.spreadsheet.sheets.length + 1;
    const name = options.name || `Sheet${index}`;
    const res = await this.batchUpdate({
      addSheet: {
        properties: { title: name, index },
      },
    });
    return this;
  }
  async deleteSheet(sheetId) {
    await this.batchUpdate({ deleteSheet: { sheetId } });
    return this;
  }
  async getSheets() {
    const sheets = [];
    for (const sheetProps of this.spreadsheet.sheets) {
      const sheet = new Sheet(sheetProps, this);
      await sheet.refreshSheet();
      sheets.push(sheet);
    }
    return sheets;
  }
  async hideSheetsByName(sheetNames) {
    const sheets = this.spreadsheet.sheets.filter(sheet => {
      console.log(sheet.properties.title);
      return sheetNames.includes(sheet.properties.title);
    });
    console.log(sheets);
    const requests = sheets.map(sheet => {
      return {
        updateSheetProperties: {
          properties: { sheetId: sheet.properties.sheetId, hidden: true },
          fields: "hidden",
        },
      };
    });
    console.log(requests);
    if (requests.length) await this.batchUpdate(requests);
    return this;
  }
  async getSheetByName(name) {
    const sheetProps = this.spreadsheet.sheets.find(sheet => sheet.properties.title === name);
    if (sheetProps) {
      const sheet = new Sheet(sheetProps, this);
      await sheet.refreshSheet();
      return sheet;
    }
  }
  async getSheetById(id) {
    const sheetProps = this.spreadsheet.sheets.find(sheet => sheet.properties.sheetId === id);
    if (sheetProps) {
      const sheet = new Sheet(sheetProps, this);
      await sheet.refreshSheet();
      return sheet;
    }
  }
  getId() {
    return this.spreadsheet.spreadsheetId;
  }
  getUrl() {
    return `https://docs.google.com/spreadsheets/d/${this.getId()}/edit`;
  }
  getName() {
    return this.spreadsheet.properties.title;
  }
  async setName(name) {
    this.spreadsheet.properties.title = name;
    await this.batchUpdate({
      updateSpreadsheetProperties: {
        properties: { title: name },
        fields: "title",
      },
    });
    return this;
  }
  async allowExternalRequests() {
    await this.batchUpdate({
      updateSpreadsheetProperties: {
        properties: { importFunctionsExternalUrlAccessAllowed: true },
        fields: "importFunctionsExternalUrlAccessAllowed",
      },
    });
    return this;
  }
}

export class Sheet {
  constructor(sheet, Spreadsheet) {
    this.sheet = sheet;
    this.Spreadsheet = Spreadsheet;
    this.spreadsheet = Spreadsheet.spreadsheet;
  }
  async refreshSheet() {
    // @TODO: create a function that updates all the important information
    await this.refreshLastRowAndColumn();
  }
  async appendRow(rowArray) {
    await axios.post("/api/spreadsheets/values/append", {
      spreadsheetId: this.getParent(),
      range: `'${this.getName()}'`,
      valueInputOption: "USER_ENTERED",
      requestBody: {
        range: `'${this.getName()}'`,
        values: [rowArray],
      },
    });
    return this;
  }
  async appendRows(values) {
    await axios.post("/api/spreadsheets/values/append", {
      spreadsheetId: this.getParent(),
      range: `'${this.getName()}'`,
      valueInputOption: "USER_ENTERED",
      requestBody: {
        range: `'${this.getName()}'`,
        values: values,
      },
    });
    return this;
  }
  async copyTo(spreadsheetId) {
    const res = await axios.post("/api/spreadsheets/sheets/copyTo", {
      sheetId: this.getSheetId(),
      spreadsheetId: this.getParent(),
      requestBody: { spreadsheetId },
    });
    const sheetId = res.data.sheetId;
    const spreadsheet = await axios.post("/api/spreadsheets/get", { spreadsheetId });
    const sheet = spreadsheet.data.sheets.find(sheet => sheet.properties.sheetId === sheetId);
    return new Sheet(sheet, spreadsheet.data);
  }
  async refreshLastRowAndColumn() {
    const res = await axios.post("/api/spreadsheets/values/append", {
      spreadsheetId: this.getParent(),
      range: `'${this.getName()}'`,
      valueInputOption: "USER_ENTERED",
      requestBody: {
        range: `'${this.getName()}'`,
        values: [],
      },
    });
    this.sheet.properties.lastRow = Number(res.data.tableRange.extract(/:[A-Z]*([0-9]*)/));
    this.sheet.properties.lastColumn = this.letterToNumber(res.data.tableRange.extract(/:([A-Z]*)/));
  }
  getLastRow() {
    return this.sheet.properties.lastRow;
  }
  getLastColumn() {
    return this.sheet.properties.lastColumn;
  }
  getName() {
    return this.sheet.properties.title;
  }
  getParent() {
    return this.spreadsheet.spreadsheetId;
  }
  getIndex() {
    return this.sheet.properties.index;
  }
  getSheetId() {
    return this.sheet.properties.sheetId;
  }
  getRange(rowOrA1Notation, col, numRows, numCols) {
    if (typeof rowOrA1Notation == "string" && rowOrA1Notation.includes(":")) {
      var { row, col, numRows, numCols } = this.parseA1Notation(rowOrA1Notation);
      return new Range(row, col, numRows, numCols, this, rowOrA1Notation);
    }
    return new Range(rowOrA1Notation, col, numRows, numCols, this);
  }
  parseA1Notation(A1) {
    const [start, end] = A1.split(":");
    const col = this.letterToNumber(start.extract(/([a-zA-Z]+)/)) || 1;
    const row = Number(start.extract(/([0-9]+)/) || 1);
    const numRows = (end.extract(/([0-9]+)/) || this.getLastRow()) - row + 1;
    const numCols = (this.letterToNumber(end.extract(/([a-zA-Z]+)/)) || this.getLastColumn()) - col + 1;
    return { row, col, numRows, numCols };
  }
  letterToNumber(letter) {
    if (!letter) return null;
    let column = 0;
    for (let i = 0; i < letter.toUpperCase().length; i++) {
      column = 26 * column + letter.toUpperCase().charCodeAt(i) - 64;
    }
    return column;
  }
  getRowHeight(row) {
    return this.sheet.data[0].rowMetadata[row - 1].pixelSize;
  }
  getColumnWidth(column) {
    return this.sheet.data[0].columnMetadata[column - 1].pixelSize;
  }
  /**
   *
   * @param {*} row
   * @param {*} formats - {
   *  bold: true | false | null
   *  align: left | center | right | null
   *  fontSize: 12
   *  fontColor: #000333
   *  backgroundColor: #000333
   *  borderWidth: 1 // px
   *  borderColor: "333333" or "yellow-100"
   *  borders: {
   *    all: true,
   *    left: true,
   *    right: true,
   *    top: false,
   *    bottom: true,
   *    inner: true
   *  }
   * }
   */
  getFormatRequest(range, formats) {
    const fieldsRef = {
      bold: "textFormat",
      align: "horizontalAlignment",
      fontSize: "textFormat",
      fontColor: "textFormat",
      backgroundColor: "backgroundColor",
    };
    const fields = Object.entries(formats)
      .map(([key, value]) => value && fieldsRef[key])
      .filter(Boolean);
    const request = [
      {
        repeatCell: {
          range,
          fields: `userEnteredFormat(${fields.join(",")})`,
          cell: {
            userEnteredFormat: {
              backgroundColor: colorToRGB(formats.backgroundColor),
              horizontalAlignment: formats.align ? formats.align.toUpperCase() : null,
              textFormat: {
                foregroundColor: colorToRGB(formats.fontColor),
                fontSize: formats.fontSize,
                bold: formats.bold,
              },
            },
          },
        },
      },
    ];
    if (formats.borders) {
      let { top, bottom, left, right, all, outer, inner } = formats.borders;
      if (outer === true) inner = false;
      request.push({
        updateBorders: {
          range,
          top: (top !== undefined || all || outer !== undefined) && {
            style: top === false || outer === false ? "NONE" : "SOLID",
            width: formats.borderWidth || 1,
            color: colorToRGB(formats.borderColor || "#000000"),
          },
          bottom: (bottom !== undefined || all || outer !== undefined) && {
            style: bottom === false || outer === false ? "NONE" : "SOLID",
            width: formats.borderWidth || 1,
            color: colorToRGB(formats.borderColor || "#000000"),
          },
          left: (left !== undefined || all || outer !== undefined) && {
            style: left === false || outer === false ? "NONE" : "SOLID",
            width: formats.borderWidth || 1,
            color: colorToRGB(formats.borderColor || "#000000"),
          },
          right: (right !== undefined || all || outer !== undefined) && {
            style: right === false || outer === false ? "NONE" : "SOLID",
            width: formats.borderWidth || 1,
            color: colorToRGB(formats.borderColor || "#000000"),
          },
          innerHorizontal: (inner !== undefined || outer !== undefined || all) && {
            style: inner === false ? "NONE" : "SOLID",
            width: formats.borderWidth || 1,
            color: colorToRGB(formats.borderColor || "#000000"),
          },
          innerVertical: (inner !== undefined || outer !== undefined || all) && {
            style: inner === false ? "NONE" : "SOLID",
            width: formats.borderWidth || 1,
            color: colorToRGB(formats.borderColor || "#000000"),
          },
        },
      });
    }
    return request;
  }
  async formatRow(row, formats) {
    const range = {
      sheetId: this.getSheetId(),
      startRowIndex: row - 1,
      endRowIndex: row,
    };
    const request = this.getFormatRequest(range, formats);
    await this.Spreadsheet.batchUpdate(request);
  }
  async formatColumn(column, formats) {
    const range = {
      sheetId: this.getSheetId(),
      startColumnIndex: column - 1,
      endColumnIndex: column,
    };
    const request = this.getFormatRequest(range, formats);
    console.log(request);
    await this.Spreadsheet.batchUpdate(request);
  }
  async duplicateSheet(newName, index) {
    const res = await this.Spreadsheet.batchUpdate({
      duplicateSheet: {
        sourceSheetId: this.getSheetId(),
        insertSheetIndex: index || this.getIndex() + 1,
        newSheetName: newName,
      },
    });
    console.log({ res });
    const sheet = res[0].duplicateSheet;
    this.Spreadsheet.spreadsheet.sheets.push(sheet);
  }
  async deleteRow(row) {
    await this.Spreadsheet.batchUpdate({
      deleteDimension: {
        range: {
          sheetId: this.getSheetId(),
          dimension: "ROWS",
          startIndex: row,
          endIndex: row,
        },
      },
    });
  }
  async deleteColumn(column) {
    await this.Spreadsheet.batchUpdate({
      deleteDimension: {
        range: {
          sheetId: this.getSheetId(),
          dimension: "COLUMNS",
          startIndex: column,
          endIndex: column,
        },
      },
    });
  }
  async hideRow(row) {
    await this.Spreadsheet.batchUpdate({
      updateDimensionProperties: {
        range: {
          sheetId: this.getSheetId(),
          dimension: "ROWS",
          startIndex: row - 1,
          endIndex: row,
        },
        properties: {
          hiddenByUser: true,
        },
        fields: "hiddenByUser",
      },
    });
  }
  async hideRows(row, numRows) {
    await this.Spreadsheet.batchUpdate({
      updateDimensionProperties: {
        range: {
          sheetId: this.getSheetId(),
          dimension: "ROWS",
          startIndex: row - 1,
          endIndex: row - 1 + numRows,
        },
        properties: {
          hiddenByUser: true,
        },
        fields: "hiddenByUser",
      },
    });
  }
  async hideColumn(column) {
    await this.Spreadsheet.batchUpdate({
      updateDimensionProperties: {
        range: {
          sheetId: this.getSheetId(),
          dimension: "COLUMNS",
          startIndex: column - 1,
          endIndex: column,
        },
        properties: {
          hiddenByUser: true,
        },
        fields: "hiddenByUser",
      },
    });
  }
  async hideColumns(column, numColumns) {
    await this.Spreadsheet.batchUpdate({
      updateDimensionProperties: {
        range: {
          sheetId: this.getSheetId(),
          dimension: "COLUMNS",
          startIndex: column,
          endIndex: column - 1 + numColumns,
        },
        properties: {
          hiddenByUser: true,
        },
        fields: "hiddenByUser",
      },
    });
  }
  async showRow(row) {
    await this.Spreadsheet.batchUpdate({
      updateDimensionProperties: {
        range: {
          sheetId: this.getSheetId(),
          dimension: "ROWS",
          startIndex: row - 1,
          endIndex: row,
        },
        properties: {
          hiddenByUser: false,
        },
        fields: "hiddenByUser",
      },
    });
  }
  async showRows(row, numRows) {
    await this.Spreadsheet.batchUpdate({
      updateDimensionProperties: {
        range: {
          sheetId: this.getSheetId(),
          dimension: "ROWS",
          startIndex: row - 1,
          endIndex: row - 1 + numRows,
        },
        properties: {
          hiddenByUser: false,
        },
        fields: "hiddenByUser",
      },
    });
  }
  async showColumn(column) {
    await this.Spreadsheet.batchUpdate({
      updateDimensionProperties: {
        range: {
          sheetId: this.getSheetId(),
          dimension: "COLUMNS",
          startIndex: column - 1,
          endIndex: column,
        },
        properties: {
          hiddenByUser: false,
        },
        fields: "hiddenByUser",
      },
    });
  }
  async showColumns(column, numColumns) {
    await this.Spreadsheet.batchUpdate({
      updateDimensionProperties: {
        range: {
          sheetId: this.getSheetId(),
          dimension: "COLUMNS",
          startIndex: column - 1,
          endIndex: column - 1 + numColumns,
        },
        properties: {
          hiddenByUser: false,
        },
        fields: "hiddenByUser",
      },
    });
  }
  async sort(column, ascending = true) {
    await this.Spreadsheet.batchUpdate({
      sortRange: {
        range: { sheetId: this.getSheetId() },
        sortSpecs: [{ dimensionIndex: column - 1, sortOrder: ascending ? "ASCENDING" : "DESCENDING" }],
      },
    });
  }
  async sortMany(array) {
    const sortSpecs = array.map(({ column, ascending }) => {
      return { dimensionIndex: column - 1, sortOrder: ascending ? "ASCENDING" : "DESCENDING" };
    });
    console.log(sortSpecs);
    await this.Spreadsheet.batchUpdate({
      sortRange: {
        range: { sheetId: this.getSheetId() },
        sortSpecs: sortSpecs,
      },
    });
  }
  async setRowHeight(row, px) {
    await this.Spreadsheet.batchUpdate({
      updateDimensionProperties: {
        range: {
          sheetId: this.getSheetId(),
          dimension: "ROWS",
          startIndex: row - 1,
          endIndex: row,
        },
        properties: {
          pixelSize: px,
        },
        fields: "pixelSize",
      },
    });
  }
  async setColumnWidth(column, px) {
    await this.Spreadsheet.batchUpdate({
      updateDimensionProperties: {
        range: {
          sheetId: this.getSheetId(),
          dimension: "COLUMNS",
          startIndex: column - 1,
          endIndex: column,
        },
        properties: {
          pixelSize: px,
        },
        fields: "pixelSize",
      },
    });
  }
  async setRowHeights(row, numRows, px) {
    await this.Spreadsheet.batchUpdate({
      updateDimensionProperties: {
        range: {
          sheetId: this.getSheetId(),
          dimension: "ROWS",
          startIndex: row - 1,
          endIndex: row - 1 + numRows,
        },
        properties: {
          pixelSize: px,
        },
        fields: "pixelSize",
      },
    });
  }
  async setColumnWidths(column, numCols, px) {
    await this.Spreadsheet.batchUpdate({
      updateDimensionProperties: {
        range: {
          sheetId: this.getSheetId(),
          dimension: "COLUMNS",
          startIndex: column - 1,
          endIndex: column - 1 + numCols,
        },
        properties: {
          pixelSize: px,
        },
        fields: "pixelSize",
      },
    });
  }
  async setName(name) {
    const sheetId = this.getSheetId();
    await this.Spreadsheet.batchUpdate({
      updateSheetProperties: {
        properties: { sheetId, title: name },
        fields: "title",
      },
    });
    return this;
  }
  async hideSheet() {
    const sheetId = this.getSheetId();
    await this.Spreadsheet.batchUpdate({
      updateSheetProperties: {
        properties: { sheetId, hidden: true },
        fields: "hidden",
      },
    });
    return this;
  }
}

export class Range {
  constructor(row, col, numRows, numCols, Sheet, A1) {
    this.row = row;
    this.col = col;
    this.numRows = numRows || 1;
    this.numCols = numCols || 1;
    this.Sheet = Sheet;
    this.A1 = A1;
    this.Spreadsheet = Sheet.Spreadsheet;
  }
  getGridRange() {
    return {
      sheetId: this.Sheet.getSheetId(),
      startRowIndex: this.row - 1,
      endRowIndex: this.row - 1 + this.numRows,
      startColumnIndex: this.col - 1,
      endColumnIndex: this.col - 1 + this.numCols,
    };
  }
  getR1C1Notation() {
    const row = this.row;
    const col = this.col;
    const numRows = this.numRows;
    const numCols = this.numCols;
    return `R${row}C${col}:R${row + numRows - 1}C${col + numCols - 1}`;
  }
  getA1Notation(includeSheet) {
    let A1 = this.A1;
    if (!A1) {
      const R1C1 = /^R([1-9]\d*)C([1-9]\d*)$/;
      A1 = this.getR1C1Notation()
        .split(":")
        .map(ref => {
          const refParts = ref.replace(R1C1, "$1,$2").split(",");
          const row = refParts[0];
          let column = refParts[1];
          let columnStr = "";
          for (; column; column = Math.floor((column - 1) / 26)) {
            columnStr = String.fromCharCode(((column - 1) % 26) + 65) + columnStr;
          }
          return columnStr + row;
        })
        .join(":");
    }
    return includeSheet ? `'${this.Sheet.getName()}'!${A1}` : A1;
  }
  async getValues() {
    const res = await axios.post("/api/spreadsheets/values/get", {
      spreadsheetId: this.Sheet.getParent(),
      range: this.getA1Notation(true),
    });
    return res.data.values;
  }
  async getValue() {
    const res = await axios.post("/api/spreadsheets/values/get", {
      spreadsheetId: this.Sheet.getParent(),
      range: this.getA1Notation(true),
    });
    return res.data.values[0][0];
  }
  async setValues(values) {
    const range = this.getA1Notation(true);
    await axios.post("/api/spreadsheets/values/update", {
      spreadsheetId: this.Sheet.getParent(),
      range: range,
      valueInputOption: "USER_ENTERED",
      requestBody: {
        range: range,
        values: values,
      },
    });
    return this;
  }
  async setValue(value) {
    const range = this.getA1Notation(true);
    await axios.post("/api/spreadsheets/values/update", {
      spreadsheetId: this.Sheet.getParent(),
      range: range,
      valueInputOption: "USER_ENTERED",
      requestBody: {
        range: range,
        values: [value],
      },
    });
    return this;
  }
  async clearValues() {
    await this.Spreadsheet.batchUpdate({
      updateCells: {
        range: this.getGridRange(),
        fields: "userEnteredValue",
      },
    });
    return this;
  }
  async clearFormats() {
    await this.Spreadsheet.batchUpdate({
      updateCells: {
        range: this.getGridRange(),
        fields: "userEnteredFormat",
      },
    });
    return this;
  }
  async copyFormulaToRange(destinationRange) {
    await this.Spreadsheet.batchUpdate({
      copyPaste: {
        source: this.getGridRange(),
        destination: destinationRange.getGridRange(),
        pasteType: "PASTE_FORMULA",
        pasteOrientation: "NORMAL",
      },
    });
  }
  async copyFormatToRange(destinationRange) {
    await this.Spreadsheet.batchUpdate({
      copyPaste: {
        source: this.getGridRange(),
        destination: destinationRange.getGridRange(),
        pasteType: "PASTE_FORMAT",
        pasteOrientation: "NORMAL",
      },
    });
  }
  async formatRange(formats) {
    const range = this.getGridRange();
    const request = this.Sheet.getFormatRequest(range, formats);
    await this.Spreadsheet.batchUpdate(request);
  }
  async copyTo(destinationRange) {
    await this.Spreadsheet.batchUpdate({
      copyPaste: {
        source: this.getGridRange(),
        destination: destinationRange.getGridRange(),
        pasteType: "PASTE_NORMAL",
      },
    });
  }
  getCell(row, column) {
    return Sheet.sheet.data[0].rowData[row - 1][column - 1];
  }
  getFormula() {
    return Sheet.sheet.data[0].rowData[this.row - 1][this.col - 1].formattedValue;
  }
  setFormula(value) {}
}
