import { Helper } from "dxf";

export function dxf2svg(dxf, type) {
  const helper = new Helper(dxf, "utf-8");

  // Group entities by layer. Returns an object with layer names as
  // keys to arrays of entities.

  const groups = helper.groups;

  const lines = [];
  const texts = [];
  const other = [];

  for (const group of Object.values(groups)) {
    group.forEach(el => {
      if (el.type === "LINE") lines.push(el);
      else if (el.type === "TEXT" && el.string !== "3/4") texts.push(el);
      else other.push(el);
    });
  }

  // Move the Cabinet Number Labels to 100px below the Wall Label

  if (type === "Elevation") {
    const wallLabel = texts.find(text => text.layer === "Wall Labels");
    const wallLabelY = wallLabel.y;
    const cabinetNumbersY = wallLabelY - 100;
    texts.forEach(text => {
      if (text.layer === "Cabinet Number Labels") text.y = cabinetNumbersY;
    });
  }

  const max = {
    x: 0,
    y: 0,
    textHeight: 0,
  };

  lines.forEach(line => {
    max.x = line.start.x > max.x ? line.start.x : max.x;
    max.x = line.end.x > max.x ? line.end.x : max.x;
    max.y = line.start.y > max.y ? line.start.y : max.y;
    max.y = line.end.y > max.y ? line.end.y : max.y;
  });

  texts.forEach(text => {
    const x = text.x + text.textHeight;
    const y = text.y + text.textHeight;
    max.x = x > max.x ? x : max.x;
    max.y = y > max.y ? y : max.y;
    max.textHeight = max.textHeight > text.textHeight ? max.textHeight : text.textHeight;
  });

  max.x = max.x + max.textHeight;
  max.y = max.y + max.textHeight;

  const fontSizeScale = type === "Elevation" ? 1.1 : 1.1;
  max.textHeight = max.textHeight * fontSizeScale;

  // 0 -1744.34 3388.7925 1744.34
  // 0
  // -max-height + half text height
  // max-width + half text height
  // max-height + half text height

  // var svg = `<?xml version="1.0" encoding="UTF-8"?>`;

  const height = max.y / 2;
  const width = max.x / 2;

  var svg = ``;
  svg += `<svg viewBox="-${max.textHeight / 2} -${max.y} ${max.x} ${max.y}" 
    version="1.1" xmlns="http://www.w3.org/2000/svg" style="stroke-linecap:round;stroke-linejoin:round;fill:none;background:white"><defs>
    <filter x="0" y="0" width="1" height="1" id="solid">
      <feFlood flood-color="yellow" result="bg" />
      <feComposite operator="xor" in="SourceGraphic" in2="composite" />
      <feMerge>
        <feMergeNode in="bg"/>
        <feMergeNode in="SourceGraphic" operator="xor"/>
      </feMerge>
    </filter>
    </defs><g transform="scale(1,-1)">
  `;

  lines.forEach(line => {
    svg += `<line class="${line.layer.toLowerCase().replace(/ /g, "-")}" x1="${line.start.x}" y1="${line.start.y}" x2="${line.end.x}" y2="${line.end.y}" style="stroke:#000000;stroke-width:2;"/>`;
  });

  texts.forEach(text => {
    svg += `<text filter="url(#solid)" style="font-weight:bold;font-size:${text.textHeight}" class="${text.layer.toLowerCase().replace(/ /g, "-")}" x="0" dx="0" y="0" dy="${
      text.textHeight
    }" font-family="standard" font-size="${text.textHeight}" line-height="1.1" fill="#000000" transform="translate(${text.x},${text.y}) rotate(${text.rotation}) translate(${text.z},${
      text.textHeight / 2
    }) scale(${fontSizeScale},-${fontSizeScale})" text-anchor="middle">${text.string.replace(/&/g, "and")}</text>`;
  });

  svg += "</g></svg>";

  return { svg, width, height };
}
