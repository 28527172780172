import React from "react";
import { connect } from "react-redux";
import { updateRoom } from "../../actions/mozaik/rooms";

const RoomSelector = ({ rooms, updateRoom }) => {
  const toggleSelected = e => {
    const room = rooms.find(room => room.id == e.target.id);
    const active = !room.active;
    updateRoom({ ...room, active });
  };

  const active = "select-none my-auto h-full cursor-pointer border border-white rounded-md bg-gray-700 py-2 px-2 text-center text-xs text-gray-300";
  const inactive = "select-none my-auto h-full cursor-pointer border border-transparent rounded-md bg-gray-700 py-2 px-2 text-center text-xs text-gray-300 hover:border-white opacity-50";

  return (
    <div className="">
      <div class="my-3 text-sm font-medium text-gray-400">Included Rooms</div>
      <div className="grid grid-cols-8 gap-2">
        {rooms.map(room => {
          return (
            <div id={room.id} onClick={toggleSelected} className={room.active ? active : inactive} key={room.id}>
              {room.name}
            </div>
          );
        })}
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  rooms: state.room.rooms,
});

export default connect(mapStateToProps, { updateRoom })(RoomSelector);
