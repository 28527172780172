import _, { sumBy, groupBy, times } from "lodash";
import { removeDuplicates, chunk, transpose } from "../../utils/general";

export const buildCoverPage = async (ss, parts) => {
  const rooms = Object.values(groupBy(parts, "`Room Name"));
  const coverSheet = await ss.getSheetByName("Cover1");
  const pages = getCoverSheetValues(rooms);
  if (pages.length > 1) await Promise.all(times(pages.length - 1, i => coverSheet.duplicateSheet(`Cover${pages.length - i}`)));

  for (let i = 0; i < pages.length; i++) {
    const sheet = await ss.getSheetByName(`Cover${i + 1}`);
    const [firstRow, secondRow] = pages[i];
    sheet.getRange("B3:F26").setValues([...firstRow, ...(secondRow || [])]);
  }
};

const getUniqList = (room, name) => {
  const list = removeDuplicates(room.filter(part => part["`PrintName"].includes(name)).map(part => part["`PrintName"].exclude(`${name} | `)));
  return list.length ? list.join(", ") : "-";
};

const getCoverSheetValues = rooms => {
  const arr = rooms.map(room => {
    const roomName = room[0]["`Room Name"];
    const material =
      _(room)
        .countBy("`Material")
        .entries()
        .filter(e => !e[0].includes("Thermofoil") && e[0] !== "Solid Bottom")
        .maxBy(_.last)[0] || "-";
    const drwFace = room.find(part => part["`Primary Category"] === "Drawer Face");
    const drwStyle = (drwFace && drwFace["`PrintName"].exclude("Drawer Face | ")) || "-";
    const doorFace = room.find(part => part["`Primary Category"] === "Door Face");
    const doorStyle = (doorFace && doorFace["`PrintName"].exclude("Door Face | ")) || "-";
    const drwPull = getUniqList(room, "Drw Pull");
    const doorPull = getUniqList(room, "Door Pull");
    const molding = room.find(part => part["`PrintName"].includes("Molding"));
    const closetRodTypes = getUniqList(room, "Closet Rod");
    const shelves = room.filter(part => part["`Secondary Category"] === "Shelf");
    const gablesAndBackings = room.filter(part => part["`Secondary Category"] === "Gable" || part["`Secondary Category"] === "Backing");
    const closetRods = room.filter(part => part["`PrintName"].includes("Closet Rod"));
    const accessories = removeDuplicates(
      room.filter(part => part["`Tertiary Category"] === "Accessory" && part["`Secondary Category"] !== "Closet Rod").map(part => part["`PrintName"].excludeAll(" |"))
    );

    return [
      roomName,
      material,
      drwStyle,
      drwPull,
      doorStyle,
      doorPull,
      molding ? molding["`PrintName"].split(" | ")[1] : "-",
      closetRodTypes,
      accessories.length ? accessories.join(", ") : "-",
      shelves.length ? sumBy(shelves, "`Qty") : 0,
      gablesAndBackings.length ? sumBy(gablesAndBackings, "`Qty") : 0,
      closetRods.length ? sumBy(closetRods, "`Qty") : 0,
    ];
  });
  const pages = chunk(arr, 10).map(page => chunk(page, 5).map(section => transpose(section)));
  return pages;
};
