import { uniqueId } from "lodash";
import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { setAlert } from "../../actions/alert";
import { Drive } from "../../actions/google";
import CNCJobSelect from "../../components/Jobs/CNCJobSelect";
import { load, adjustHoles } from "./OptimizerRules/OptimizerUtils";
import JobSelect from "../../components/Jobs/JobSelect";

const Transfer = ({ jobFolder, rooms, setAlert }) => {
  const [files, setFiles] = useState([]);
  const [complete, setComplete] = useState(false);
  const [report, setReport] = useState([]);

  const ref = useRef();

  async function fetchFiles(jobFolder) {
    if (jobFolder.id) {
      const files = (await Drive.getFilesByFolder(jobFolder.id)).filter(file => file.name.endsWith(".opt"));
      if (!files.length) setAlert("error", "No optimizer files found.");
      setFiles(files);
    }
  }

  useEffect(() => {
    fetchFiles(jobFolder);
  }, [jobFolder.id]);

  async function transferFolder() {
    ref.current.textContent = "Copying folder to...";
    ref.current.setAttribute("disabled", true);

    // Drive.copyFolder({
    //   sourceFolderId: "1-0UmaUcPCVPZ47o0mR7A5t-qM9UH7zTV",
    //   destinationFolderId: "1Sm-4BfAn21A7MnVqD9qsHsA-dSPdnvZT",
    //   folderName: "transfer-test",
    // });

    // @TODO: copy folder
  }

  async function sanitizeOptimizerFile(file, issues) {
    try {
      let $ = await load(file);

      adjustHoles($);

      const optimizeMaterial = $("OptimizeMaterial").attr("Name");
      if (optimizeMaterial === '5/8" | White (L191)') return;

      const output = "4\n" + $.xml().replace(/  /g, "");
      console.log((output.match(/  /g) || []).length);
      const base64 = btoa(output);
      const res = await Drive.updateFile(file.id, "audio/mpeg", base64, file.parents[0]);
      console.log(res);
      setComplete(true);
    } catch (err) {
      console.log(err);
      ref.current.textContent = "Error: " + err.message;
    }
  }

  /**
   * https://docs.google.com/spreadsheets/d/1Bx2d79tcKN2GN0QbVRSpuGclLe5mkJUO09gMHcLl88E/edit#gid=0
   *
   * Get list of all ".opt" files from the job folder
   * Create a function to iterate each Optimizer Part and find the SourceLib and the PrintName
   * Iterate each Optimizer Part and create a JSON object of all the "criteria" attributes
   *
   * OptimizePart > Shape > ShapePoint
   * OptimizePart > Shape > Operations > OperationHole, OperationLineBore
   *
   * OptimizePart attributes: PrintName, Name, Width, Length, Quan
   * ShapePoint: X, Y
   * OperationHole: Diameter, X, Y, Depth, FlipSide
   * OperationLineBore: Quan, Diameter, X, Y, Depth, FlipSide
   *
   * Check whether part meets condition for a rule and if so modify part according to that rule
   * Keep track of which parts have been modified and which rules were applied
   * Output the modified XML and update the google drive file
   */

  return (
    <div className="mt-10">
      <JobSelect />
      <button
        ref={ref}
        onClick={() => transferFolder()}
        class={`mt-12 block w-full cursor-pointer items-center justify-center rounded-md bg-cyan-500 px-4 py-4 text-lg leading-6 text-white shadow-sm outline-gray-200 transition duration-150 ease-in-out hover:bg-cyan-400 focus:outline-offset-1 sm:leading-5 ${
          !files.length || !rooms.length ? "cursor-not-allowed opacity-50 hover:bg-cyan-500" : "opacity-100"
        }`}
      >
        {complete ? "Finished" : `Start`}
      </button>
      {/* {files.length === 0 && <p className="my-1 text-sm font-semibold tracking-wide text-red-500">Unable to find any optimizer files</p>} */}
      <div className="mt-8 rounded-md border border-gray-400 p-8 text-sm text-white">
        <div className="py-1 text-base text-gray-400">TRANSFER</div>
        {report.map(issue => (
          <div className="py-2 text-sm">* {issue.msg}</div>
        ))}
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  jobFolder: state.job.job,
  rooms: state.room.rooms,
  params: state.room.params,
});

export default connect(mapStateToProps, { setAlert })(Transfer);
