import axios from "./axios";
import { Zoho } from "./zoho";

export async function createPotential(contactId) {
  // const contact = Zoho.getRecord('Contacts', contactId)
  // const ownerId = contact.Owner.id
  // const existingPotentials = await Zoho.getRelatedListsForRecord("Contacts", "1494268000052755181", "Potentials");
  // const number = existingPotentials.info.count + 1
  // const potentialName = `${contact.First_Name} ${contact.Last_Name} [${number.toFixed(0)}]`
  // const clientFolderName = `${contact.First_Name} ${contact.Last_Name}`
  // let clientFolder = await Drive.getFolders({ name: clientFolderName, parents: '0B8Du9lMtaJzBUlE0OFppSnNic2M' })
  // if (!clientFolder.length) {

  // }
  const res = await axios.get("/api/gapi/create-potential", { params: { contactId } });
  const potentialId = res.data.id;
  return potentialId;
}

export async function createQuote(potentialId) {
  const res = await axios.get("/api/gapi/create-quote", { params: { potentialId } });
  const quoteId = res.data.id;
  return quoteId;
}

export async function createCutlist(name, potentialId) {
  const res = await Zoho.insertRecord("Cutlists", { Name: `${name} Cutlist`, Potential_Name: potentialId });
  return res.id;
}
